import { Controller } from "@hotwired/stimulus"
import React from "react";
import { createRoot } from "react-dom/client";
import ReactOnRails from "react-on-rails";

const renderedReactOnRailsDomIds = new Set();

window.roots = window.roots = [];

/*
 * Connects to data-controller="react-on-rails-loader"
 *
 * Even though React on Rails does a pretty good job on rendering React on Turbo
 * (navigation) events, it doesn't do anything when a Turbo Frame loads.
 * This controller helps render React on Rails instances within a Turbo frame
 * and after that it passes its handling back to React on Rails.
 */
export default class extends Controller {
  connect() {
    const reactOnRailsElements = this.element.querySelectorAll(".js-react-on-rails-component");

    reactOnRailsElements.forEach(this.renderReactOnRailsInstance.bind(this));
  }

  renderReactOnRailsInstance(element) {
    const { domId } = element.dataset;

    if (!renderedReactOnRailsDomIds.has(domId)) {
      const { componentName, trace } = element.dataset;
      const props = (element.textContent !== null)
        ? JSON.parse(element.textContent)
        : {};
      const domNode = document.getElementById(domId);
      const { component } = ReactOnRails.getComponent(componentName);
      const reactElement = React.createElement(component, props);
      const root = createRoot(domNode);

      if (trace === "true") {
        console.log(`RENDERED ${name} to dom node with id: ${domId} with props, railsContext:`, props, this.railsContext);
      }
      root.render(reactElement);
      window.roots.push(root);
      renderedReactOnRailsDomIds.add(domId)
    }
  }

  get railsContext() {
    const element = document.getElementById("js-react-on-rails-context");

    if (!element) {
      return null;
    }
    if (!element.textContent) {
      throw new Error("The HTML element with ID 'js-react-on-rails-context' has no textContent");
    }

    return JSON.parse(element.textContent);
  }
}
