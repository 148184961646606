import {useQuery} from "@tanstack/react-query";

import {get} from "../../api";
import {useParams} from "react-router-dom";

const REACT_QUERY_STALE_TIME = 5000;

export const useAutoReteachFocusBannerQuery = (studentLessonId) => {
  const { id: studentId } = useParams();
  return useQuery({
    queryKey: ["auto_reteach_focus_banner", studentId, studentLessonId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/auto_reteach_focus_banner.json`, {
        signal, params: {
          student_lesson_id: studentLessonId
        }
      });

      return response.data;
    },
    keepPreviousData: false,
    staleTime: REACT_QUERY_STALE_TIME,
  });
};
