import React from "react";

import { useLessonPlanQuery } from "./queries";
import LessonCardRenderer from "./LessonCardRenderer";

const LessonPlan = ({ studentId }) => {
  const lessonPlanQuery = useLessonPlanQuery();

  if (!lessonPlanQuery.data) {
    if (lessonPlanQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {lessonPlanQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return lessonPlanQuery.data.map((props) => (
    <LessonCardRenderer key={`${props.type}-${props.id}`} studentId={studentId} {...props} />
  ));
};

export default LessonPlan;
