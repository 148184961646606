import { useContext, useMemo } from "react";

import TableContext from "./context";

const useTable = () => {
  const {
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  } = useContext(TableContext);

  return useMemo(() => ({
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  }), [
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  ]);
};

export default useTable;
