import cx from "classnames";
import React, { useContext } from "react";

import { CurrentViewContext } from "../../../contexts/Calendar";
import { CALENDAR_DAILY_VIEW, CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW } from "../../../constants";
import { useCalendarViewMode } from "../../../hooks/useCalendarViewMode";

export default ({ views, onChange }) => {
  const { currentView, setCurrentView } = useContext(CurrentViewContext);
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const onChangeView = (event) => {
    setCurrentView(event.target.value);

    if (onChange) {
      onChange();
    }
  }

  return (
    <div
      className={cx("flex items-center rounded-[8px] ring-1 ring-inset p-[3px] h-full",
        isCalendarMode ? "bg-gray-100/75 ring-gray-300" : "bg-zinc-700/60 ring-zinc-600"
      )}
      data-testid="view-switcher"
    >
      {views.map(view => [
        <input
          checked={currentView === view}
          className={cx("hidden", {
            // We can't build this class names dynamically because Tailwind won't be able to pick up on them and
            // generate them correctly.
            "peer/daily": view === CALENDAR_DAILY_VIEW,
            "peer/weekly": view === CALENDAR_WEEKLY_VIEW,
            "peer/monthly": view === CALENDAR_MONTLY_VIEW,
          })}
          id={`view-${view}`}
          key="input"
          name="view"
          onChange={onChangeView}
          type="radio"
          value={view}
        />,
        <label
          className={cx(
            "capitalize cursor-pointer rounded-md text-xs md:text-sm font-medium text-center px-1 md:px-2.5 leading-none flex items-center justify-center h-full w-[56px] md:w-[68px] lg:w-[80px]",
            isCalendarMode ? "text-gray-700" : "text-white", {
            "peer-checked/daily:bg-white peer-checked/daily:shadow": view === CALENDAR_DAILY_VIEW && isCalendarMode,
            "peer-checked/daily:bg-zinc-700 peer-checked/daily:shadow": view === CALENDAR_DAILY_VIEW && isSubMode,
            "peer-checked/weekly:bg-white peer-checked/weekly:shadow": view === CALENDAR_WEEKLY_VIEW && isCalendarMode,
            "peer-checked/weekly:bg-zinc-700 peer-checked/weekly:shadow": view === CALENDAR_WEEKLY_VIEW && isSubMode,
            "peer-checked/monthly:bg-white peer-checked/monthly:shadow": view === CALENDAR_MONTLY_VIEW && isCalendarMode,
            "peer-checked/monthly:bg-zinc-700 peer-checked/monthly:shadow": view === CALENDAR_MONTLY_VIEW && isSubMode,
          })}
          htmlFor={`view-${view}`}
          key="label"
        >
          {view}
        </label>
      ])}
    </div>
  );
}
