import noop from "lodash/noop";
import React, { createContext, useMemo, useState } from "react";

export const PagyContext = createContext({
  pagy: {
    next: 2,
    prev: null,
    page: 1,
    pages: 1,
  },
  setPagy: noop,
});
const PagyProvider = ({ children }) => {
  const [pagy, setPagy] = useState({
    next: 2,
    prev: null,
    page: 1,
    pages: 1,
  });
  const [upperPageBound, setUpperPageBound] = useState(5);
  const [lowerPageBound, setLowerPageBound] = useState(0);
  const value = useMemo(() => ({
    lowerPageBound,
    pagy,
    setPagy,
    setLowerPageBound,
    setUpperPageBound,
    upperPageBound,
  }), [
    lowerPageBound,
    pagy,
    setPagy,
    setLowerPageBound,
    setUpperPageBound,
    upperPageBound,
  ]);

  return (
    <PagyContext.Provider value={value}>
      {children}
    </PagyContext.Provider>
  );
};

export default PagyProvider;
