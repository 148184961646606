import { createContext, useContext } from "react";

export const ExpandableContext = createContext(null);
export const useExpandableContext = () => {
  const context = useContext(ExpandableContext);

  if (context === null) {
    throw new Error("Expandable components must be wrapped in <Expandable />");
  }

  return context;
};
