import React from "react";
import { Outlet } from "react-router-dom";

import { PagyProvider } from "../common/Pagy";
import Header from "./Header";
import Navigation from "./Navigation";
import {TableProvider} from "../common/Table";

const TutorStudentDashboard = ({fullName, timeZoneName}) => (
  <PagyProvider>
    <div className="max-w-7xl mx-auto w-full px-4 md:px-6 xl:px-8 py-6" key="content">
      <Header fullName={fullName} timeZoneName={timeZoneName}/>
      <Navigation/>
      <TableProvider>
        <Outlet/>
      </TableProvider>
    </div>
  </PagyProvider>
);

export default TutorStudentDashboard;
