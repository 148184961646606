import { useContext } from "react";
import { CurrentViewContext } from "../contexts/Calendar";
import { CALENDAR_NORMAL_MODE, CALENDAR_SUB_MODE } from "../constants";

export const useCalendarViewMode = () => {
  const { calendarViewMode, setCalendarViewMode } = useContext(CurrentViewContext);
  const isCalendarMode = calendarViewMode === CALENDAR_NORMAL_MODE;
  const isSubMode = calendarViewMode === CALENDAR_SUB_MODE;
  const setCalendarMode = () => {
    setCalendarViewMode(CALENDAR_NORMAL_MODE);
  };
  const setSubMode = () => {
    setCalendarViewMode(CALENDAR_SUB_MODE);
  };

  return { isCalendarMode, setCalendarMode, isSubMode, setSubMode };
}
