import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"
import { utcToZonedTime, format } from "date-fns-tz"

export default class extends Controller {
  static classes = ["visible"]
  static targets = [
    "hiddenFormField",
    "monthlyDataMeetingDateTable",
    "monthlyDataMeetingDateList",
    "monthlyDataMeetingDateListItemTemplate"
  ]

  static values = {
    dates: { type: Array, default: [] }
  }

  connect() {
    this.refreshMonthlyDataMeetingDateList(this.datesValue)
  }

  addMonthlyDataMeetingDate() {
    const newMonthlyDataMeetingDateElement =
      document.querySelector("#monthly_data_meeting_date_picker")

    const newMonthlyDataMeetingDate = newMonthlyDataMeetingDateElement.value
    if (new Date(newMonthlyDataMeetingDate).toString() !== 'Invalid Date' ) {
      const newMonthlyDataMeetingDateObject = {
        date: newMonthlyDataMeetingDate,
      }

      const monthlyDataMeetingDatesCopy = structuredClone(this.datesValue)
      const existingMonthlyDataMeetingDateIndex =
        monthlyDataMeetingDatesCopy.findIndex(e => e.date === newMonthlyDataMeetingDate)

      // Replace or Add the new monthly date meeting date
      if (existingMonthlyDataMeetingDateIndex !== -1) {
        monthlyDataMeetingDatesCopy[existingMonthlyDataMeetingDateIndex] = newMonthlyDataMeetingDateObject
      } else {
        monthlyDataMeetingDatesCopy.push(newMonthlyDataMeetingDateObject)
      }

      // Reset the monthly date meeting date form
      newMonthlyDataMeetingDateElement.value = ''
      this.refreshMonthlyDataMeetingDateList(monthlyDataMeetingDatesCopy)
    }
  }

  removeMonthlyDataMeetingDate(event) {
    event.preventDefault()
    const dateToRemove = event.target.closest("[data-monthly-data-meeting-date]").dataset.monthlyDataMeetingDate
    const remainingMonthlyDataMeetingDates =
      this.datesValue.filter(data => data.date !== dateToRemove)
    this.refreshMonthlyDataMeetingDateList(remainingMonthlyDataMeetingDates)
  }

  refreshMonthlyDataMeetingDateList(updatedMonthlyDataMeetingDates) {
    this.datesValue = updatedMonthlyDataMeetingDates.sort((a, b) => {
      return (new Date(a.date) - new Date(b.date))
    })

    // Update the hidden field with the updated monthly date meeting date values
    this.hiddenFormFieldTarget.value = JSON.stringify(this.datesValue)

    // Repaint the list with the updated monthly date meeting date values
    this.monthlyDataMeetingDateListTarget.innerHTML = ''
    const listItemTemplate = template(this.monthlyDataMeetingDateListItemTemplateTarget.innerHTML)

    this.datesValue.forEach((monthlyDataMeetingDate) => {
      // By default all JavaScript dates are Timestamps based in UTC. However, all of the
      // monthly data meeting dates that we store are intended to be used as local time. To
      // ensure the dates are displayed properly, we need tell JavaScript to use its
      // native 'UTC'. Essentially, we are using zoneless dates.
      var zonedDate = utcToZonedTime(new Date(monthlyDataMeetingDate.date), 'UTC')
      var templateData = {
        monthlyDataMeetingDate: monthlyDataMeetingDate.date,
        formattedMonthlyDataMeetingDate: format(zonedDate, 'MMMM d, y'),
      }

      this.monthlyDataMeetingDateListTarget.innerHTML += listItemTemplate(templateData)
    })

    if (this.datesValue.length > 0) {
      this.monthlyDataMeetingDateTableTarget.classList.remove(this.visibleClass)
    } else {
      this.monthlyDataMeetingDateTableTarget.classList.add(this.visibleClass)
    }
  }
}
