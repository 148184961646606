import React, {useState} from "react";

import { Modal, ModalClose, ModalContent } from "../common/Modal";
import { useDeleteNoteMutation } from "./queries";

const DeleteNote = ({ noteId }) => {
  const [open, setOpen] = useState(false);
  const mutation = useDeleteNoteMutation(noteId);
  const onSubmit = (event) => {
    mutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
      },
    });
    event.preventDefault();
  };
  const openModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <button className="absolute top-50 right-0 translate-y-50" onClick={openModal}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
             stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
        </svg>
      </button>
      <Modal open={open} onOpenChange={setOpen}>
        <ModalContent data-testid="delete-note">
          <ModalClose className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1" data-testid="celebratory-modal-close-button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </ModalClose>
          <div className="sm:flex sm:items-start">
            <div
              className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-base font-medium leading-6 text-gray-900" id="modal-title">Remove</h3>
              <div>
                <p className="text-sm text-gray-500 font-normal">Are you sure you want to remove this note?</p>
              </div>
            </div>
          </div>
          <div className="mt-5 flex flex-col md:flex-row-reverse items-center">
            <form onSubmit={onSubmit}>
              <div className="mt-5 space-y-2 space-x-2 sm:space-y-0 sm:mt-4">
                <ModalClose>Cancel</ModalClose>
                <button
                  className="button-primary ring-red-700 bg-red-500 text-white enabled:hover:bg-red-700"
                  disabled={mutation.isLoading}
                  type="submit"
                >
                  Remove
                </button>
              </div>
            </form>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default DeleteNote;
