import { Controller } from "@hotwired/stimulus"
import { arrow, computePosition, flip, offset, shift } from "@floating-ui/dom";

import {
  TOOLTIP_CLOSE_DELAY_IN_MS,
  TOOLTIP_DEFAULT_SHIFT_PADDING,
  TOOLTIP_DEFAULT_Y_AXIS_OFFSET,
  TOOLTIP_OPEN_DELAY_IN_MS
} from "../constants";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ["arrow", "placement", "tooltip", "trigger"]
  static values = {
    closeDelay: { type: Number, default: TOOLTIP_OPEN_DELAY_IN_MS },
    openDelay: { type: Number, default: TOOLTIP_CLOSE_DELAY_IN_MS },
  }

  initialize() {
    super.initialize();
    this.timerId = null;
  }

  connect() {
    this.triggerTarget.addEventListener("mouseover", this.show.bind(this));
    this.triggerTarget.addEventListener("mouseout", this.hide.bind(this));
    this.tooltipTarget.addEventListener("mouseover", this.preventHide.bind(this));
    this.tooltipTarget.addEventListener("mouseout", this.hide.bind(this));
  }

  disconnect() {
    this.triggerTarget.removeEventListener("mouseover", this.show.bind(this));
    this.triggerTarget.removeEventListener("mouseout", this.hide.bind(this));
    this.tooltipTarget.removeEventListener("mouseover", this.preventHide.bind(this));
    this.tooltipTarget.removeEventListener("mouseout", this.hide.bind(this));
  }

  show() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.tooltipTarget.classList.remove("hidden");
      this.update();
    }, this.openDelayValue);
  }

  hide() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.tooltipTarget.classList.add("hidden");
    }, this.closeDelayValue);
  }

  preventHide() {
    clearTimeout(this.timerId);
  }

  update() {
    computePosition(this.hasPlacementTarget ? this.placementTarget : this.triggerTarget, this.tooltipTarget, {
      placement: "top",
      middleware: [
        offset({ mainAxis: this.mainAxisOffset }),
        flip(),
        shift({ padding: TOOLTIP_DEFAULT_SHIFT_PADDING }),
        arrow({ element: this.arrowTarget }),
      ]
    }).then(({ x, y, placement, middlewareData }) => {
      const { x: arrowX, y: arrowY } = middlewareData.arrow;
      const staticSide = {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right",
      }[placement.split("-")[0]];

      Object.assign(this.tooltipTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
      Object.assign(this.arrowTarget.style, {
        left: arrowX !== null ? `${arrowX}px` : "",
        top: arrowY !== null ? `${arrowY}px` : "",
        right: "",
        bottom: "",
        [staticSide]: "-5px",
      });
    });
  }

  get mainAxisOffset() {
    return TOOLTIP_DEFAULT_Y_AXIS_OFFSET + this.arrowTarget.getBoundingClientRect().height / 2;
  }
}
