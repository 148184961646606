import React from "react";

import AssessmentButton from "./AssessmentButton";
import StudentAssessmentsResults from "../StudentAssessmentsResults";

const AssessmentRow = ({ content, assessmentable }) => {
  return (
    <>
      {assessmentable.isBaseline ?
        <tr className="grid auto-cols-auto gap-0.5 w-[calc(100vw-2rem)] md:table-row border-t border-gray-200">
          <td className="whitespace-nowrap col-start-1 pt-4 md:py-4 md:pr-3 pl-0 lg:pl-3" colSpan={4}>
            {content.displayName}
          </td>
          <td
            className="whitespace-nowrap col-start-3 row-span-2 flex items-center justify-end md:py-4 lg:pl-3 pr-0 lg:pr-3 md:text-right font-medium">
            <div className="flex justify-end">
              <AssessmentButton content={content} assessmentable={assessmentable}/>
            </div>
          </td>
        </tr> :
        <tr className="grid auto-cols-auto gap-0.5 w-[calc(100vw-2rem)] md:table-row border-t border-gray-200">

          <td className="whitespace-nowrap col-start-1 pt-4 md:py-4 md:pr-3 pl-0 lg:pl-3" colSpan={2}>
            {content.displayName}
            {content.status == "in_progress" &&
              <span className="rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800 ml-1">
              In Progress
            </span>
            }
          </td>
          <td
            className="whitespace-nowrap col-start-2 row-start-1 pt-4 md:py-4 md:pr-3 md:text-center before:content-[attr(data-title)] before:font-medium before:text-zinc-500 before:truncate lg:before:hidden"
            data-title="Completions:">
            {content.completions > 0 ? content.completions : "-"}
          </td>
          <td
            className="whitespace-nowrap col-start-2 row-start-2 pb-4 md:py-4 md:pr-3 md:text-center before:content-[attr(data-title)] before:font-medium before:text-zinc-500 before:truncate lg:before:hidden"
            data-title="HFW:">
            -
          </td>
          <td
            className="whitespace-nowrap col-start-3 row-span-2 flex items-center justify-end md:py-4 lg:pl-3 pr-0 lg:pr-3 md:text-right font-medium">
            <div className="flex justify-end">
              <AssessmentButton content={content} assessmentable={assessmentable}/>
            </div>
          </td>
        </tr>
      }
      { content.displayResults ?
        <tr className="md:table-row border-t border-gray-200">
          <td className="p-0" colSpan={5}>
            <div className="max-w-[894px] mx-auto whitespace-nowrap p-4 sm:p-6 lg:p-10">
              <StudentAssessmentsResults assessmentId={content.id} studentAssessmentable={assessmentable} isBaseline={assessmentable.isBaseline}/>
            </div>
          </td>
        </tr>
       : null
      }
    </>
  );
}

export default AssessmentRow;
