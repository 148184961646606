import React from "react";
import cx from "classnames";

import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";

export default ({ timeZoneName }) => {
  const { isCalendarMode, isSubMode } = useCalendarViewMode();

  return (
    <div className={`flex`}>
      <em className="align-middle leading-8">
        All times are {timeZoneName}
      </em>
      <Tooltip>
        <TooltipTrigger>
          <button
            className={cx("flex items-center justify-center w-4 ml-1 align-middle h-8", {
              "text-zinc-500/80 hover:text-zinc-500": isCalendarMode,
              "text-zinc-400/80 hover:text-zinc-400": isSubMode,
            })}
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-full">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
            </svg>
          </button>
        </TooltipTrigger>
        <TooltipContent>
          You can change the time zone<br />from <a className="text-brand-500 hover:underline" href="/profile/edit">your profile page</a>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}
