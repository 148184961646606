import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useRef } from "react";

import AutoReteachFocusBanner from "./Main";

const REACT_QUERY_STALE_TIME = 20 * 1000;

export default ({studentLessonId}) => {
  const queryClient = useRef(new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: REACT_QUERY_STALE_TIME,
      },
    },
  }));

   return (
     <QueryClientProvider client={queryClient.current}>
      <AutoReteachFocusBanner studentLessonId={studentLessonId} />
     </QueryClientProvider>
   )
}
