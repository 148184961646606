import { useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react";
import { useMemo, useState } from "react";

export default ({ open: controlledOpen, onOpenChange: setControlledOpen }) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const floating = useFloating({ open, onOpenChange: setOpen });
  const click = useClick(floating.context, { enabled: !controlledOpen });
  const dismiss = useDismiss(floating.context, { outsidePressEvent: "mousedown" });
  const role = useRole(floating.context);
  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(() => ({
    open,
    setOpen,
    ...interactions,
    ...floating,
  }), [open, setOpen, interactions, floating]);
}
