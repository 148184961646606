import { format, parse } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { EASTERN_TIME_ZONE, MERIDIEM_TIME_FORMAT, STANDARD_DATE_FORMAT } from "../constants";

export default function ({date, timeZone}) {
  const dateInUtc = zonedTimeToUtc(date, timeZone);
  const dateInEST = utcToZonedTime(dateInUtc, EASTERN_TIME_ZONE).toISOString();
  const parsedDate = parse(dateInEST, STANDARD_DATE_FORMAT, new Date());

  return format(parsedDate, MERIDIEM_TIME_FORMAT);
}
