import { useCallback, useMemo } from "react";
import { eachDayOfInterval, isWeekend, format, parseISO } from "date-fns";

import { DEFAULT_DATE_FORMAT } from "../constants";
import { useTimeBlocksQuery } from "../components/TutorCalendar/queries";

export const useTutorTimeOffs = () => {
  const timeBlocksQuery = useTimeBlocksQuery();
  const { tutorTimeOffs } = timeBlocksQuery.data || {};
  const timeOffStatuses = useMemo(() => {
    const map = new Map();
    if (tutorTimeOffs) {
      tutorTimeOffs.forEach(tutorTimeOff => {
        eachDayOfInterval({
          start: parseISO(tutorTimeOff.startDate),
          end: parseISO(tutorTimeOff.endDate),
        }).filter(date => !isWeekend(date)).forEach(date => {
          map.set(format(date, DEFAULT_DATE_FORMAT), tutorTimeOff.status);
        });
      });
    }
    return map;
  }, [tutorTimeOffs]);
  const getTimeOffStatus = useCallback(date => {
    const dateString  = format(date, DEFAULT_DATE_FORMAT);
    if (timeOffStatuses.has(dateString)) {
      return timeOffStatuses.get(dateString);
    }
    return null;
  }, [timeOffStatuses])

  return { timeOffStatuses, getTimeOffStatus };
}
