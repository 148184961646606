import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    inheritedSchedule: String,
  }

  overrideSchedule(event) {
    event.preventDefault()

    const inheritedSchedule = this.inheritedScheduleValue.replace(/[[\]",]+/g, "").split(" ")
    const buttonText = event.target.innerHTML
    const enable = buttonText.includes("Enable")
    const newText = enable ? buttonText.replace("Enable", "Disable") : buttonText.replace("Disable", "Enable")

    event.target.closest("div[data-controller='schedule-input']").querySelectorAll("input").forEach((input) => {
      input.disabled = !enable

      if (!enable) {
        input.checked = inheritedSchedule.includes(input.value)
      }
    })

    event.target.innerHTML = newText
  }
}
