import React from "react";
import { Rnd } from "react-rnd";

import { TUTOR_AVAILABILITY_START_HOUR_EASTERN } from "../../constants";
import { formatTimeToMeridiem, minutesCountToTime } from "../../utils"

const CELL_HEIGHT = 16;

export default ({ availability, endY, onChange, startY, timeZoneOffset }) => {
  const height = endY - startY;
  const onResize = (event, dir, ref, delta, position) => {
    const startTimeInMinutes = ((TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset) * 60) + (position.y / CELL_HEIGHT * 15);
    const endTimeInMinutes = startTimeInMinutes + (parseInt(ref.style.height, 10) / CELL_HEIGHT * 15);

    onChange(minutesCountToTime(startTimeInMinutes), minutesCountToTime(endTimeInMinutes));
  };
  const onDrag = (event, data) => {
    const startTimeInMinutes = ((TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset) * 60) + (data.y / CELL_HEIGHT * 15);
    const endTimeInMinutes = startTimeInMinutes + (height / CELL_HEIGHT * 15);

    onChange(minutesCountToTime(startTimeInMinutes), minutesCountToTime(endTimeInMinutes));
  };

  return (
      <Rnd
        axis="y"
        bounds="parent"
        className={`absolute bg-[#f3eaf2] border border-brand-500 rounded-[6px] left-0 right-0 min-h-[${CELL_HEIGHT}px] p-[1px] flex flex-col items-start`}
        dragAxis="y"
        dragGrid={[1, CELL_HEIGHT]}
        enableResizing={{
          top: true,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        height={height}
        onDrag={onDrag}
        onResize={onResize}
        position={{ x: 0, y: startY }}
        size={{ height, width: "auto" }}
        resizeGrid={[1, CELL_HEIGHT]}
      >
        <span className="text-[10px] font-semibold leading-none text-white bg-brand-500 rounded-[4px] px-1.5 py-[1px] absolute top-[1px] left-[1px]">
          {formatTimeToMeridiem(availability.start_time)} - {formatTimeToMeridiem(availability.end_time)}
        </span>
      </Rnd>
  )
}
