import cx from "classnames";
import React, { useMemo } from "react";
import { components } from "react-select";

import { timeToMinutesCount } from "../../utils";

export default ({ children, className, ...props }) => {
  const { highDemandIntervals } = props.selectProps;
  const isWithinHighDemandInterval = useMemo(() => {
    return highDemandIntervals.some(({ endTime, startTime }) => {
      const endTimeInMinutes = timeToMinutesCount(endTime);
      const startTimeInMinutes = timeToMinutesCount(startTime);
      const timeInMinutes = timeToMinutesCount(props.value);

      return timeInMinutes >= startTimeInMinutes && timeInMinutes < endTimeInMinutes;
    });
  }, [highDemandIntervals, props.value]);
  const isStartOfHighDemandInterval = useMemo(() => {
    return highDemandIntervals.map(interval => interval.startTime).some(time => props.value === time);
  }, [highDemandIntervals, props.value])

  return (
    <components.Option
      className={cx("relative", {
        "!bg-yellow-300/10 hover:!bg-yellow-400/20": isWithinHighDemandInterval && !props.isSelected
      }, className)}
      {...props}
    >
      {children}
      {isStartOfHighDemandInterval ?
        <svg
          className="absolute right-1.5 top-1.5 text-yellow-300 w-3.5 h-3.5 mr-0.5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
        </svg>
      : null}
    </components.Option>
  );
}
