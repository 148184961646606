import { useCallback, useContext } from "react";

import { SparkCookieContext } from "./index";

const useSparkCookie = (key, initialValue) => {
  const context = useContext(SparkCookieContext);

  if (context === null) {
    throw new Error("Components where useSparkCookie is used must be wrapped with <SparkCookieContext.Provider />");
  }

  const { getCookie, setCookie } = context;
  const value = getCookie(key) || initialValue;
  const updateValue = useCallback((nextValue) => {
    setCookie(key, nextValue);
  }, [key, setCookie]);

  return [value, updateValue];
};

export default useSparkCookie;
