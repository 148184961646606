import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"

export default class extends Controller {
  static classes = ["visible"]
  static targets = [
    "hiddenFormField",
    "classroomTeacherTable",
    "classroomTeacherList",
    "classroomTeacherListItemTemplate",
    "classroomTeacherFormTemplate"
  ]

  static values = {
    teacherId: String,
    schoolSystemId: String,
    schoolSystemName: String,
    schoolId: String,
    schoolName: String,    
    classroomTeachers: { type: Array, default: [] }
  }  

  connect() {
    this.refreshClassroomTeacherList(this.classroomTeachersValue)
  }

  addClassroomTeacher() {
    const schoolSystemSelectElement = document.querySelector("#school_school_system_id")
    const schoolSelectElement = document.querySelector("#teacher_school_id")
    const schoolCalendarSelectElement = document.querySelector("#school_calendar_id")
    const schoolCalendarClassroomSelectElement = document.querySelector("#classroom_id")

    // If the teacher has already been persisted, we can simply grab the school system
    // and school information from the Values API. Otherwise, we will need to grab these
    // values from the select option elements.
    const schoolSystemName = this.schoolSystemNameValue ||
      schoolSystemSelectElement.options[schoolSystemSelectElement.selectedIndex].text
    const schoolName = this.schoolNameValue ||
      schoolSelectElement.options[schoolSelectElement.selectedIndex].text
    const schoolCalendarName =
      schoolCalendarSelectElement.options[schoolCalendarSelectElement.selectedIndex].text
    const classroomName =
      schoolCalendarClassroomSelectElement.options[schoolCalendarClassroomSelectElement.selectedIndex].text
    const classroomId = schoolCalendarClassroomSelectElement.value

    const allTeacherClassroomFieldsSelected = schoolCalendarSelectElement.selectedIndex != 0 &&
      schoolCalendarClassroomSelectElement.selectedIndex != 0

    const classroomTeacherDetected = this.classroomTeachersValue.some((e) => {
      // Explicity casting everything to Strings so that the comparisons don't break.
      // This is needed because dependinig on where the data is coming from, it is
      // sometimes represented as an integer
      return `${e.classroom_id}` === `${classroomId}` && `${e.teacher_id}` === `${this.teacherIdValue}`
    })

    // If the classroom has already been assigned to the teacher, then we don't need to re-add it.
    if (!classroomTeacherDetected && allTeacherClassroomFieldsSelected) {
      // Resetting the classroom selection form
      schoolCalendarClassroomSelectElement.selectedIndex = 0

      // Creating a new object to be used to update the classrooms Table
      const newRecordId = new Date().getTime()
      const newClassroomTeacherObject = {
        id: newRecordId,
        school_system_name: schoolSystemName,
        school_name: schoolName,
        school_calendar_name: schoolCalendarName,
        classroom_name: classroomName,
        classroom_id: classroomId,
        teacher_id: this.teacherIdValue
      }

      const classroomTeachersValueCopy = structuredClone(this.classroomTeachersValue)
      classroomTeachersValueCopy.push(newClassroomTeacherObject)

      // Creating a copy of the nested classroom form and populating it with the
      // values pulled out of the school selector
      const content = this.classroomTeacherFormTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newRecordId)
      this.classroomTeacherFormTemplateTarget.insertAdjacentHTML("beforebegin", content)

      document.querySelector(`#teacher_classroom_teachers_attributes_${newRecordId}_classroom_id`).value = classroomId
      document.querySelector(`#teacher_classroom_teachers_attributes_${newRecordId}_teacher_id`).value = this.teacherIdValue

      this.refreshClassroomTeacherList(classroomTeachersValueCopy)    
    }
  }

  removeClassroomTeacher(event) {
    event.preventDefault()

    const classroomTeacherIdToRemoveDataset =
      event.target.closest("[data-classroom-teacher-id]").dataset
    const classroomTeacherIdToRemove =
      classroomTeacherIdToRemoveDataset.classroomTeacherId
    const classroomTeacherPersisted =
      classroomTeacherIdToRemoveDataset.classroomTeacherPersisted

    // Note the "boolean" value from the dataset provied as a string
    if (classroomTeacherPersisted === "true") {
      document.querySelector(`[data-classroom-teacher-id-to-remove="${classroomTeacherIdToRemove}"]`).value = true
    } else {
      document.querySelector(`#teacher_classroom_teachers_attributes_${classroomTeacherIdToRemove}_classroom_id`).remove()
      document.querySelector(`#teacher_classroom_teachers_attributes_${classroomTeacherIdToRemove}_teacher_id`).remove()
    }

    const remainingClassroomTeachers =
      this.classroomTeachersValue.filter(classroomTeacher => classroomTeacher.id != classroomTeacherIdToRemove)

    this.refreshClassroomTeacherList(remainingClassroomTeachers)
  }

  refreshClassroomTeacherList(updatedClassroomTeachers) {
    // This sorting doesn't seem to be doing anything. At any rate, we
    // should probably be sorting by the school calendar start dates
    this.classroomTeachersValue = updatedClassroomTeachers.sort((a, b) => a.name - b.name)

    // Repaint the list with the updated classroom values
    this.classroomTeacherListTarget.innerHTML = ''

    const listItemTemplate = template(this.classroomTeacherListItemTemplateTarget.innerHTML)

    this.classroomTeachersValue.forEach((classroomTeacher) => {
      let templateData = {
        classroomTeacherId: classroomTeacher.id,
        schoolSystemName: classroomTeacher.school_system_name,
        schoolName: classroomTeacher.school_name,
        schoolCalendarName: classroomTeacher.school_calendar_name,
        classroomName: classroomTeacher.classroom_name,
        classroomTeacherPersisted: classroomTeacher.created_at !== undefined
      }

      this.classroomTeacherListTarget.innerHTML += listItemTemplate(templateData)
    })

    if (this.classroomTeachersValue.length > 0) {
      this.classroomTeacherTableTarget.classList.remove(this.visibleClass)
    } else {
      this.classroomTeacherTableTarget.classList.add(this.visibleClass)
    }    
  }
}
