import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get } from "../../api";

export const useStudentQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student", studentId],
    queryFn: async ({ signal }) => {
      const { data } = await get(`/students/${studentId}`, { signal });

      return data;
    },
  });
};
