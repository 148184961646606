import React from "react";
import { twMerge } from "tailwind-merge";

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";

const InfoTooltip = ({ children, className }) => (
  <Tooltip>
    <TooltipTrigger>
      <button className={twMerge("text-zinc-500 hover:text-zinc-700 w-5 sm:w-6 h-5 sm:h-6", className)} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
      </button>
    </TooltipTrigger>
    <TooltipContent>
      <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm text-zinc-600 px-2 md:px-3 py-2">
        {children}
      </div>
    </TooltipContent>
  </Tooltip>
);

 export default InfoTooltip;
