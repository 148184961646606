import React from "react";

import {
  StudentAssessmentContentCollapsed,
  StudentAssessmentReadingStatsItem,
  useStudentAssessmentQuery
} from "../StudentAssessment";

const ContentCollapsed = () => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { correctWordsCount, hasEndWord } = studentAssessmentQuery.data || {};

  if (!hasEndWord) return null;

  if (!studentAssessmentQuery.data) {
    if (studentAssessmentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentAssessmentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <StudentAssessmentContentCollapsed>
      <ul className="flex space-x-1 md:space-x-4 mb-6">
        <StudentAssessmentReadingStatsItem
          emphasized
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 33 32">
              <path fillRule="evenodd" clipRule="evenodd" d="M16.6666 1.6001C13.7411 1.6001 10.8703 1.83814 8.07272 2.29616C7.49253 2.39115 7.0666 2.89248 7.0666 3.48039V4.09885C5.74402 4.34977 4.43939 4.65157 3.15483 5.00214C2.64774 5.14053 2.28973 5.59283 2.27149 6.11815C2.26824 6.21179 2.2666 6.30578 2.2666 6.4001C2.2666 10.5519 5.42893 13.9646 9.47678 14.3616C10.7078 15.7518 12.3376 16.7838 14.1891 17.2771C14.0352 18.5352 13.6569 19.7229 13.0953 20.8001H12.6666C11.3411 20.8001 10.2666 21.8746 10.2666 23.2001V27.2001H9.0666C7.96203 27.2001 7.0666 28.0955 7.0666 29.2001C7.0666 29.8628 7.60386 30.4001 8.2666 30.4001H25.0666C25.7293 30.4001 26.2666 29.8628 26.2666 29.2001C26.2666 28.0955 25.3712 27.2001 24.2666 27.2001H23.0666V23.2001C23.0666 21.8746 21.9921 20.8001 20.6666 20.8001H20.2379C19.6763 19.7229 19.298 18.5352 19.1441 17.2771C20.9956 16.7838 22.6254 15.7518 23.8564 14.3616C27.9043 13.9646 31.0666 10.5519 31.0666 6.4001C31.0666 6.30575 31.065 6.21176 31.0617 6.11815C31.0435 5.59283 30.6855 5.14053 30.1784 5.00214C28.8938 4.65157 27.5892 4.34977 26.2666 4.09885V3.48039C26.2666 2.89248 25.8407 2.39115 25.2605 2.29616C22.4629 1.83814 19.5921 1.6001 16.6666 1.6001ZM4.70698 7.076C5.48622 6.87971 6.27293 6.70215 7.0666 6.54384V8.0001C7.0666 9.18328 7.28106 10.3173 7.6731 11.3647C6.07568 10.5284 4.93168 8.94358 4.70698 7.076ZM28.6262 7.076C28.4015 8.94358 27.2575 10.5284 25.6601 11.3647C26.0521 10.3173 26.2666 9.18328 26.2666 8.0001V6.54384C27.0603 6.70215 27.847 6.87971 28.6262 7.076Z" />
            </svg>
          }
          label="Total Correct"
          unitOfMeasurement="Words Correct"
          value={correctWordsCount}
        />
      </ul>
    </StudentAssessmentContentCollapsed>
  );
}

export default ContentCollapsed;
