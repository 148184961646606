import React from "react";
import { Outlet } from "react-router-dom";

import { PagyProvider } from "../common/Pagy";
import Header from "./Header";

const Student = () => (
  <PagyProvider>
    <Header />
    <section className="max-w-7xl mx-auto w-full px-0 md:px-6 lg:px-8 grow flex flex-col">
      <Outlet />
    </section>
  </PagyProvider>
);

export default Student;
