import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "button"]

  connect() {
    document.addEventListener("keydown", this.keydownHandler.bind(this))
  }

  disconnect() {
    document.removeEventListener("keydown", this.keydownHandler.bind(this))
  }

  toggle(event) {
    event.preventDefault();

    if (this.containerTarget.classList.contains("hidden")) {
      this.show();
    } else {
      this.hide(null);
    }
  }

  show() {
    this.containerTarget.classList.remove("hidden")
    enter(this.containerTarget)
  }

  hide(event) {
    if (event && (this.containerTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
      return;
    }

    Promise.all([
      leave(this.containerTarget)      
    ]).then(() => {
      this.containerTarget.classList.add("hidden")
    })
  }

  keydownHandler(event) {
    if (event.key === "Escape") {
      this.hide()
    }
  }
}
