import React from "react";

import { useStudentQuery } from "../Student/queries";
import CalendarOutline from "assets/icons/calendar-outline.svg";
import DocumentTextMini from "assets/icons/document-text-mini.svg";
import { useSparkCookie } from "../common/SparkCookie";
import XMarkMini from "assets/icons/x-mark-mini.svg";

export const MOP_SCREENER_BANNER_COOKIE_KEY = "mop_banner_dismissed";

const UpcomingMopBanner = () => {
  const studentQuery = useStudentQuery();
  const { assessmentScriptURL, isUpcomingMop } = studentQuery.data || {};
  const [isDismissed, setIsDismissed] = useSparkCookie(MOP_SCREENER_BANNER_COOKIE_KEY, "false");
  const onDismiss = () => {
    setIsDismissed("true");
  };
  const onShowBanner = () => {
    setIsDismissed("false");
  };

  return isUpcomingMop ? (
    isDismissed === "true" ? (
      <div className="flex justify-end">
        <button
          className="inline-flex items-center text-sm text-brand-600 hover:text-zinc-800 hover:underline font-semibold mt-6 mx-4"
          data-testid="show-upcoming-mop-banner"
          onClick={onShowBanner}
        >
          MOP Screener is in less than 7 days
        </button>
      </div>
    ) : (
      <div
        className="flex items-center rounded-lg border border-brand-500 bg-brand-50 px-4 py-5 space-x-4 mt-6 relative mx-4 md:mx-0"
        data-testid="upcoming-mop-banner"
      >
        <div className="grow-0">
          <div className="rounded-full bg-brand-100 p-1.5">
            <CalendarOutline className="w-5 h-5" />
          </div>
        </div>
        <div className="space-y-2">
          <h2 className="text-base font-semibold text-gray-600">
            Upcoming Mid of Program Screener{" "}
          </h2>
          <p className="text-gray-500 text-sm flex items-center">
            This student’s MOP Screener is coming up in less than 7 days, make sure you have
            everything you need.
            <a
              className="inline-flex items-center text-sm font-medium text-brand-600 hover:text-zinc-800 hover:underline ml-3 whitespace-nowrap"
              data-testid="mop-screener-script-link"
              href={assessmentScriptURL}
              target="_blank"
              rel="noreferrer"
            >
              <DocumentTextMini className="w-5 h-5 mr-1" />
              Screener Script
            </a>
          </p>
        </div>
        <button
          className="flex items-center space-x-1.5 text-xs text-gray-500 hover:text-gray-700 hover:underline absolute top-3 right-5"
          data-testid="dismiss-upcoming-mop-banner"
          onClick={onDismiss}
          type="button"
        >
          <XMarkMini className="w-4 h-4" />
          Close
        </button>
      </div>
    )
  ) : null;
};

export default UpcomingMopBanner;
