import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from "@floating-ui/react";
import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { useFloatingContext } from "./context";

const Content = forwardRef((props, propRef) => {
  const { context: floatingContext, refs, getFloatingProps } = useFloatingContext();
  const ref = useMergeRefs([refs.setFloating, propRef]);

  return (
    <FloatingPortal>
      <FloatingOverlay className="bg-gray-500 bg-opacity-75 grid place-items-center p-4 z-50" lockScroll>
        <FloatingFocusManager context={floatingContext}>
          <div className={twMerge(props.className)} ref={ref} {...getFloatingProps(props)}>
            {props.children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

export default Content;
