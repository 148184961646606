import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useExpandableContext } from "./context";

const Content = ({ children }) => {
  const { expanded  } = useExpandableContext();

  return (
    <AnimatePresence>
      {expanded ? (
        <motion.div
          className="overflow-hidden"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
        >
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default Content;
