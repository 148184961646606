import React from "react";
import { twMerge } from "tailwind-merge";

import ArrowRightOutline from "assets/icons/arrow-right-outline.svg";

const ContinueLessonButton = ({ className, submitButtonLabel = "Continue Lesson", ...props }) => (
  <button
    className={twMerge(
      "button-primary w-full self-center flex items-center justify-center",
      className
    )}
    data-testid="continue-lesson"
    type="submit"
    {...props}
  >
    {submitButtonLabel}
    <ArrowRightOutline className="w-5 h-5 ml-2" />
  </button>
);

export default ContinueLessonButton;
