import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, parse, parseISO } from "date-fns";
import first from "lodash/first";
import safeGet from "lodash/get";
import last from "lodash/last";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { get, patch } from "../../api";
import Calendar from "./Calendar"
import Form from "./Form"
import MaxDailyHoursControls from "./MaxDailyHoursControls"
import {
  HOUR_MINUTE_ZERO_PADDED,
  MONTH_DAY_YEAR_DATE_FORMAT,
  TUTOR_AVAILABILITY_HIGH_DEMAND_TIMES_EASTERN,
  TUTOR_MAX_HOURS_PER_DAY,
} from "../../constants";
import { minutesCountToTime, timeToMinutesCount} from "../../utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import DynamicDropdown from "../common/DynamicDropdown";

const availabilitiesCbFn = availability => ({
  ...availability,
  end_time: format(parseISO(availability.end_time.replace("Z", "")), HOUR_MINUTE_ZERO_PADDED),
  start_time: format(parseISO(availability.start_time.replace("Z", "")), HOUR_MINUTE_ZERO_PADDED),
});

export default ({ timeZoneName, timeZoneOffset }) => {
  const toastIdRef = useRef();
  const [availabilities, setAvailabilities] = useState([]);
  const [tutorAvailabilityCycles, setTutorAvailabilityCycles] = useState([]);
  const [selectedCycleId, setSelectedCycleId] = useState(null);
  const [existingSessionsErrorMessage, setExistingSessionsErrorMessage] = useState("");
  const [changesImpactExistingSessions, setChangesImpactExistingSessions] = useState(false);
  const [maxHoursPerDay, setMaxHoursPerDay] = useState(TUTOR_MAX_HOURS_PER_DAY);
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient()
  const cyclesQuery = useQuery({
    queryKey: ["cycles"],
    queryFn: async () => {
      const { data } = await get("/tutor_availabilities.json");
      const firstCycle = first(first(data));
      const cycles = data
      return {
        ...firstCycle,
        availabilities: firstCycle.availabilities.map(availabilitiesCbFn),
        cycles
      };
    },
  });
  const mutation = useMutation({
    mutationFn: async (event) => {
      event.preventDefault();
      const availabilitiesPromise = patch("/tutor_availabilities/update.json", {
        tutor: {
          tutor_availabilities_attributes: availabilities.map(availability => {
            const { _destroy, id, end_time, start_time } = availability;

            return {
              _destroy,
              end_time,
              id,
              start_time,
              tutor_availability_cycle_id: selectedCycleId
            };
          }),
          tutors_tutor_availability_cycles_attributes: [{max_hours_per_day: parseFloat(maxHoursPerDay), tutor_availability_cycle_id: selectedCycleId}]
        },
      }).then(async response => {
        if (response.statusText === "OK" || response.status === 200) {
          return response;
        } else {
          const error = await response.json
          if (error["existing_sessions"][0]) {
            throw new Error("This change would impact your existing sessions. Please email your tutor operations team.")
          } else {
            throw new Error("Something went wrong");
          }
        }
      }).catch(error => {
        if (error.response.data["existing_sessions"]) {
          throw new Error("This change would impact your existing sessions. Please email your tutor operations team.")
        } else {
          throw new Error("Something went wrong");
        }
      });

      return await Promise.all([availabilitiesPromise]);
    },
    onMutate: () => {
      setIsSaving(true);
      setChangesImpactExistingSessions(false);
      toastIdRef.current = toast.loading("Saving. Please wait...");
    },
    onError: (error) => {
      setIsSaving(false);
      if (error.message.includes("existing sessions")) {
        setExistingSessionsErrorMessage(error.message)
        setChangesImpactExistingSessions(true);
        toast.dismiss(toastIdRef.current);
      } else {
        toast.update(toastIdRef.current, {
          autoClose: 3200,
          isLoading: false,
          render: "There was a problem saving your changes. Please check your selections and try again.",
          type: "error",
        });
      }
    },
    onSuccess: ([availabilitiesResponse]) => {
      queryClient.setQueryData(["availabilities"], availabilitiesResponse.data.availabilities.map(availabilitiesCbFn));
      setAvailabilities(availabilitiesResponse.data.availabilities.map(availabilitiesCbFn));
      queryClient.setQueryData(["max-hours-per-day"], parseFloat(availabilitiesResponse.data.max_hours_per_day));
      setMaxHoursPerDay(parseFloat(availabilitiesResponse.data.max_hours_per_day))
      let newTacs = tutorAvailabilityCycles.map((tutorAvailabilityCycle) => {
        if (tutorAvailabilityCycle.id === availabilitiesResponse.data.id) {
          return availabilitiesResponse.data
        } else {
          return tutorAvailabilityCycle
        }
      })
      setTutorAvailabilityCycles(newTacs)
      setIsSaving(false);
      toast.update(toastIdRef.current, {
        autoClose: 1800,
        isLoading: false,
        render: "Saved successfully",
        type: "success",
      });
    },
  });
  const onChangeMaxHoursPerDay = (value) => {
    setMaxHoursPerDay(value);
  };
  const onCloseChangesImpactExistingSessions = () => {
    setChangesImpactExistingSessions(false);
  };
  const sortedAvailabilities = useMemo(() => {
    return availabilities.filter(availability => !availability._destroy).sort((a, b) => {
      const aTimeInMinutes = a.start_time ? timeToMinutesCount(a.start_time) : Infinity;
      const bTimeInMinutes = b.start_time ? timeToMinutesCount(b.start_time) : Infinity;

      return aTimeInMinutes - bTimeInMinutes;
    });
  }, [availabilities]);
  const highDemandIntervals = useMemo(() => {
    return TUTOR_AVAILABILITY_HIGH_DEMAND_TIMES_EASTERN.map(time => timeToMinutesCount(time) + timeZoneOffset * 60)
      .reduce((acc, time) => {
        if (time - last(last(acc)) > 15) {
          return [...acc, [time]];
        }
        last(acc).push(time);

        return acc;
      }, [
        [
          first(TUTOR_AVAILABILITY_HIGH_DEMAND_TIMES_EASTERN.map(time => timeToMinutesCount(time) + timeZoneOffset * 60))
        ]
      ])
    .map((group) => ({
      startTime: minutesCountToTime(first(group)),
      endTime: minutesCountToTime(last(group) + 15),
    }));
  }, [timeZoneOffset]);
  const selectedCycle = useMemo(() => {
    return tutorAvailabilityCycles.find(({ id }) => id === selectedCycleId);
  }, [selectedCycleId, tutorAvailabilityCycles]);

  useEffect(() => {
    if (cyclesQuery.isFetched) {
      if (cyclesQuery.data.max_hours_per_day !== undefined && cyclesQuery.data.max_hours_per_day.length > 0) {
        setMaxHoursPerDay(cyclesQuery.data.max_hours_per_day[0].max_hours_per_day)
      }
      setAvailabilities(cyclesQuery.data.availabilities);
      setTutorAvailabilityCycles(cyclesQuery.data.cycles[0]);
      setSelectedCycleId(cyclesQuery.data.cycles[0][0].id);
    }
  }, [cyclesQuery.data, cyclesQuery.isFetched]);
  useEffect(() => {
    if (selectedCycle) {
      setAvailabilities(selectedCycle.availabilities.map(availabilitiesCbFn));
      if (selectedCycle.max_hours_per_day !== undefined && selectedCycle.max_hours_per_day.length > 0) {
        setMaxHoursPerDay(selectedCycle.max_hours_per_day[0].max_hours_per_day)
      } else {
        setMaxHoursPerDay(TUTOR_MAX_HOURS_PER_DAY)
      }
    }
  }, [selectedCycle]);

  if (!cyclesQuery.data) {
    if (cyclesQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {cyclesQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <form onSubmit={mutation.mutate}>
      <div className="sticky top-0 z-40">
        <div className="bg-white border-b flex items-center justify-between py-4 lg:py-7 px-4 md:px-6 lg:px-8">
          <div className="grow flex items-center">
            <Link className="button-secondary flex items-center mr-3 lg:mr-5 relative pl-8" to="/calendar">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#999fa9" className="w-3.5 h-3.5 absolute left-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
              Back
            </Link>
            <h1 className="text-base lg:text-3xl">Schedule preferences</h1>
          </div>
          <div>
            <button className="button-primary" disabled={isSaving} type="submit">
              Save
            </button>
          </div>
        </div>
        {changesImpactExistingSessions ?
          <div className="absolute top-full w-full border-b border-t -mt-[1px] border-yellow-200 bg-yellow-50 text-zinc-700 text-xs md:text-sm px-4 md:px-8 py-3 flex items-center justify-between">
            <div className="flex items-center">
              <svg viewBox="0 0 26 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="flex-none w-5 h-5 text-yellow-500 mr-2">
                <path d="M8.99998 7.00016H17V21.0002H8.99998V7.00016Z" fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.75123 2.25391C11.195 -0.246094 14.805 -0.246094 16.2475 2.25391L25.4412 18.1889C26.8837 20.6889 25.0787 23.8139 22.1925 23.8139H3.80623C0.919979 23.8139 -0.883771 20.6889 0.558729 18.1889L9.74998 2.25391H9.75123ZM13 8.81266C13.2486 8.81266 13.4871 8.91143 13.6629 9.08724C13.8387 9.26306 13.9375 9.50152 13.9375 9.75016V14.4377C13.9375 14.6863 13.8387 14.9248 13.6629 15.1006C13.4871 15.2764 13.2486 15.3752 13 15.3752C12.7513 15.3752 12.5129 15.2764 12.3371 15.1006C12.1613 14.9248 12.0625 14.6863 12.0625 14.4377V9.75016C12.0625 9.50152 12.1613 9.26306 12.3371 9.08724C12.5129 8.91143 12.7513 8.81266 13 8.81266ZM13 19.1252C13.2486 19.1252 13.4871 19.0264 13.6629 18.8506C13.8387 18.6748 13.9375 18.4363 13.9375 18.1877C13.9375 17.939 13.8387 17.7006 13.6629 17.5247C13.4871 17.3489 13.2486 17.2502 13 17.2502C12.7513 17.2502 12.5129 17.3489 12.3371 17.5247C12.1613 17.7006 12.0625 17.939 12.0625 18.1877C12.0625 18.4363 12.1613 18.6748 12.3371 18.8506C12.5129 19.0264 12.7513 19.1252 13 19.1252Z" fill="currentColor"/>
              </svg>
              {existingSessionsErrorMessage}
            </div>
            <button className="text-zinc-400 hover:text-zinc-600" onClick={onCloseChangesImpactExistingSessions} type="button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        : null}
      </div>
      <div className="max-w-7xl mx-auto w-full px-4 md:px-6 lg:px-10 py-4 lg:py-6">
        <div className="mb-4 md:mb-8 lg:mb-12 pt-0 md:pt-6 lg:pt-10">
          <div className="flex items-center justify-between lg:hidden">
            <h2 className="text-base md:text-lg">{safeGet(cyclesQuery.data, "name", "Availability calendar")}</h2>
            <p className="flex items-center text-zinc-500 text-xs whitespace-nowrap">
              <em>All times are {timeZoneName}</em>
              <Tooltip>
                <TooltipTrigger>
                  <button
                    className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                    type="button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-full">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                    </svg>
                  </button>
                </TooltipTrigger>
                <TooltipContent>
                  You can change the time zone<br />from <a className="text-brand-500 hover:underline" href="/profile/edit">your profile page</a>
                </TooltipContent>
              </Tooltip>
            </p>
          </div>
          <div className="space-y-4">
            {tutorAvailabilityCycles.length > 1 ? (
              <div className="flex items-center">
                <DynamicDropdown
                  onChange={setSelectedCycleId}
                  options={tutorAvailabilityCycles.map(cycle => ({
                    label: cycle.name,
                    value: cycle.id,
                  }))}
                  value={selectedCycleId}
                  data-testid="tutor-availability-cycles-dropdown"
                />
                <p className="text-zinc-700 text-sm">
                  {format(parse(selectedCycle?.start_date, "yyyy-MM-dd", new Date()), MONTH_DAY_YEAR_DATE_FORMAT)}
                  {" "}-{" "}
                  {format(parse(selectedCycle?.end_date, "yyyy-MM-dd", new Date()), MONTH_DAY_YEAR_DATE_FORMAT)}
                </p>
              </div>
            ) : null}
            <p className="text-sm text-slate-600">
              {safeGet(selectedCycle, "description",  "Set your weekly available hours to work with students")}
            </p>
          </div>
        </div>
        <div className="flex pb-4 lg:pb-12 border-b">
          <Calendar
            availabilities={sortedAvailabilities}
            highDemandIntervals={highDemandIntervals}
            setAvailabilities={setAvailabilities}
            timeZoneName={timeZoneName}
            timeZoneOffset={timeZoneOffset}
          />
          <Form
            availabilities={sortedAvailabilities}
            highDemandIntervals={highDemandIntervals}
            setAvailabilities={setAvailabilities}
            timeZoneOffset={timeZoneOffset}
          />
        </div>
        <MaxDailyHoursControls maxHoursPerDay={maxHoursPerDay} onChange={onChangeMaxHoursPerDay} />
        <div className="border-t border-gray-200 flex justify-end pt-6">
          <button className="button-primary" disabled={isSaving} type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
