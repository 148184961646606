import { Controller } from "@hotwired/stimulus"

import { getCsrfToken } from "../utils";

export default class extends Controller {
  static targets = [ "button" ]

  createShellSession(event) {
    this.buttonTarget.disabled = true
    this.buttonTarget.innerHTML = "Creating..."

    let programEnrollmentId = event.params.id
    let studentId = event.params.studentId

    fetch(`/admin/students/${studentId}/program_enrollments/${programEnrollmentId}/create_shell_session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({ program_enrollment_id: programEnrollmentId })
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error: ' + response.status)
      }
    })
    .then(data => {
      if (data.gsb_session_id) {
        document.querySelector('#program_enrollment_gsb_session_id').value = data.gsb_session_id
        this.buttonTarget.innerHTML = "Done"
      }
    })
    .catch(error => {
      console.error('Error:', error);
      this.buttonTarget.innerHTML = "Create Shell Session"
    });
  }
}
