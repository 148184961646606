import React from "react";

import { PagyProvider } from "../common/Pagy";
import Navigation from "../Student/Navigation";
import Notes from "./Notes";
import PlacedOutsideBanner from "../common/PlacedOutsideBanner";

export default ({ allowDeletion }) => [
  <Navigation key="navigation" />,
  <PlacedOutsideBanner key="place-out" />,
  <PagyProvider key="content">
    <Notes allowDeletion={allowDeletion} />
  </PagyProvider>,
];
