import React from "react";
import ReactRouterTabs from "../common/ReactRouterTabs";

const NAV_LINKS = [
  { to: "/dashboard", label: "Today" },
  { to: "/dashboard/all", label: "All" }
];
const Navigation = () => (
  <ReactRouterTabs navLinks={NAV_LINKS}/>
);

export default Navigation;
