import { format } from "date-fns";
import React from "react";

import { MERIDIEM_TIME_FORMAT_ZERO_PADDED, MONTH_DAY_YEAR_DATE_FORMAT } from "../../constants";
import SubSessionRequestClaim from "./SubSessionRequestClaim";

const SubSessionRequestsList = ({ sessions }) => (
  <div className="pt-0.5 w-full md:w-[320px] max-h-[calc(100vh-40px)] overflow-y-auto">
    <ul className="divide-y divide-zinc-200">
      {sessions.map((session) => (
        <li className="pt-3.5 pb-5 px-5" key={session.id}>
          <div className="flex items-center mb-2">
            <h4
              className="text-sm font-medium leading-none text-zinc-700 truncate mt-0.5"
              data-heap-redact-text
            >
              {session.displayName}
            </h4>
          </div>
          <p className="flex items-center text-xs text-zinc-500 mb-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-3.5 h-3.5 mr-1"
            >
              <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z" />
              <path
                fillRule="evenodd"
                d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                clipRule="evenodd"
              />
            </svg>
            <span>Date: {format(session.startTime, MONTH_DAY_YEAR_DATE_FORMAT)}</span>
          </p>
          <p className="flex items-center text-xs text-zinc-500 mb-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3.5 h-3.5 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>
              {format(session.startTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)} -{" "}
              {format(session.endTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}
            </span>
          </p>
          <div className="flex items-center mt-3.5 space-x-2">
            <SubSessionRequestClaim
              programEnrollmentId={session.programEnrollmentId}
              startTime={session.startTime}
            />
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default SubSessionRequestsList;
