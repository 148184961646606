import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useSparkCookie } from "../common/SparkCookie";
import { VISIBILITY_BANNER_COOKIE_KEY } from "./";
import { useStudentAssessmentContext } from "../StudentAssessment";

const VisibilityInfoBanner = () => {
  const { targetGoal } = useStudentAssessmentContext();
  const [isDismissed, setIsDismissed] = useSparkCookie(VISIBILITY_BANNER_COOKIE_KEY, "false");
  const onDismiss = () => {
    setIsDismissed("true");
  };

  return (
    <AnimatePresence>
      {isDismissed === "false" ? (
        <motion.div
          className="bg-blue-50 border-b border-blue-100 rounded-tr-lg space-y-2 sm:space-y-3 overflow-hidden"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
        >
          <div className="flex items-center justify-between pt-3.5 px-4 sm:px-5">
            <h5 className="font-semibold text-gray-700 sm:pt-1.5 sm:pl-5 text-xs sm:text-sm">Why am I seeing this?</h5>
            <button
              className="flex items-center space-x-1.5 text-xs text-gray-500 hover:text-gray-700 hover:underline"
              onClick={onDismiss}
              type="button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                <path d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
              </svg>
              Close
            </button>
          </div>
          <div className="pb-3.5 sm:pb-5 px-4 sm:px-10 text-gray-600 text-xs sm:text-sm">
            <p>This drill provides additional at-bats for letter name practice. It disappears once the student reaches the goal of <strong>{targetGoal}</strong> Letters Correct Per Minute (LCPM).</p>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default VisibilityInfoBanner;
