import React from "react";

import {
  StudentAssessment,
  StudentAssessmentContentCollapsed,
  StudentAssessmentContentExpanded,
  StudentAssessmentHeader,
  StudentAssessmentReadingStats,
  StudentAssessmentTimedAssessmentContent,
} from "../StudentAssessment";
import CompleteAssessmentForm from "./CompleteAssessmentForm";
import Results from "./Results";
import WordList from "./WordList";
import BookOpenSolid from "assets/icons/book-open-solid.svg";

const typeToTitleMap = {
  StudentOralReadingFluency: "Oral Reading Fluency",
  StudentDearAssessment: "DEAR",
};
const typeToTooltipContentMap = {
  StudentOralReadingFluency: [
    <h5 className="font-medium mb-1.5" key="title-1">
      Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      The Oral Reading Fluency (ORF) assessment measures a student&apos;s ability to read a passage
      aloud with accuracy and fluency (rate). This assessment evaluates the student&apos;s accuracy
      and fluency (rate) by counting the number of words read correctly within a specified time
      frame. This assessment does not factor other components of fluency, like expression.
    </p>,
    <p className="mb-1.5" key="paragraph-2">
      Student success is measured by counting the number of words read correctly within 1 minute
    </p>,
  ],
  StudentDearAssessment: [
    <h5 className="font-medium mb-1.5" key="title-1">
      DEAR Overview
    </h5>,
    <p className="mb-6" key="paragraph-1">
      DEAR means Drop Everything And Read. At Ignite, DEAR Day is an opportunity for us to take 3-5
      minutes with students to assess their reading fluency.
    </p>,
    <p className="mb-1.5" key="paragraph-2">
      Fluency is a student’s ability to read accurately, with expression at a rate appropriate for
      the text.
    </p>,
  ],
};

const StudentDearAssessment = (props) => (
  <StudentAssessment
    {...props}
    data-testid="dear-assessment-lesson-card"
    queryKey={["student-dear-assessment", props.assessmentId]}
    resetModalContent={`All the ${typeToTitleMap[props.type]} assessment data you marked will be erased and reset for this lesson. Are you sure you want to reset?`}
    resetModalTitle={`Reset ${typeToTitleMap[props.type]} Assessment`}
  >
    <StudentAssessmentHeader
      assessmentName={typeToTitleMap[props.type]}
      icon={<BookOpenSolid />}
      tooltipContent={typeToTooltipContentMap[props.type]}
    />
    <StudentAssessmentContentExpanded>
      <StudentAssessmentTimedAssessmentContent
        components={{
          CompleteAssessmentForm,
          Results,
          UtteranceList: WordList,
        }}
        hideReadingStats={props.hideReadingStats}
        instructions={[
          "Start the timer",
          "Click on the word if student reads it incorrectly",
          "Once time’s up, select stop on the last word attempted",
        ]}
        submitResultsButtonLabel={props.submitResultsButtonLabel}
      />
    </StudentAssessmentContentExpanded>
    <StudentAssessmentContentCollapsed>
      <StudentAssessmentReadingStats />
    </StudentAssessmentContentCollapsed>
  </StudentAssessment>
);

export default StudentDearAssessment;
