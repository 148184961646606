import get from "lodash/get";
import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import React, { useEffect, useMemo, useState } from "react";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import TaskList from "./TaskList";
import {
  StudentAssessmentContinueLessonButton,
  StudentAssessmentSetNewGoalForm,
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";
import QuestionList from "./QuestionList";
import { readingAssessmentTaskType } from "../../constants";
import { useStudentAssessmentWordCountQuery } from "./queries";

export default ({ hideReadingStats, submitButtonLabel }) => {
  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const studentAssessmentWordCountQuery = useStudentAssessmentWordCountQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { onCompleteAssessment, setOpen } = useStudentAssessmentContext();
  const { correct } = studentAssessmentWordCountQuery.data || {};
  const [nextGoalValue, setNextGoalValue] = useState(correct ?? "");
  const { readingAssessmentTasks, studentReadingAssessmentTasksIds } =
    studentAssessmentQuery.data || {
      readingAssessmentTasks: [],
      studentReadingAssessmentTasksIds: [],
    };
  const onChangeNextGoalValue = (event) => {
    setNextGoalValue(event.target.value);
  };
  const onSubmit = (event) => {
    if (isFunction(onCompleteAssessment)) {
      onCompleteAssessment(event);
    } else {
      completeAssessmentMutation.mutate(nextGoalValue, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    }
    event.preventDefault();
  };
  const onShowModal = () => {
    setIsModalOpen(true);
  };
  const task = useMemo(
    () =>
      readingAssessmentTasks.find(({ taskType }) => {
        return taskType === readingAssessmentTaskType.QUESTIONS;
      }),
    [readingAssessmentTasks]
  );
  const questions = get(task, "questions", []);
  const shouldDisabledButton = useMemo(() => {
    const readingAssessmentTaskIds = readingAssessmentTasks.map((task) => task.id);

    return !isEqual(readingAssessmentTaskIds.sort(), studentReadingAssessmentTasksIds.sort());
  }, [readingAssessmentTasks, studentReadingAssessmentTasksIds]);

  useEffect(() => {
    if (correct !== undefined) {
      setNextGoalValue(correct);
    }
  }, [correct]);

  return (
    <form onSubmit={onSubmit}>
      {!hideReadingStats ? (
        <StudentAssessmentSetNewGoalForm onChange={onChangeNextGoalValue} value={nextGoalValue} />
      ) : null}
      {questions.length > 0 ? (
        <div
          className="bg-white border border-gray-200 rounded-lg p-4 pb-6 mb-8"
          key="comprehension"
        >
          <h5 className="flex items-center text-lg font-medium text-zinc-700 space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
              />
            </svg>
            <label htmlFor="nextGoal">Check Comprehension</label>
          </h5>
          <div className="pl-9">
            <QuestionList questions={questions} taskId={task.id} />
          </div>
        </div>
      ) : null}
      <TaskList key="tasks" />
      {shouldDisabledButton ? (
        <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
          <ModalTrigger>
            <StudentAssessmentContinueLessonButton
              className="opacity-50 enabled:cursor-default"
              onClick={onShowModal}
              type="button"
            />
          </ModalTrigger>
          <ModalContent>
            <div className="sm:flex sm:flex-col sm:items-start">
              <div className="flex items-center flex-col sm:flex-row space-y-2 sm:space-y-0 space-x-4">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 text-yellow-500 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                    />
                  </svg>
                </div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  You still have unfinished tasks
                </h3>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-14 sm:text-left space-y-2 whitespace-normal">
                <p className="text-sm text-gray-500 font-normal">
                  Please complete and check off all the tasks before you continue with your lesson.
                </p>
              </div>
            </div>
            <div className="mt-5 flex flex-col md:flex-row-reverse items-center">
              <ModalClose className="button-secondary bg-yellow-400 ring-yellow-400 enabled:hover:bg-yellow-500 enabled:hover:ring-yellow-500">
                Back to Fluency
              </ModalClose>
            </div>
          </ModalContent>
        </Modal>
      ) : (
        <StudentAssessmentContinueLessonButton
          disabled={completeAssessmentMutation.isLoading}
          submitButtonLabel={submitButtonLabel}
        />
      )}
    </form>
  );
};
