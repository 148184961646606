import filter from "lodash/filter";
import head from "lodash/head";
import shuffle from "lodash/shuffle";
import size from "lodash/size";
import times from "lodash/times";
import React, { useCallback, useEffect, useRef } from "react";

import { hexToRgb } from "../../../utils";

const DECAY = 0.9;
const TOTAL_TICKS = 200;

const ConfettiCannon = ({ angle, className, colors, gravity, height, origin, particleCount, spread, startVelocity, width }) => {
  const canvasRef = useRef();
  const rafRef = useRef();
  const fireCannon = useCallback(() => {
    const context = canvasRef.current.getContext("2d");
    const confettis = times(particleCount, () => {
      const radAngle = angle * (Math.PI / 180);
      const radSpread = spread * (Math.PI / 180);

      return {
        angle2D: -radAngle + (0.5 * radSpread - Math.random() * radSpread),
        color: hexToRgb(head(shuffle(colors))),
        opacity: 1,
        random: Math.random() + 5,
        tick: 0,
        tiltAngle: Math.random() * Math.PI,
        velocity: startVelocity * 0.5 + Math.random() * startVelocity,
        wobble: Math.random() * 10,
        x: canvasRef.current.width * origin.x,
        y: canvasRef.current.height * origin.y,
      };
    });
    const animationCallback = () => {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      const animatedConfettis = filter(confettis, confetti => {
        const tiltAngle = confetti.tiltAngle + 0.1;
        const wobble = confetti.wobble + 0.1;

        const tiltSin = Math.sin(tiltAngle);
        const tiltCos = Math.cos(tiltAngle);
        const x = confetti.x + Math.cos(confetti.angle2D) * confetti.velocity;
        const y = confetti.y + Math.sin(confetti.angle2D) * confetti.velocity + gravity;
        const wobbleX = x + 10 * Math.cos(wobble);
        const wobbleY = y + 10 * Math.sin(wobble);
        const x1 = x + confetti.random * tiltCos;
        const y1 = y + confetti.random * tiltSin;
        const x2 = wobbleX + confetti.random * tiltCos;
        const y2 = wobbleY + confetti.random * tiltSin;
        const nextTick = confetti.tick + 1;
        const opacity = 1 - nextTick / TOTAL_TICKS;

        confetti.tick = nextTick;
        confetti.velocity *= DECAY;
        confetti.wobble = wobble;
        confetti.x = x;
        confetti.y = y;
        context.fillStyle = `rgba(${confetti.color.r}, ${confetti.color.g}, ${confetti.color.b}, ${opacity})`;
        context.beginPath();
        context.moveTo(Math.floor(x), Math.floor(y));
        context.lineTo(Math.floor(wobbleX), Math.floor(y1));
        context.lineTo(Math.floor(x2), Math.floor(y2));
        context.lineTo(Math.floor(x1), Math.floor(wobbleY));
        context.closePath();
        context.fill();

        return confetti.tick < TOTAL_TICKS;
      });

      if (size(animatedConfettis) > 0) {
        rafRef.current = window.requestAnimationFrame(animationCallback);
      }
    };

    rafRef.current = window.requestAnimationFrame(animationCallback);
  }, [angle, colors, gravity, origin, particleCount, spread, startVelocity]);

  useEffect(() => {
    fireCannon();

    return () => {
      window.cancelAnimationFrame(rafRef.current);
    };
  });

  return (
    <canvas className={className} height={height} ref={canvasRef} width={width} />
  );
};

ConfettiCannon.defaultProps = {
  angle: 90,
  className: "",
  colors: ["#9A98CB", "#3AC2D6", "#ED8DBB", "#FFC953"],
  delay: 0,
  gravity: 1.7,
  height: 800,
  origin: {
    x: 0.5,
    y: 0.5,
  },
  particleCount: 100,
  spread: 90,
  startVelocity: 20,
  width: 600,
};

export default ConfettiCannon;
