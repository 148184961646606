import React, { useEffect, useState } from "react";

import { Modal, ModalClose, ModalContent } from "../common/Modal";
import { useDrawerContext } from "../common/Drawer";
import ConfettiCannon from "../common/ConfettiCannon";

const StudentAssessmentCompletionModal = ({ studentAssessmentIdParamKey, id }) => {
  const { setOpen: setDrawerOpen } = useDrawerContext();
  const [modalOpen, setModalOpen] = useState(false);

  const onClick = () => {
    setModalOpen(false);
    setDrawerOpen(true);
  };

  useEffect(() => {
    const url = new URL(window.location);
    const params = new URLSearchParams(window.location.search);
    const assessmentId = parseInt(params.get(studentAssessmentIdParamKey), 10);

    if (assessmentId === id) {
      setModalOpen(true);

      url.searchParams.delete(studentAssessmentIdParamKey);
      window.history.pushState({}, "", url);
    }
  }, [id, studentAssessmentIdParamKey]);

  return (
    <Modal open={modalOpen} onOpenChange={setModalOpen}>
      <ModalContent data-testid="student-assessment-completion-modal">
        <div className="px-32 py-6 text-center">
          <ModalClose
            className="ring-0 p-1 enabled:shadow-none absolute top-2 right-2"
            data-testid="student-assessment-completion-modal-close-button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </ModalClose>
          <div className="flex justify-center mb-6 relative">
            <ConfettiCannon
              className="absolute -top-28"
              height={340}
              particleCount={60}
              width={400}
            />
            <svg className="relative" width="117" height="138" viewBox="0 0 117 138" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M94.97 23.1502C94.305 23.1502 93.7992 22.8217 93.5905 22.2496C92.7769 19.9999 91.9669 17.7503 91.1746 15.4935C90.9093 14.7413 91.2701 14.0137 92.0235 13.7312C92.9927 13.3674 93.9689 13.0213 94.9487 12.6858C95.7022 12.4316 96.4485 12.7812 96.7173 13.5299C97.5273 15.7655 98.3338 18.0045 99.1226 20.2471C99.3844 20.9923 99.059 21.7269 98.3374 22.0059C97.3328 22.3909 96.3141 22.7405 95.2989 23.0972C95.1751 23.1396 95.0336 23.1431 94.97 23.1537V23.1502Z" fill="#9A98CB"/>
              <path d="M18.595 77.7996C17.93 77.7996 17.4242 77.4711 17.2155 76.899C16.4019 74.6493 15.5919 72.3997 14.7996 70.1429C14.5343 69.3907 14.8951 68.6632 15.6485 68.3806C16.6177 68.0169 17.5939 67.6708 18.5737 67.3353C19.3272 67.081 20.0735 67.4306 20.3423 68.1793C21.1524 70.4149 21.9588 72.6539 22.7476 74.8966C23.0094 75.6417 22.684 76.3763 21.9624 76.6553C20.9578 77.0403 19.9391 77.3899 18.9239 77.7466C18.8001 77.789 18.6586 77.7925 18.595 77.8031V77.7996Z" fill="#F9D078"/>
              <path d="M107.977 51.7959C107.839 51.9442 107.588 52.2303 107.319 52.5022C105.936 53.8866 104.56 55.2781 103.163 56.6484C102.179 57.6125 100.75 57.616 99.8343 56.6943C98.9111 55.7655 98.9217 54.3528 99.8874 53.371C101.387 51.8488 102.894 50.3337 104.411 48.8293C105.331 47.9181 106.615 47.8651 107.595 48.6562C108.479 49.3696 108.681 50.6445 107.977 51.7994V51.7959Z" fill="#F1CADF"/>
              <path d="M75.0808 76.1465H40.1652C25.9044 76.1465 14.3438 87.6891 14.3438 101.928V112.215C14.3438 126.454 25.9044 137.997 40.1652 137.997H75.0808C89.3416 137.997 100.902 126.454 100.902 112.215V101.928C100.902 87.6891 89.3416 76.1465 75.0808 76.1465Z" fill="white"/>
              <path d="M107.385 114.034C107.361 114.217 107.336 114.599 107.251 114.966C106.395 118.643 105.528 122.316 104.662 125.989C104.273 127.645 103.158 128.341 101.51 127.959C99.975 127.602 98.4434 127.246 96.9118 126.878C95.4085 126.518 94.6904 125.395 95.0371 123.901C95.9214 120.094 96.8198 116.291 97.7253 112.487C98.0543 111.106 99.1933 110.389 100.58 110.696C102.249 111.064 103.912 111.459 105.571 111.865C106.579 112.113 107.23 112.858 107.382 114.037L107.385 114.034Z" fill="#C7E8EE"/>
              <path d="M62.3792 2.93119L72.5911 23.5915C73.3587 25.1419 74.8408 26.219 76.5563 26.4662L99.3924 29.7789C103.711 30.4041 105.437 35.7051 102.311 38.7459L85.7884 54.8291C84.5468 56.0369 83.9809 57.778 84.2744 59.4838L88.176 82.1925C88.9152 86.487 84.3982 89.7644 80.5356 87.7337L60.1119 77.0115C58.5768 76.2063 56.7445 76.2063 55.2094 77.0115L34.7856 87.7337C30.923 89.7608 26.406 86.487 27.1453 82.1925L31.0468 59.4838C31.3404 57.778 30.7745 56.0369 29.5329 54.8291L13.0107 38.7459C9.88384 35.7051 11.61 30.4041 15.9289 29.7789L38.765 26.4662C40.4805 26.219 41.9626 25.1419 42.7302 23.5915L52.942 2.93119C54.8733 -0.978366 60.455 -0.978366 62.3863 2.93119H62.3792Z" fill="#FFC953"/>
              <path d="M43.3033 44.0324C45.7296 44.0324 47.6965 42.0686 47.6965 39.6461C47.6965 37.2236 45.7296 35.2598 43.3033 35.2598C40.8771 35.2598 38.9102 37.2236 38.9102 39.6461C38.9102 42.0686 40.8771 44.0324 43.3033 44.0324Z" fill="#27004B"/>
              <path d="M72.0143 44.0324C74.4406 44.0324 76.4075 42.0686 76.4075 39.6461C76.4075 37.2236 74.4406 35.2598 72.0143 35.2598C69.588 35.2598 67.6211 37.2236 67.6211 39.6461C67.6211 42.0686 69.588 44.0324 72.0143 44.0324Z" fill="#27004B"/>
              <path d="M38.98 57.4922C42.6214 57.4922 45.5734 54.5449 45.5734 50.9092C45.5734 47.2735 42.6214 44.3262 38.98 44.3262C35.3386 44.3262 32.3867 47.2735 32.3867 50.9092C32.3867 54.5449 35.3386 57.4922 38.98 57.4922Z" fill="#ED017F"/>
              <path d="M76.482 57.4922C80.1234 57.4922 83.0753 54.5449 83.0753 50.9092C83.0753 47.2735 80.1234 44.3262 76.482 44.3262C72.8406 44.3262 69.8887 47.2735 69.8887 50.9092C69.8887 54.5449 72.8406 57.4922 76.482 57.4922Z" fill="#ED017F"/>
              <path d="M65.2011 45.2051C65.2011 49.4042 61.7912 52.8123 57.582 52.8123C53.3763 52.8123 49.9629 49.4078 49.9629 45.2051" fill="#27004B"/>
              <path d="M11.361 54.1621C13.1932 54.1621 14.6682 55.6207 14.6753 57.4395C14.6788 59.2724 13.1826 60.7946 11.3751 60.7981C9.53577 60.7981 8.02539 59.3078 8.02539 57.496C8.02539 55.6101 9.46856 54.1692 11.361 54.1656V54.1621Z" fill="#C8E9EF"/>
              <path d="M112.96 38.0645C114.803 38.0645 116.296 39.5478 116.292 41.3772C116.292 43.2101 114.789 44.7217 112.974 44.7181C111.128 44.7146 109.635 43.2066 109.639 41.356C109.646 39.5513 111.142 38.0645 112.957 38.068L112.96 38.0645Z" fill="#FFC853"/>
              <path d="M0.594597 103.792C-0.0597824 102.867 -0.183584 102.019 0.279788 101.175C2.2217 97.6502 4.17777 94.1362 6.15859 90.6363C6.81651 89.4744 8.12881 89.1424 9.3173 89.7852C10.7994 90.5869 12.2744 91.4062 13.7423 92.2432C15.0157 92.9708 15.3907 94.2563 14.6903 95.5277C12.7944 98.9499 10.8878 102.361 8.97066 105.77C8.22785 107.09 6.95093 107.447 5.63155 106.723C4.19192 105.936 2.75936 105.134 1.33387 104.322C1.02613 104.145 0.757308 103.908 0.594597 103.792Z" fill="#F1CADF"/>
              <path d="M35.0249 17.9159C34.0345 18.2373 32.938 17.9301 32.418 17.026C31.2578 14.9988 30.1224 12.9504 29.0683 10.8667C28.4953 9.7366 29.04 8.4652 30.1613 7.90366C31.2366 7.36332 32.5524 7.70236 33.1608 8.77952C34.2821 10.7537 35.368 12.7491 36.415 14.7622C37.0517 15.9912 36.3549 17.4816 35.0214 17.9195L35.0249 17.9159Z" fill="#ED8DBB"/>
              <path d="M64.041 16.2247C63.093 16.5744 61.9399 16.1258 61.2926 14.9639C59.8352 12.3611 58.4027 9.73353 57.0444 7.06711C56.3087 5.62266 56.673 4.06166 57.7235 3.4189C58.7281 2.80439 60.1005 3.30235 60.8681 4.67971C62.2759 7.21191 63.6554 9.76884 64.996 12.3505C65.8131 13.9256 65.325 15.7515 64.0445 16.2212L64.041 16.2247Z" fill="#FBE6C8"/>
              <path d="M86.7392 31.442C87.047 30.4814 88.1081 29.8492 89.4239 30.047C92.3775 30.4955 95.331 30.9793 98.274 31.5479C99.8693 31.8552 100.877 33.0983 100.743 34.3238C100.612 35.4928 99.3776 36.2698 97.8142 36.0261C94.9455 35.5811 92.0804 35.0973 89.2152 34.5711C87.4679 34.2497 86.3254 32.7417 86.7392 31.4455V31.442Z" fill="#FBE6C8"/>
              <path d="M101.273 82.2881C103.105 82.2881 104.58 83.7467 104.587 85.5655C104.591 87.3984 103.095 88.9206 101.287 88.9241C99.4479 88.9241 97.9375 87.4337 97.9375 85.622C97.9375 83.7361 99.3807 82.2951 101.273 82.2916V82.2881Z" fill="#3AC2D6"/>
              <path d="M84.8195 124.53C86.6623 124.53 88.155 126.014 88.1515 127.843C88.1515 129.676 86.6482 131.187 84.8336 131.184C82.9872 131.18 81.4945 129.672 81.4981 127.822C81.5051 126.017 83.0014 124.53 84.8159 124.534L84.8195 124.53Z" fill="#FFC953"/>
              <path d="M39.5851 105.357C37.7741 105.357 36.3168 104.732 35.2167 103.482C34.1166 102.231 33.5684 100.589 33.5684 98.5549C33.5684 96.5207 34.1555 94.829 35.3263 93.6C36.4972 92.371 38.096 91.7529 40.1263 91.7529C41.7746 91.7529 43.1011 92.145 44.1021 92.929C45.1031 93.713 45.7257 94.8961 45.9697 96.4818L43.0268 96.9692C42.8712 96.0192 42.5422 95.3305 42.0435 94.8996C41.5447 94.4688 40.8727 94.2534 40.0308 94.2534C38.9449 94.2534 38.103 94.6207 37.5052 95.3552C36.9075 96.0898 36.6103 97.1387 36.6103 98.502C36.6103 99.8652 36.9145 100.925 37.5265 101.705C38.1384 102.486 39.0156 102.874 40.1652 102.874C40.9858 102.874 41.665 102.662 42.1956 102.235C42.7261 101.808 43.0622 101.161 43.2001 100.293H39.8893V98.117H46.0193V105.163H43.8404L43.7696 102.874C43.4583 103.686 42.9419 104.304 42.2274 104.725C41.5129 105.145 40.6321 105.357 39.5851 105.357Z" fill="#27004B"/>
              <path d="M52.895 105.357C51.2998 105.357 50.0441 104.877 49.1279 103.919C48.2118 102.962 47.752 101.705 47.752 100.151C47.752 98.5972 48.2118 97.3435 49.1279 96.3899C50.0441 95.4364 51.2998 94.9561 52.895 94.9561C54.4903 94.9561 55.7354 95.4328 56.6515 96.3899C57.5677 97.347 58.0275 98.6007 58.0275 100.151C58.0275 101.702 57.5712 102.959 56.6515 103.919C55.7354 104.88 54.4832 105.357 52.895 105.357ZM51.1123 98.1275C50.6701 98.6219 50.4508 99.2965 50.4508 100.151C50.4508 101.006 50.6701 101.684 51.1123 102.185C51.5544 102.687 52.1487 102.938 52.895 102.938C53.6414 102.938 54.225 102.687 54.6636 102.185C55.1022 101.684 55.3215 101.006 55.3215 100.151C55.3215 99.2965 55.1022 98.6219 54.6636 98.1275C54.225 97.6331 53.6378 97.3858 52.895 97.3858C52.1522 97.3858 51.5544 97.6331 51.1123 98.1275Z" fill="#27004B"/>
              <path d="M64.5005 105.357C62.9052 105.357 61.6495 104.877 60.7334 103.919C59.8173 102.962 59.3574 101.705 59.3574 100.151C59.3574 98.5972 59.8173 97.3435 60.7334 96.3899C61.6495 95.4364 62.9052 94.9561 64.5005 94.9561C66.0958 94.9561 67.3409 95.4328 68.257 96.3899C69.1731 97.347 69.633 98.6007 69.633 100.151C69.633 101.702 69.1767 102.959 68.257 103.919C67.3409 104.88 66.0887 105.357 64.5005 105.357ZM62.7178 98.1275C62.2756 98.6219 62.0563 99.2965 62.0563 100.151C62.0563 101.006 62.2756 101.684 62.7178 102.185C63.1599 102.687 63.7541 102.938 64.5005 102.938C65.2468 102.938 65.8305 102.687 66.2691 102.185C66.7077 101.684 66.927 101.006 66.927 100.151C66.927 99.2965 66.7077 98.6219 66.2691 98.1275C65.8305 97.6331 65.2433 97.3858 64.5005 97.3858C63.7577 97.3858 63.1599 97.6331 62.7178 98.1275Z" fill="#27004B"/>
              <path d="M75.4272 105.357C74.0831 105.357 73.0042 104.88 72.1907 103.93C71.3771 102.977 70.9668 101.716 70.9668 100.144C70.9668 98.5728 71.3736 97.3226 72.1907 96.3761C73.0042 95.4296 74.0831 94.9564 75.4272 94.9564C76.7713 94.9564 77.8183 95.4649 78.4197 96.4821V90.9727H81.1504V105.166H78.8087L78.6885 103.256C78.1119 104.658 77.026 105.361 75.4307 105.361L75.4272 105.357ZM73.7046 100.141C73.7046 101.003 73.9097 101.684 74.3165 102.189C74.7233 102.694 75.2928 102.945 76.0285 102.945C76.7642 102.945 77.3656 102.687 77.8219 102.172C78.2782 101.656 78.5045 100.978 78.5045 100.137C78.5045 99.2968 78.2782 98.647 77.8219 98.1455C77.3656 97.644 76.7678 97.3932 76.0285 97.3932C75.2892 97.3932 74.7268 97.6369 74.3165 98.1243C73.9062 98.6117 73.7046 99.2827 73.7046 100.137V100.141Z" fill="#27004B"/>
              <path d="M40.1871 126.148C40.8521 126.184 41.3296 126.056 41.6162 125.77C41.9062 125.484 42.0477 125.043 42.0477 124.439V114.211H44.7749V124.439C44.7749 127.144 43.2433 128.461 40.1836 128.394V126.148H40.1871ZM42.2281 112.707C41.8991 112.389 41.7329 112.004 41.7329 111.559C41.7329 111.114 41.8991 110.729 42.2316 110.415C42.5641 110.1 42.9532 109.941 43.4024 109.941C43.8517 109.941 44.262 110.1 44.5945 110.415C44.927 110.729 45.0932 111.11 45.0932 111.559C45.0932 112.007 44.927 112.389 44.5945 112.707C44.262 113.025 43.8658 113.183 43.4024 113.183C42.9391 113.183 42.557 113.025 42.2246 112.707H42.2281Z" fill="#27004B"/>
              <path d="M51.6118 124.421C50.0166 124.421 48.7608 123.941 47.8447 122.984C46.9286 122.027 46.4688 120.77 46.4688 119.216C46.4688 117.662 46.9286 116.408 47.8447 115.454C48.7608 114.501 50.0166 114.021 51.6118 114.021C53.2071 114.021 54.4522 114.497 55.3683 115.454C56.2844 116.411 56.7443 117.665 56.7443 119.216C56.7443 120.766 56.288 122.023 55.3683 122.984C54.4522 123.945 53.2 124.421 51.6118 124.421ZM49.8291 117.192C49.3869 117.686 49.1676 118.361 49.1676 119.216C49.1676 120.07 49.3869 120.748 49.8291 121.25C50.2712 121.751 50.8655 122.002 51.6118 122.002C52.3582 122.002 52.9418 121.751 53.3804 121.25C53.819 120.748 54.0383 120.07 54.0383 119.216C54.0383 118.361 53.819 117.686 53.3804 117.192C52.9418 116.698 52.3546 116.45 51.6118 116.45C50.869 116.45 50.2712 116.698 49.8291 117.192Z" fill="#27004B"/>
              <path d="M64.1464 124.422C62.59 124.422 61.5147 123.761 60.9169 122.44L60.8745 124.231H58.3984V110.037H61.1291V115.539C61.7552 114.529 62.7598 114.024 64.1428 114.024C65.5259 114.024 66.5623 114.498 67.3652 115.444C68.1681 116.391 68.5714 117.644 68.5714 119.212C68.5714 120.78 68.1681 122.055 67.3652 123.005C66.5623 123.955 65.487 124.429 64.1428 124.429L64.1464 124.422ZM61.0407 119.205C61.0407 120.046 61.2671 120.724 61.7163 121.24C62.1691 121.755 62.7633 122.013 63.5026 122.013C64.2419 122.013 64.8043 121.762 65.2146 121.261C65.6214 120.759 65.8265 120.074 65.8265 119.205C65.8265 118.337 65.6214 117.68 65.2146 117.192C64.8078 116.705 64.2383 116.461 63.5026 116.461C62.7669 116.461 62.1655 116.715 61.7163 117.221C61.2671 117.726 61.0407 118.39 61.0407 119.209V119.205Z" fill="#27004B"/>
              <path d="M70.4962 123.902C70.146 123.556 69.9727 123.143 69.9727 122.662C69.9727 122.182 70.146 121.769 70.4962 121.426C70.8463 121.084 71.2567 120.914 71.7342 120.914C72.2117 120.914 72.622 121.084 72.9722 121.426C73.3224 121.769 73.4957 122.182 73.4957 122.662C73.4957 123.143 73.3224 123.556 72.9722 123.902C72.622 124.248 72.2082 124.421 71.7342 124.421C71.2602 124.421 70.8463 124.248 70.4962 123.902ZM70.5917 120.088L70.2768 111.008H73.188L72.8731 120.088H70.5917Z" fill="#27004B"/>
              <path d="M24.0751 19.4141L25.8224 23.5108C25.9887 23.8993 26.2611 24.2313 26.6112 24.4679L30.297 26.9754L26.1938 28.72C25.8048 28.886 25.4723 29.1579 25.2353 29.5076L22.7239 33.1876L20.9765 29.0908C20.8102 28.7024 20.5379 28.3704 20.1877 28.1338L16.502 25.6263L20.6051 23.8816C20.9942 23.7156 21.3267 23.4437 21.5637 23.0941L24.0751 19.4141Z" fill="#3AC2D6"/>
              <path d="M107.945 93.8857L108.709 98.2756C108.78 98.6923 108.971 99.0773 109.257 99.3881L112.271 102.673L107.874 103.435C107.457 103.506 107.071 103.697 106.76 103.983L103.471 106.992L102.706 102.602C102.636 102.185 102.445 101.8 102.158 101.489L99.1445 98.205L103.541 97.4421C103.959 97.3715 104.344 97.1808 104.655 96.8947L107.945 93.8857Z" fill="#ED8DBB"/>
              <path d="M11.8115 111.194L15.476 113.734C15.8226 113.974 16.233 114.108 16.6539 114.126L21.1107 114.288L18.5675 117.947C18.327 118.293 18.1926 118.703 18.1749 119.123L18.0122 123.573L14.3476 121.034C14.001 120.793 13.5907 120.659 13.1697 120.642L8.71289 120.479L11.2561 116.82C11.4967 116.474 11.6311 116.065 11.6488 115.644L11.8115 111.194Z" fill="#9A98CB"/>
            </svg>
          </div>
          <h2 className="text-gray-600 font-semibold mb-10">You submitted the assessment!</h2>
          <button
            className="button-primary px-20 text-sm"
            data-testid="student-assessment-completion-modal-submit-button"
            onClick={onClick}
            type="button"
          >
            See Results
          </button>
        </div>
      </ModalContent>
    </Modal>
  );
}

export default StudentAssessmentCompletionModal;
