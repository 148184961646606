import React from "react";

import { useStartAssessmentMutation } from "./queries";

const AssessmentButton = ({ content, assessmentable }) => {
  const startAssessmentMutation = useStartAssessmentMutation({
    assessmentId: content.id,
    studentAssessmentableId: assessmentable.id,
    studentAssessmentableType: assessmentable.isBaseline ? "StudentBaseline" : "StudentProtocol",
    studentInstructionalPlanId: assessmentable.studentInstructionalPlanId,
  });
  const onStartAssessment = (event) => {
    startAssessmentMutation.mutate();
    event.preventDefault();
  };

  if (content.status === "in_progress") {
    return (
      <a
        className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
        data-testid="student-continue-assessment"
        href={content.assessmentPath}
      >
        Continue Assessment
      </a>
    );
  } else {
    let buttonText = "Start Assessment";
    let disabled = false;
    if (!content.assessmentButtonEnabled) {
      buttonText = "Assessment Unavailable";
      disabled = true;
    }
    if (assessmentable.isBaseline && !content.isCurrentContent && content.status === null) {
      buttonText = "Assessment Unavailable";
      disabled = true;
    } else if (content.status === "completed" || content.status === "passed") {
      buttonText = "Assessment Completed";
      disabled = true;
    }
    return (
      <form onSubmit={onStartAssessment}>
        <button
          className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
          data-testid={"student-start-assessment-" + content.id}
          disabled={startAssessmentMutation.isLoading || disabled}
          type="submit"
        >
          {buttonText}
        </button>
      </form>
    );
  }
};

export default AssessmentButton;
