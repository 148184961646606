import cx from "classnames";
import { format } from "date-fns";
import React from "react";
import { twMerge } from "tailwind-merge";

import { DATE_WITH_TIME } from "../../constants/index";
import { DrawerClose } from "../common/Drawer";

const Header = ({
  attemptCount,
  completed,
  correctCount,
  failed,
  id,
  isScreener,
  passed,
  studentName,
  submittedAt,
  submittedBy,
  title,
  totalCount,
}) => (
  <div className="space-y-5" data-testid={`student-assessment-results-drawer-header-${id}`}>
    <div>
      <DrawerClose
        className="text-brand-500 hover:text-brand-800 hover:underline font-semibold flex items-center space-x-1"
        data-testid="results-drawer-back-arrow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
            clipRule="evenodd"
          />
        </svg>
        <span>Back</span>
      </DrawerClose>
    </div>
    <div className="space-y-2 sm:space-y-5">
      <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between space-y-2 sm:space-y-0">
        <h1 className="text-xl font-semibold text-gray-700">{title}</h1>
        <span
          className={twMerge(
            cx("text-xs rounded-full px-4 py-1 font-semibold uppercase", {
              "bg-red-100 text-red-800": failed,
              "bg-green-100 text-green-800": passed || completed,
            })
          )}
        >
          {isScreener ? "Completed" : passed ? "Passed" : completed ? "Completed" : "Failed"} -{" "}
          {correctCount}/{totalCount}
        </span>
      </div>
      <ul className="space-y-2 text-sm text-gray-500">
        <li>
          <strong>Attempt {attemptCount}:</strong> Completed on{" "}
          {format(new Date(submittedAt), DATE_WITH_TIME)}
        </li>
        <li>
          <strong>Student:</strong> {studentName}
        </li>
        <li>
          <strong>Submitted by:</strong> {submittedBy ?? "Unknown"}
        </li>
      </ul>
    </div>
  </div>
);

export default Header;
