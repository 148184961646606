import React from "react";

export default ({ children }) => (
  <div className="space-y-2 mb-8">
    <h5 className="uppercase font-medium text-zinc-500 text-sm">Challenge results</h5>
    <div className="space-y-12">
      <ul className="space-y-2.5">
        {children}
      </ul>
    </div>
  </div>
);
