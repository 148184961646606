import React from "react";
import { twMerge } from "tailwind-merge";

import FloatingContent from "../Floating/Content";
import { useFloatingContext } from "../Floating/context";

const ModalContent = ({ children, className, "data-testid": dataTestid }) => {
  const { context: floatingContext } = useFloatingContext();

  return floatingContext.open ? (
    <FloatingContent
      className={twMerge(
        "bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-xl w-full sm:p-6",
        className
      )}
      data-testid={dataTestid}
    >
      {children}
    </FloatingContent>
  ) : null;
};

export default ModalContent;
