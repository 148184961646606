import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-submit"
export default class extends Controller {
  static targets = ["form"]
  
  submit() {
    this.formTarget.submit();
  }
}
