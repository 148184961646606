import noop from "lodash/noop";
import { createContext } from "react";

const TableContext = createContext({
  setSortBy: noop,
  setSortDirection: noop,
  sortBy: null,
  sortDirection: "asc",
});

export default TableContext;
