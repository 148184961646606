import size from "lodash/size";
import React from "react";

import SessionSuggestionItem from "./SessionSuggestionItem";
import { useSuggestionsQuery } from "./queries";

export default () => {
  const suggestionsQuery = useSuggestionsQuery();
  const { data: suggestions } = suggestionsQuery || { data: [] };

  return (
    <div className="pt-0.5 w-full md:w-[320px] max-h-[calc(100vh-142px)] overflow-y-auto">
      {size(suggestions) > 0 ?
        <ul className="divide-y divide-zinc-200">
          {suggestions.map(suggestion => (
            <li key={suggestion.uuid}>
              <SessionSuggestionItem
                assignmentType={suggestion.assignmentType}
                endDate={suggestion.endDate}
                endTime={suggestion.endTime}
                schedule={suggestion.schedule}
                startDate={suggestion.startDate}
                startTime={suggestion.startTime}
                title={suggestion.title}
                uuid={suggestion.uuid}
              />
            </li>
          ))}
        </ul>
      :
        <div className="px-5 py-3 text-zinc-500 text-sm flex flex-col items-center justify-center min-h-[180px] text-center">
          <p className="font-semibold mb-2">You are all caught up!</p>
          <p>There are no suggested student<br />pairings to review.</p>
        </div>
      }
    </div>
  );
};
