import React, { useContext, useEffect } from "react";

import { Table } from "../common/Table";
import TimeOffItem from "./TimeOffItem";
import { useTutorTimeOffQuery } from "./queries";
import { FilterByDateContext } from "./";
import { PAGY_UPPER_PAGE_BOUND } from "../../constants";
import { PagyContext } from "../common/Pagy";

const TimeOffList = () => {
  const query = useTutorTimeOffQuery();
  const { filterEndDate, filterStartDate } = useContext(FilterByDateContext);
  const { setPagy, setUpperPageBound, setLowerPageBound } = useContext(PagyContext);
  useEffect(() => {
    setUpperPageBound(PAGY_UPPER_PAGE_BOUND);
    setLowerPageBound(0);
    setPagy((prevPagy) => ({ ...prevPagy, page: 1 }));
  }, [setUpperPageBound, setLowerPageBound, setPagy, filterStartDate, filterEndDate]);

  return (
    <Table
      columns={[
        {
          id: "start_date",
          label: "Time Off Requested",
          isSortable: true,
        },
        {
          id: "status",
          label: "Request Approval Status",
          className: "text-center",
        },
        {
          id: "filler",
          label: ""
        }
      ]}
      data-testid="tutor-time-off-request-list"
      defaultSortKey="start_date"
      query={query}
    >
      {(data) => data.tutorTimeOff.length > 0 ? data.tutorTimeOff.map(tutorTimeOff => (
        <TimeOffItem key={tutorTimeOff.uuid} {...tutorTimeOff} />
      )) : (
        <tr>
          <td
            className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg"
            colSpan={3}
          >
            <p className="flex items-center justify-center grow text-gray-500 min-h-[120px]">
              You don&lsquo;t have any requested time off{(filterEndDate || filterStartDate) ? " for the selected date range" : null}.
            </p>
          </td>
        </tr>
      )}
    </Table>
  );
};

export default TimeOffList;
