import cx from "classnames";
import { endOfDay, isWithinInterval, isValid, startOfDay } from "date-fns";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Button } from "@Ignite-Reading/ui-kit";

import ChevronRightOutline from "assets/icons/chevron-right-outline.svg";
import TutorStudentListItemLink from "./TutorStudentListItemLink";
import { isToday, parse } from "date-fns";
import { SHORT_MONTH_AND_DAY } from "../../constants";

const statusToLabelMap = {
  upcoming: "Upcoming",
  inactive: "Inactive",
  permanent: "Permanent",
  substitute: "Substitute",
};
const TutorStudentListItem = ({
  assignmentDuration,
  displayName,
  gradeLevel,
  schoolName,
  status,
  studentId,
  tutoringBlock,
  nsbEnabled,
  clientURL,
}) => {
  const [assignmentStart, assignmentEnd] = (assignmentDuration ?? "").split(" - ");
  const assignmentStartDate = parse(assignmentStart, SHORT_MONTH_AND_DAY, new Date());
  const assignmentEndDate = parse(assignmentEnd, SHORT_MONTH_AND_DAY, new Date());
  const isSubAvailableToday = isValid(assignmentEndDate)
    ? isWithinInterval(new Date(), {
        start: startOfDay(assignmentStartDate),
        end: endOfDay(assignmentEndDate),
      })
    : isToday(assignmentStartDate);
  const disabled =
    ["inactive", "upcoming"].includes(status) || (status === "substitute" && !isSubAvailableToday);
  const joinNSB = () => {
    window.open(clientURL, "_blank");
  };

  return (
    <tr
      className={twMerge(
        cx(
          "group grid grid-cols-1 sm:grid-cols-2 xl:table-row relative xl:static pr-[180px] md:pr-[160px] xl:pr-auto",
          {
            "bg-gray-100": disabled,
          }
        )
      )}
    >
      <td className="p-0 whitespace-nowrap block xl:table-cell order-2 xl:order-1 group-last:md:rounded-bl-lg">
        <TutorStudentListItemLink
          className="py-0.5 xl:py-4 pl-4 sm:pl-6"
          data-title="Tutoring Block:"
          disabled={disabled}
          studentId={studentId}
        >
          {tutoringBlock}
        </TutorStudentListItemLink>
      </td>
      <td
        className="p-0 font-medium block xl:table-cell sm:col-span-2 order-1 group-first:md:rounded-t-lg group-first:xl:rounded-none"
        data-heap-redact-text
      >
        <TutorStudentListItemLink
          className="pt-4 pb-1 xl:py-4 pl-4 sm:pl-6 text-base"
          disabled={disabled}
          studentId={studentId}
        >
          {displayName}
        </TutorStudentListItemLink>
      </td>
      <td className="p-0 truncate xl:max-w-xs block xl:table-cell order-3" data-heap-redact-text>
        <TutorStudentListItemLink
          className="py-0.5 xl:py-4 pl-4 sm:pl-6"
          data-title="School:"
          disabled={disabled}
          studentId={studentId}
        >
          {schoolName}
        </TutorStudentListItemLink>
      </td>
      <td
        className="p-0 truncate block xl:table-cell order-4 group-last:md:rounded-bl-lg"
        data-heap-redact-text
      >
        <TutorStudentListItemLink
          className="pt-0.5 xl:pb-4 xl:py-4 pl-4 sm:pl-6"
          data-title="Grade Level:"
          disabled={disabled}
          studentId={studentId}
        >
          {gradeLevel}
        </TutorStudentListItemLink>
      </td>
      <td className="p-0 whitespace-nowrap block xl:table-cell order-5 group-last:md:rounded-br-lg">
        <TutorStudentListItemLink
          className="pt-0.5 pb-4 xl:py-4 pl-4 sm:pl-6"
          data-title="Assignment:"
          disabled={disabled}
          studentId={studentId}
        >
          <span
            className={twMerge(
              cx(
                "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 self-start",
                {
                  "bg-blue-100 text-blue-800": status === "permanent",
                  "bg-gray-200 text-gray-800": status === "inactive",
                  "bg-yellow-100 text-yellow-800": status === "upcoming",
                  "bg-purple-100 text-purple-800": status === "substitute",
                }
              )
            )}
          >
            {statusToLabelMap[status]}
          </span>
          {assignmentDuration ? (
            <span className="text-xs text-zinc-500 ml-1">{assignmentDuration}</span>
          ) : null}
        </TutorStudentListItemLink>
      </td>
      <td className="p-0 block xl:table-cell absolute xl:static right-2 xl:right-0 top-1/2 -translate-y-1/2 xl:translate-y-0 group-last:md:rounded-br-lg">
        {!disabled ? (
          <div className="flex flex-row-reverse items-center">
            <TutorStudentListItemLink
              className="py-0.5 xl:py-4 pr-2 justify-center text-zinc-500 group-hover:text-zinc-600 flex items-center max-w-[70px] right-0"
              data-testid={`tutor-student-list-item-chevron-${studentId}`}
              disabled={disabled}
              studentId={studentId}
            >
              <ChevronRightOutline className="h-5 w-5" strokeWidth={3} />
            </TutorStudentListItemLink>
            {nsbEnabled ? (
              <Button
                onClick={joinNSB}
                className="button-primary whitespace-nowrap mr-6"
                disabled={!clientURL}
                data-testid="join-nsb-button"
              >
                Join Session
              </Button>
            ) : null}
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default TutorStudentListItem;
