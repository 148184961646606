import React from "react";
import StudentAssessmentResultsDrawer from "../StudentAssessmentResultsDrawer";
import StudentAssessmentCompletionModal
  from "./StudentAssessmentCompletionModal";

import { useStudentAssessmentResultQuery } from "./queries";
import { Drawer, DrawerContent } from "../common/Drawer";

const StudentAssessmentResultModalTrigger = ({ studentAssessmentId }) => {
  const { data } = useStudentAssessmentResultQuery(studentAssessmentId);

  return data ? (
    <Drawer>
      <DrawerContent data-testid={`student-assessment-results-drawer-${data.id}`}>
        <StudentAssessmentResultsDrawer {...data} />
      </DrawerContent>
      <StudentAssessmentCompletionModal
        studentAssessmentIdParamKey={data.studentAssessmentIdParamKey}
        id={data.id}
      />
    </Drawer>
  ) : null;
};

export default StudentAssessmentResultModalTrigger
