import cx from "classnames";
import { format } from "date-fns";
import React, { useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import { HOUR_MINUTE_TWELVE, tutorStudentStatus, tutorTimeOffStatus } from "../../constants";
import TimeBlockDetails from "./TimeBlockDetails";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useSubBoardSelector } from "../../hooks/useSubBoardSelector";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";

const { ACCEPTED, CANCELLED, INVITED, SUB_REQUESTED, CLAIMED } = tutorStudentStatus;
const { APPROVED, PENDING } = tutorTimeOffStatus;

export default ({
  assignmentType,
  cancellationReason,
  columnSelected,
  endTime,
  gradeLevel,
  hideTimestamp,
  id,
  school,
  startTime,
  status,
  title,
  tutorStudentUuid,
}) => {
  const { selectedTimeBlock, setSelectedTimeBlock } = useContext(SelectedTimeBlockContext);
  const { isSubMode, isCalendarMode } = useCalendarViewMode();
  const { timeBlockIsSelected, deselectDate, selectTimeBlock, deselectTimeBlock } = useSubBoardSelector();
  const { getTimeOffStatus } = useTutorTimeOffs();
  const onOpenChange = (open) => {
    if (!isSubMode) {
      setSelectedTimeBlock(open ? id : null);
    }
  }
  const selected = useMemo(() => {
    return columnSelected || timeBlockIsSelected(startTime);
  }, [columnSelected, timeBlockIsSelected, startTime]);
  const tutorTimeOffStatus = useMemo(() => {
    return getTimeOffStatus(new Date(startTime));
  }, [startTime, getTimeOffStatus]);
  const onClickTimeBlock = () => {
    if (isSubMode && status === ACCEPTED && tutorTimeOffStatus === null) {
      if (selected) {
        deselectTimeBlock(startTime);
        deselectDate(startTime);
      } else {
        selectTimeBlock(startTime);
      }
    }
  };
  const styles = useMemo(() => {
    if (status === SUB_REQUESTED) {
      return "bg-cyan-400 border-transparent hover:opacity-80";
    } else if (status === CLAIMED) {
      return "opacity-70 pattern-diagonal-lines pattern-cyan-400 pattern-bg-cyan-500 pattern-size-1 pattern-opacity-100 border-cyan-500 hover:opacity-80";
    } else if (isSubMode) {
      return cx({
        "bg-zinc-600 border-transparent hover:bg-zinc-600/70 hover:border-zinc-600/70": status === ACCEPTED && !selected,
        "bg-brand-700 border-transparent hover:bg-brand-700/70 hover:border-brand-900/70": status === ACCEPTED && selected,
        "bg-zinc-800 border-brand-500/70 hover:bg-zinc-600/30 hover:border-brand-500/100": status === INVITED && !selected,
        "bg-brand-700 border-brand-500 hover:bg-brand-700/70 hover:border-brand-500/70": status === INVITED && selected,
        "opacity-60 pattern-diagonal-lines pattern-zinc-600 pattern-bg-zinc-800 pattern-size-1 pattern-opacity-100 border-zinc-800 hover:opacity-80": status === CANCELLED,
      });
    } else if (tutorTimeOffStatus) {
      return cx({
        "bg-zinc-400 border-transparent hover:opacity-80": tutorTimeOffStatus === PENDING && status !== INVITED,
        "bg-zinc-400/60 border-brand-500/70 hover:opacity-80": tutorTimeOffStatus === PENDING && status === INVITED,
        "opacity-100 pattern-diagonal-lines pattern-zinc-300 pattern-bg-zinc-400 pattern-size-1 pattern-opacity-100 border-transparent hover:opacity-80": tutorTimeOffStatus === APPROVED,
      });
    } else if (isCalendarMode) {
      return cx({
        "bg-brand-600 border-transparent hover:bg-brand-700 hover:border-brand-700": ![INVITED, CANCELLED, SUB_REQUESTED, CLAIMED].includes(status),
        "bg-brand-100/30 border-brand-500/70 hover:bg-brand-100/60 hover:border-brand-500/100": status === INVITED,
        "opacity-70 pattern-diagonal-lines pattern-brand-400 pattern-bg-brand-500 pattern-size-1 pattern-opacity-100 hover:opacity-80": status === CANCELLED,
      });
    }
  }, [tutorTimeOffStatus, isSubMode, isCalendarMode, selected, status]);

  return (
    <Popover
      offset={{ alignmentAxis: -20 }}
      onOpenChange={onOpenChange}
      open={selectedTimeBlock === id && !isSubMode}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <div
          className={twMerge(cx("flex items-start justify-between rounded-[15px] h-full px-2.5 py-[4.5px] border cursor-pointer relative", styles))}
          onClick={onClickTimeBlock}
        >
          <div className="flex flex-nowrap items-center grow whitespace-nowrap w-full">
            <h3 className={cx("text-[12px] grow leading-tight truncate",
              status === INVITED ? isSubMode ? "text-zinc-500" : "text-brand-500" : "text-white",
              status === CLAIMED || status === SUB_REQUESTED ? "mr-5" : "mr-2.5"
            )}>
              {hideTimestamp ? null : (
                <span
                  className={cx(
                    "hidden md:inline-block w-[32px] text-right font-light mr-2.5",
                    isSubMode
                      ? "text-white/70"
                      : status === INVITED ? "text-gray-600" : "text-white/70",
                    { "line-through": status === CANCELLED || status === CLAIMED }
                  )}
                >
                  {format(startTime, HOUR_MINUTE_TWELVE)}
                </span>
              )}
              <span
                className={cx("text-[11px] md:text-[13px] font-medium", { "line-through": status === CANCELLED || status === CLAIMED })}
                data-heap-redact-text
              >
                {title}
              </span>
            </h3>
            {status === INVITED ?
              <div className="bg-sky-500 rounded-full w-[10px] h-[10px] mr-1 shrink-0 hidden md:block" />
            : null}
          </div>
          {status === CANCELLED ?
            <div className="absolute top-0 bottom-0 left-0 right-0 " />
          : null}
          {selectedTimeBlock === id ?
            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[15px] bg-sky-500/20 outline outline-sky-500 outline-offset-1 pointer-events-none" />
          : null}
          {status === CLAIMED || status === SUB_REQUESTED ?
            <Tooltip>
              <TooltipTrigger>
                <div className="absolute right-1.5 w-[20px] h-[20px] text-white">
                  {status === CLAIMED ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                Your sub session has been {status === CLAIMED ? "claimed" : "requested"}.
              </TooltipContent>
            </Tooltip>
          : null}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <TimeBlockDetails
          assignmentType={assignmentType}
          endTime={endTime}
          gradeLevel={gradeLevel}
          cancellationReason={cancellationReason}
          school={school}
          startTime={startTime}
          status={status}
          title={title}
          tutorStudentUuid={tutorStudentUuid}
        />
      </PopoverContent>
    </Popover>
  );
}
