import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { format } from "date-fns-tz"

export default class extends Controller {
  static targets = ["downloadButton", "buttonText", "spinner"]
  static values = {
    url: String,
    filenamePrefix: String,
  }
  static classes = ["download", "downloading"]

  initiate() {
    this.downloadButtonTarget.classList.remove(...this.downloadClasses)
    this.downloadButtonTarget.classList.add(...this.downloadingClasses)
    this.downloadButtonTarget.disabled = true
    this.buttonTextTarget.innerHTML = "Downloading"
    this.spinnerTarget.classList.remove("hidden")

    get(this.urlValue)
      .then(async response => {
        const filename = response.headers.get("content-disposition").match(/filename\s*="(.*?)"/)[1]
        const fallbackFilename = `${this.filenamePrefixValue}_${format(new Date(), "Y_MM_dd_HHmm")}.csv`;
        const csv = await response.text;

        return {
          filename: filename || fallbackFilename,
          csv,
        };
      })
      .then(({ csv, filename }) => {
        const blob = new Blob([csv], { type: "text/csv" })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        link.remove()
      }).then(() => {
        this.downloadButtonTarget.classList.remove(...this.downloadingClasses)
        this.downloadButtonTarget.classList.add(...this.downloadClasses)
        this.downloadButtonTarget.disabled = false
        this.buttonTextTarget.innerHTML = "Download"
        this.spinnerTarget.classList.add("hidden")
      })
      .catch(console.error)
  }
}
