import React from "react";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { useResetAssessmentMutation } from "./queries";
import useStudentAssessmentContext from "./useStudentAssessmentContext";

export default ({ countdownTimer, isConfirmationModalOpen, setIsConfirmationModalOpen, setShouldTimerResume }) => {
  const { resetModalTitle, resetModalContent } = useStudentAssessmentContext();
  const resetAssessmentMutation = useResetAssessmentMutation();
  const onSubmit = (event) => {
    resetAssessmentMutation.mutate(null, {
      onSuccess: () => {
        countdownTimer.reset();
        setIsConfirmationModalOpen(false);
        setShouldTimerResume(false);
      },
    });
    event.preventDefault();
  };

  return (
    <Modal
      open={isConfirmationModalOpen}
      onOpenChange={setIsConfirmationModalOpen}
    >
      <ModalTrigger>
        <button className="button-secondary ring-0 enabled:shadow-none flex items-center text-zinc-500 ml-2" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
          Reset
        </button>
      </ModalTrigger>
      <ModalContent>
        <form onSubmit={onSubmit}>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-base font-medium leading-6 text-gray-900 py-2 mb-2">{resetModalTitle}</h3>
              <div>
                <p className="text-sm text-gray-500 font-normal">{resetModalContent}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 space-y-2 sm:space-y-0 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              className="button-primary sm:ml-3 w-full sm:w-auto"
              disabled={resetAssessmentMutation.isLoading}
              type="submit"
            >
              Submit
            </button>
            <ModalClose>Cancel</ModalClose>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
