module.exports = {
  content: [
    './app/views/**/*.html.erb',
    './app/helpers/**/*.rb',
    './app/assets/stylesheets/**/*.css',
    './app/javascript/**/*.js*'
  ],
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('tailwindcss-bg-patterns'),
  ],
  theme: {
    extend: {
      colors: {
        // This color scale was generated using this tool:
        // https://hihayk.github.io/scale/#3/6/40/96/-1/16/6/14/8C2A7C/140/42/124/white
        brand: {
          '50':  '#F8F7FB',
          '100': '#DBD5EA',
          '200': '#C0B3D9',
          '300': '#A792C8',
          '400': '#8F70B6',
          '500': '#784FA5',
          '600': '#632E93', // This is the base brand color
          '700': '#562681',
          '800': '#4A1E6F',
          '900': '#3D175C',
        },
        'green': {
          '50': '#f0fdf4',
          '100': '#dcfce6',
          '200': '#bbf7ce',
          '300': '#85f0a8',
          '400': '#49df7b',
          '500': '#20bf55',
          '600': '#15a445',
          '700': '#148139',
          '800': '#166531',
          '900': '#14532b',
          '950': '#052e15',
        },
        'yellow': {
          '50': '#fff9eb',
          '100': '#ffefc6',
          '200': '#ffdd88',
          '300': '#ffc854',
          '400': '#ffae20',
          '500': '#f98a07',
          '600': '#dd6402',
          '700': '#b74406',
          '800': '#94330c',
          '900': '#7a2b0d',
          '950': '#461402',
        },
        'red': {
          '50': '#ffeff1',
          '100': '#ffe0e5',
          '200': '#ffc6d1',
          '300': '#ff97ab',
          '400': '#ff5d80',
          '500': '#ff2459',
          '600': '#ff1053',
          '700': '#d7003c',
          '800': '#b4003b',
          '900': '#990239',
          '950': '#570019',
        },
        'cyan': {
          '50': '#eefcfd',
          '100': '#d4f6f9',
          '200': '#afebf2',
          '300': '#77dbe9',
          '400': '#39c2d7',
          '500': '#1da6bd',
          '600': '#1b859f',
          '700': '#1d6c81',
          '800': '#20586a',
          '900': '#1f4a5a',
          '950': '#0f303d',
        },
        'orange': {
          '50': '#fff6ed',
          '100': '#ffebd5',
          '200': '#fed3aa',
          '300': '#fcb375',
          '400': '#fa8334',
          '500': '#f86717',
          '600': '#e94d0d',
          '700': '#c1380d',
          '800': '#992d13',
          '900': '#7c2812',
          '950': '#431107',
        },
        'fuchsia': {
          '50': '#FDE6F2',
          '100': '#FBCCE5',
          '200': '#F899CC',
          '300': '#F467B2',
          '400': '#F13499',
          '500': '#ED017F',
          '600': '#BE0166',
          '700': '#8E014C',
          '800': '#5F0033',
          '900': '#2F0019',
          '950': '#18000D',
        },
      },
      spacing: {
        '144': '36rem',
        '168': '42rem',
        '192': '48rem',
      }
    }
  }
}
