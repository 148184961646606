import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { axiosDelete, get, post } from "../../api";
import { useCurrentPagyPage } from "../common/Pagy";

export const useNotesQuery = () => {
  const currentPage = useCurrentPagyPage();
  return useQuery({
    queryKey: ["notes", currentPage],
    queryFn: async ({ signal }) => {
      const { data } = await get("notes", {
        params: {
          page: currentPage
        },
        signal,
      });

      return data;
    },
  });
};

export const useCreateNoteMutation = (content) => {
  const { id: studentId } = useParams();
  const currentPage = useCurrentPagyPage();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => post(`/students/${studentId}/notes`, { note: { content }}),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["notes", currentPage], (prevData) => {
        const prevNotes = prevData?.notes || [];

        return {
          ...prevData,
          notes: [...prevNotes, data],
        };
      });
    },
  });
};

export const useDeleteNoteMutation = (noteId) => {
  const { id: studentId } = useParams();
  const currentPage = useCurrentPagyPage();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => axiosDelete(`/students/${studentId}/notes/${noteId}`),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["notes", currentPage],
        (prevData) => {
          return {
            ...prevData,
            notes: prevData.notes.filter(note => {
              return note.id !== data.id
            }),
          };
        }
      );
    },
  });
};
