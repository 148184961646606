import { format, parse } from "date-fns";
import range from "lodash/range";
import React, { useCallback, useMemo} from "react";
import useMeasure from "react-use-measure";

import CalendarResizableInterval from "./CalendarResizableInterval";
import CalendarHighDemandInterval from "./CalendarHighDemandInterval";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import {
  MERIDIEM_HOUR_FORMAT,
  TUTOR_AVAILABILITY_END_HOUR_EASTERN,
  TUTOR_AVAILABILITY_START_HOUR_EASTERN,
} from "../../constants";
import { differenceInMinutes } from "../../utils"

const totalMinutes = (TUTOR_AVAILABILITY_END_HOUR_EASTERN - TUTOR_AVAILABILITY_START_HOUR_EASTERN) * 60;
export default ({ availabilities, highDemandIntervals, setAvailabilities, timeZoneName, timeZoneOffset }) => {
  const [ref, bounds] = useMeasure();
  const interpolateTime = useCallback((time) => {
    const timeInMinutes = differenceInMinutes(time, `${TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset}:00`);
    const y = Math.round(timeInMinutes * bounds.height / totalMinutes);

    return isNaN(y) ? 0 : y
  }, [bounds, timeZoneOffset]);
  const hoursRange = useMemo(() => {
    return range(
      TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset,
      TUTOR_AVAILABILITY_END_HOUR_EASTERN + timeZoneOffset
    );
  }, [timeZoneOffset]);
  const onChange = (uuid) => (nextStartTime, nextEndTime) => {
    setAvailabilities(prevAvailabilities => {
      return prevAvailabilities.map(availability => {
        if (availability.uuid === uuid) {
          return { ...availability, end_time: nextEndTime, start_time: nextStartTime };
        } else {
          return availability;
        }
      })
    });
  };

  return (
    <div className="flex-1 max-w-[500px] relative hidden lg:block">
      <div className="absolute bottom-full flex items-center w-full pb-1.5">
        <p className="flex items-center grow text-zinc-500 text-xs whitespace-nowrap">
          <em>All times are {timeZoneName}</em>
          <Tooltip>
            <TooltipTrigger>
              <button
                className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                type="button"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-full">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                </svg>
              </button>
            </TooltipTrigger>
            <TooltipContent>
              You can change the time zone<br />from <a className="text-brand-500 hover:underline" href="/profile/edit">your profile page</a>
            </TooltipContent>
          </Tooltip>
        </p>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-yellow-300 w-3.5 h-3.5 mr-0.5">
            <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
          </svg>
          <p className="flex items-center text-zinc-500 text-xs">
            <em>- High demand times</em>
            <Tooltip>
              <TooltipTrigger>
                <button
                  className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-full">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                  </svg>
                </button>
              </TooltipTrigger>
              <TooltipContent>You have a higher chance of being scheduled during these times</TooltipContent>
            </Tooltip>
          </p>
        </div>
      </div>
      <table className="border rounded-lg border-separate border-spacing-0 table-fixed w-full">
        <thead>
          <tr>
            <th className="border-b border-r bg-gray-50 rounded-tl-lg w-[60px]"></th>
            <th className="border-b border-r bg-gray-50 py-3 uppercase text-xs font-normal text-gray-500" scope="col">Mon</th>
            <th className="border-b border-r bg-gray-50 uppercase text-xs font-normal text-gray-500" scope="col">Tue</th>
            <th className="border-b border-r bg-gray-50 uppercase text-xs font-normal text-gray-500" scope="col">Wed</th>
            <th className="border-b border-r bg-gray-50 uppercase text-xs font-normal text-gray-500" scope="col">Thu</th>
            <th className="border-b rounded-tr-lg bg-gray-50 uppercase text-xs font-normal text-gray-500" scope="col">Fri</th>
          </tr>
        </thead>
        <tbody>
        {hoursRange.map(hour => (
          <tr className="group" key={hour}>
            <th className="align-top border-b group-last:border-b-0 border-r bg-gray-50 font-normal h-[64px] group-first:h-[67px] group-last:h-[67px] px-2 py-1 group-last:rounded-bl-lg uppercase text-xs text-gray-500 text-right" scope="row">
              {format(parse(hour, "H", new Date()), MERIDIEM_HOUR_FORMAT)}
            </th>
            <td className="border-r group-first:pt-[4px] group-last:pb-[4px]" />
            <td className="border-r group-first:pt-[4px] group-last:pb-[4px]" />
            <td className="border-r group-first:pt-[4px] group-last:pb-[4px]" />
            <td className="border-r group-first:pt-[4px] group-last:pb-[4px]" />
          </tr>
        ))}
        </tbody>
      </table>
      <div className="absolute bottom-[4px] left-[calc(60px+4px)] top-[calc(41px+4px)] right-[4px]" ref={ref}>
        {highDemandIntervals.map(({ endTime, startTime }) => (
          <CalendarHighDemandInterval
            key={`${startTime}-${endTime}`}
            endY={interpolateTime(endTime)}
            startY={interpolateTime(startTime)}
          />
        ))}
        {availabilities.filter(availability => !availability._destroy).map(availability => (
          <CalendarResizableInterval
            availability={availability}
            calendarHeight={bounds.height}
            endY={interpolateTime(availability.end_time)}
            interpolateTime={interpolateTime}
            key={availability.uuid}
            onChange={onChange(availability.uuid)}
            startY={interpolateTime(availability.start_time)}
            timeZoneOffset={timeZoneOffset}
          />
        ))}
      </div>
    </div>
  );
}
