import cx from "classnames";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isToday,
  isWeekend,
  startOfWeek,
} from "date-fns";
import noop from "lodash/noop";
import React, { useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { SHORT_DAY_OF_WEEK } from "../../../constants";
import { CurrentDateContext } from "../../../contexts/Calendar";

const defaultComponents = {
    AdjacentTableHeader: noop,
};
const WeeklyView = ({ children, components, hideHourHeading, isDarkMode, onDateSelect = noop, selectedDates, thClassNames }) => {
  const { AdjacentTableHeader } = { ...defaultComponents, ...components };
  const { currentDate } = useContext(CurrentDateContext);
  const dateRange = useMemo(() => {
    return eachDayOfInterval({
      start: startOfWeek(currentDate),
      end: endOfWeek(currentDate),
    }).filter(date => !isWeekend(date));
  }, [currentDate]);

  return (
    <table
      className={twMerge(cx("w-full h-[1px] border-spacing-0 border-b grow table-fixed bg-white text-gray-700", {
        "bg-zinc-800 text-white border-zinc-600": isDarkMode,
      }))}
    >
      <thead>
        <tr>
          {hideHourHeading ? null :
            <th
              className={twMerge(cx(
                "bg-white w-[52px] md:w-[60px] p-0 align-bottom sticky z-40 hidden md:table-cell", {
                  "bg-zinc-800": isDarkMode,
                },
                thClassNames,
              )) }
            >
              <div
                className={cx(
                  "h-[calc(100%+1px)] border border-l-0 -mr-[1px] -mt-[1px]", {
                    "border-zinc-600": isDarkMode,
                  }
                )}
              />
            </th>
          }
          {dateRange.map((date) => (
            <th
              className={twMerge(cx(
                "border-l border-r w-[calc((100%-60px)/5)] text-center p-0 leading-none sticky z-40", {
                  "bg-sky-50": isToday(date) && !isDarkMode,
                  "bg-white": !isToday(date) && !isDarkMode,
                  "bg-slate-700 cursor-pointer": isToday(date) && isDarkMode,
                  "bg-zinc-800 cursor-pointer": !isToday(date) && isDarkMode,
                  "bg-brand-900": isDarkMode && selectedDates.has(date.toString()),
                }, thClassNames)
              )}
              key={date.toISOString()}
              data-date={date.toISOString()}
              scope="col"
              onClick={() => onDateSelect(date)}
            >
              <div
                className={twMerge(cx(
                  "relative border -mx-[1px] -mt-[1px] py-0.5", {
                    "border-zinc-600": isDarkMode,
                  }
                ))}
              >
                <h2 className="font-medium text-lg leading-none">
                  <span className={twMerge(cx(
                    "inline-flex items-center justify-center rounded-full w-[28px] h-[28px] text-zinc-500", {
                      "bg-sky-500": isToday(date),
                      "text-white": isToday(date) || isDarkMode
                    }))}
                  >
                      {format(date, "d")}
                    </span>
                </h2>
                <span className={twMerge(cx("text-xs font-normal text-zinc-500/80", {
                  "text-sky-500": isToday(date),
                  "text-white/80": isDarkMode
                }))}>
                  {format(date, SHORT_DAY_OF_WEEK)}
                </span>
                <AdjacentTableHeader date={date} />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {children(dateRange)}
      </tbody>
    </table>
  );
};

WeeklyView.defaultProps = {
  components: defaultComponents,
};

export default WeeklyView;
