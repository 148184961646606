import React from "react";

import StudentDearAssessment from "../StudentDearAssessment";
import StudentOneMinuteChallenge from "../StudentOneMinuteChallenge";

const lessonCardProps = {
  hideReadingStats: true,
  isCollapsible: false,
  open: true,
};
const LessonCardRenderer = ({
  id,
  isLast,
  onCompleteAssessment,
  onUpdateWordEndCallback,
  studentId,
  type,
}) => {
  const submitResultsButtonLabel = isLast ? "Submit Assessment" : "Continue Assessment";

  if (type === "StudentOralReadingFluency") {
    return (
      <StudentDearAssessment
        {...lessonCardProps}
        assessmentId={id}
        onCompleteAssessment={onCompleteAssessment}
        onUpdateWordEndCallback={onUpdateWordEndCallback}
        paths={{
          completeAssessment: `/students/${studentId}/student_dear_assessment/${id}/complete_assessment`,
          index: `/students/${studentId}/student_dear_assessment/${id}`,
          resetAssessment: `/students/${studentId}/student_dear_assessment/${id}/reset_assessment`,
          startAssessment: `/students/${studentId}/student_dear_assessment/${id}/start_assessment`,
          updateGoal: `/students/${studentId}/student_dear_assessment/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_dear_assessment/${id}/update_seconds_remaining`,
          updateWord: `/students/${studentId}/student_dear_assessment/${id}/update_word`,
        }}
        submitResultsButtonLabel={submitResultsButtonLabel}
        title="Oral Reading Fluency"
        type={type}
      />
    );
  }

  if (type === "StudentNonsenseWordFluency") {
    return (
      <StudentOneMinuteChallenge
        {...lessonCardProps}
        assessmentId={id}
        onCompleteAssessment={onCompleteAssessment}
        onUpdateWordEndCallback={onUpdateWordEndCallback}
        paths={{
          completeAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/complete`,
          index: `/students/${studentId}/student_nonsense_word_fluency/${id}`,
          resetAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/reset`,
          startAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/start`,
          updateGoal: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/decrement_word_incorrect`,
        }}
        submitResultsButtonLabel={submitResultsButtonLabel}
        type={type}
      />
    );
  }

  if (type === "StudentLetterNameFluency") {
    return (
      <StudentOneMinuteChallenge
        {...lessonCardProps}
        assessmentId={id}
        onCompleteAssessment={onCompleteAssessment}
        onUpdateWordEndCallback={onUpdateWordEndCallback}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_challenge/${id}/complete`,
          index: `/students/${studentId}/student_reading_challenge/${id}`,
          resetAssessment: `/students/${studentId}/student_reading_challenge/${id}/reset`,
          startAssessment: `/students/${studentId}/student_reading_challenge/${id}/start`,
          updateGoal: `/students/${studentId}/student_reading_challenge/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_reading_challenge/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_reading_challenge/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_reading_challenge/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_reading_challenge/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/decrement_word_incorrect`,
        }}
        submitResultsButtonLabel={submitResultsButtonLabel}
        type={type}
      />
    );
  }

  return null;
};

export default LessonCardRenderer;
