import { Controller } from "@hotwired/stimulus"
import { format } from "date-fns";

import { DEFAULT_DATE_FORMAT } from "../constants";

// Connects to data-controller="today-or-date-selection"
export default class extends Controller {
  static targets = ["datePicker", "hiddenField", "submit", "programEnrollment", "assignmentType"];

  changeTerminationOption(event) {
    if (event.target.value === "on_date") {
      this.datePickerTarget.disabled = false;
      this.hiddenFieldTarget.value = null;
      this.submitTarget.disabled = !this.datePickerTarget.value;
      this.datePickerTarget.focus();
    } else {
      this.datePickerTarget.disabled = true;
      this.submitTarget.disabled = false;
      this.hiddenFieldTarget.value = format(new Date(), DEFAULT_DATE_FORMAT);
    }
  }

  changeDatePickerValue(event) {
    this.hiddenFieldTarget.value = event.target.value;
    this.submitTarget.disabled = !this.datePickerTarget.value;
  }

  changeProgramEnrollment(event) {
    this.programEnrollmentTarget.value = event.target.selectedOptions[0].id
    this.datePickerTarget.max = event.target.selectedOptions[0].value
  }

  changeAssignmentType(event) {
    let assignDateRadioButtons =
        document.getElementById("assign-date-radio-buttons");

    this.assignmentTypeTarget.value = event.target.selectedOptions[0].value;

    if(this.assignmentTypeTarget.value === "invite")
    {
      assignDateRadioButtons.style.display = "none";
    }
    else
    {
      assignDateRadioButtons.style.display = "flex";
    }
  }
}
