import startCase from "lodash/startCase";
import React, { useMemo } from "react";

import { useStudentAssessmentWordCountQuery } from "./queries";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useStudentAssessmentQuery } from "../StudentAssessment";

export default ({ hideReadingStats }) => {
  const { data } = useStudentAssessmentWordCountQuery();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const goals = studentAssessmentQuery?.data?.goals;
  const recordWpm = studentAssessmentQuery?.data?.assessmentRecord?.wordsPerMinute;
  const highestWpm = useMemo(
    () => (data?.correct > recordWpm ? data?.correct : recordWpm),
    [data?.correct, recordWpm]
  );
  const goalWidth = useMemo(() => {
    let goalPercent = (highestWpm / goals?.studentEoyGoal) * 100;

    if (!data || !goals?.studentEoyGoal) {
      goalPercent = 0;
    } else if (goalPercent > 100) {
      goalPercent = 100;
    }

    return `${goalPercent}%`;
  }, [data, highestWpm, goals?.studentEoyGoal]);

  return (
    <div className="space-y-2 mb-8">
      <h5 className="uppercase font-medium text-zinc-500">Challenge results</h5>
      <div className="space-y-12">
        <ul className="space-y-2.5 text-sm">
          <li className="flex items-center text-lg font-medium border-b border-zinc-300 text-zinc-700 py-4 px-2 space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
              />
            </svg>
            <span>Total Correct: {data?.correct ?? "--"}</span>
          </li>
          <li className="flex items-center text-zinc-500 px-2 space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M10.75 16.82A7.462 7.462 0 0115 15.5c.71 0 1.396.098 2.046.282A.75.75 0 0018 15.06v-11a.75.75 0 00-.546-.721A9.006 9.006 0 0015 3a8.963 8.963 0 00-4.25 1.065V16.82zM9.25 4.065A8.963 8.963 0 005 3c-.85 0-1.673.118-2.454.339A.75.75 0 002 4.06v11a.75.75 0 00.954.721A7.506 7.506 0 015 15.5c1.579 0 3.042.487 4.25 1.32V4.065z" />
            </svg>
            <span>Total Attempted: {data?.attempted ?? "--"}</span>
          </li>
          <li className="flex items-center text-zinc-500 px-2 space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clipRule="evenodd"
              />
            </svg>
            <span>Errors: {data?.errored ?? "--"}</span>
          </li>
        </ul>

        {!hideReadingStats ? (
          <div className="items-center space-y-2.5">
            <div className="flex items-center text-lg font-medium text-zinc-700 pt-4 px-2 space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-7 h-7 shrink-0"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                />
              </svg>
              <span className="whitespace-nowrap">
                End of Year Goal: {goals?.studentEoyGoal}{" "}
                <abbr className="uppercase text-[10px]" title="Words Correct Per Minute">
                  wcpm
                </abbr>
              </span>
              <Tooltip>
                <TooltipTrigger>
                  <button className="text-zinc-400 hover:text-zinc-600" type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </button>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="max-w-[360px] text-left min-w-min text-xs md:text-sm text-zinc-600 px-2 md:px-3 py-2">
                    <h5 className="font-medium mb-1.5">DEAR End of Year Goals</h5>
                    <p className="mb-4">
                      The expected goal rate should be the student’s actual grade level, NOT their
                      protocol-aligned grade level. In other words, if your 4th grader is on B+D,
                      their expected rate is 125 wcpm.
                    </p>
                    <ul>
                      {Object.entries(goals ? goals?.dearGoalsMap : {}).map(([key, value]) => (
                        <li key={key}>
                          {startCase(key)}: {value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="text-zinc-500 px-2">
              <div className="items-center flex">
                <div className="items-center relative max-w-[360px] grow">
                  <span className="items-center min-w-full h-2 bg-gray-100 rounded-full absolute top-1/2 mt-[-.25rem]"></span>
                  <span
                    className="items-center h-2 bg-brand-700 rounded-full absolute top-1/2 mt-[-.25rem]"
                    style={{ width: goalWidth }}
                  ></span>
                </div>
                <div className="items-center flex p-1 px-2 text-xs">
                  EOY Goal: {goals?.studentEoyGoal ?? "--"}
                </div>
              </div>
              <div className="py-2 text-xs">
                Highest Record: {highestWpm ?? "-"}{" "}
                <abbr className="uppercase" title="Words Correct Per Minute">
                  wcpm
                </abbr>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
