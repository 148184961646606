import isEmpty from "lodash/isEmpty";
import React from "react";
import { twMerge } from "tailwind-merge";

const DateRangePicker = ({ className, "data-testid": dataTestid, endDate, startDate, showClearButton, ...props }) => {
  const onChangeStartDate = (event) => {
    const { value } = event.target;

    props.onChangeStartDate(value);
    if (isEmpty(value)) {
      props.onChangeEndDate("");
    }
  };
  const onChangeEndDate = (event) => {
    props.onChangeEndDate(event.target.value);
  };
  const onClearFilter = () => {
    props.onChangeEndDate("");
    props.onChangeStartDate("");
  };

  return (
    <div className={twMerge("flex items-center space-x-2", className)}>
      <input
        className="border-gray-300 py-[5px] rounded-lg focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm"
        data-testid={`${dataTestid}-start-date-input`}
        onChange={onChangeStartDate}
        type="date"
        value={startDate}
      />
      <span className="text-gray-400">-</span>
      <input
        className="border-gray-300 py-[5px] rounded-lg focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm disabled:bg-gray-50 disabled:text-gray-400"
        data-testid={`${dataTestid}-end-date-input`}
        disabled={!startDate}
        min={startDate}
        onChange={onChangeEndDate}
        type="date"
        value={endDate}
      />
      {(showClearButton && (endDate || startDate)) ? (
        <button
          className="button-secondary"
          onClick={onClearFilter}
          type="button"
        >
          Clear
        </button>
      ) : null}
    </div>
  );
};

DateRangePicker.defaultProps = {
  showClearButton: false,
};

export default DateRangePicker;
