import times from "lodash/times";
import React, { useContext, useEffect } from "react";

import { PagyContext } from "./";
import { PAGY_UPPER_PAGE_BOUND } from "../../../constants";

const PagyNavigation = ({ query }) => {
  const { pagy, setPagy } = useContext(PagyContext);
  const { upperPageBound, setUpperPageBound } = useContext(PagyContext);
  const { lowerPageBound, setLowerPageBound } = useContext(PagyContext);

  const onClickFirst = () => {
    setUpperPageBound(PAGY_UPPER_PAGE_BOUND);
    setLowerPageBound(0);
    setPagy((prevPagy) => ({ ...prevPagy, page: 1 }));
  }
  const onClickLast = () => {
    setUpperPageBound(pagy.last);
    setLowerPageBound(pagy.last - PAGY_UPPER_PAGE_BOUND);
    setPagy((prevPagy) => ({ ...prevPagy, page: pagy.last }));
  }
  const onClickNext = () => {
    if(pagy.page + 1 >= 1 + PAGY_UPPER_PAGE_BOUND) {
      setUpperPageBound(upperPageBound + 1 > pagy.last ? pagy.last : upperPageBound + 1);
      setLowerPageBound(lowerPageBound + 1 < 1 ? 1 : (lowerPageBound + 1 > pagy.last - PAGY_UPPER_PAGE_BOUND ? pagy.last - PAGY_UPPER_PAGE_BOUND : lowerPageBound + 1));
    }
    setPagy((prevPagy) => ({ ...prevPagy, page: pagy.page + 1 }));
  };
  const onClickPrev = () => {
    if(pagy.page - 1 <= pagy.last - (PAGY_UPPER_PAGE_BOUND - 1) / 2) {
      setUpperPageBound(upperPageBound - 1 > pagy.last ? pagy.last : (upperPageBound - 1 < 5 ? 5 : upperPageBound - 1));
      setLowerPageBound(lowerPageBound - 1 < 0 ? 0 : lowerPageBound - 1);
    }
    setPagy((prevPagy) => ({ ...prevPagy, page: pagy.page - 1 }));
  };
  const onClickPage = (pageNumber) => () => {
    setPagy((prevPagy) => ({ ...prevPagy, page: pageNumber }));
    if(pageNumber > (PAGY_UPPER_PAGE_BOUND - 1)/2 && pageNumber <= (pagy.last - ( PAGY_UPPER_PAGE_BOUND - 1 ) / 2)) {
      setUpperPageBound(((pageNumber + ( PAGY_UPPER_PAGE_BOUND - 1 ) / 2) >= pagy.last) ? pagy.last : pageNumber + ( PAGY_UPPER_PAGE_BOUND - 1 ) / 2)
      setLowerPageBound(((pageNumber - ( PAGY_UPPER_PAGE_BOUND - 1 ) / 2) <= 1) ? 0 : ((pageNumber - ( PAGY_UPPER_PAGE_BOUND - 1 ) / 2) - 1))
    }
  };
  useEffect(() => {
    if (query.isFetched && query.data?.pagy) {
      setPagy(query.data?.pagy);
    }
  }, [query.data, query.isFetched, setPagy]);

  if (pagy === undefined || pagy.pages === 1) {
    return null;
  }

  return (
    <nav
      className="pagy-nav pagination flex items-center justify-between mt-6 px-4 sm:px-0"
      role="navigation"
    >
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center pr-1 text-sm font-medium text-zinc-500 hover:text-brand-900 disabled:text-zinc-400"
          disabled={pagy.page <= 1}
          onClick={onClickFirst}
          type="button"
        >
          <svg
            className="mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 38"
            fill="currentColor"
            aria-hidden="true"
          >
            <polygon points="7.884 19 15.398 26.514 15.398 11.486 7.884 19" />
            <rect height="2.5" width="21.79" x="13.044" y="17.75" />
            <rect height="15.028" width="2.5" x="3.166" y="11.486" />
          </svg>
        </button>
        <button
          className="inline-flex items-center pr-1 text-sm font-medium text-zinc-500 hover:text-brand-900 disabled:text-zinc-400"
          disabled={pagy.page <= 1}
          onClick={onClickPrev}
          type="button"
        >
          <svg
            className="mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 38"
            fill="currentColor"
            aria-hidden="true"
          >
            <polygon points="7.884 19 15.398 26.514 15.398 11.486 7.884 19" />
            <rect height="2.5" width="21.79" x="13.044" y="17.75" />
          </svg>
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {lowerPageBound >= 1 ? (
          <button
            className="inline-flex items-center px-4 text-sm font-medium text-gray-500 enabled:hover:text-brand-900 disabled:text-brand-600"
            type="button"
          >
            {" "}
            &hellip;{" "}
          </button>
        ) : null}
        {times(pagy.pages).map((index) =>
          index < upperPageBound && index >= lowerPageBound ? (
            <button
              className="inline-flex items-center px-4 text-sm font-medium text-gray-500 enabled:hover:text-brand-900 disabled:text-brand-600"
              key={index}
              onClick={onClickPage(index + 1)}
              type="button"
            >
              {pagy.page === index + 1 ? '\u005B' + (index + 1) + '\u005D' : index + 1 }
            </button>
          ) : null
        )}
        {times(pagy.pages).length > upperPageBound ? (
          <button
            className="inline-flex items-center px-4 text-sm font-medium text-gray-500 enabled:hover:text-brand-900 disabled:text-brand-600"
            type="button"
          >
            {" "}
            &hellip;{" "}
          </button>
        ) : null}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center pl-1 text-sm font-medium text-zinc-500 enabled:hover:text-brand-900 disabled:text-zinc-400"
          disabled={pagy.page >= pagy.last}
          onClick={onClickNext}
          type="button"
        >
          Next
          <svg
            className="ml-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 38"
            fill="currentColor"
            aria-hidden="true"
          >
            <polygon points="30.116 19 22.602 26.514 22.602 11.486 30.116 19" />
            <rect height="2.5" transform="translate(28.122 38) rotate(-180)" width="21.79" x="3.166" y="17.75" />
          </svg>
        </button>
        <button
          className="inline-flex items-center pl-1 text-sm font-medium text-zinc-500 enabled:hover:text-brand-900 disabled:text-zinc-400"
          disabled={pagy.page >= pagy.last}
          onClick={onClickLast}
          type="button"
        >
          <svg
            className="ml-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 38 38"
            fill="currentColor"
            aria-hidden="true"
          >
            <polygon points="30.116 19 22.602 26.514 22.602 11.486 30.116 19" />
            <rect height="2.5" transform="translate(28.122 38) rotate(-180)" width="21.79" x="3.166" y="17.75" />
            <rect height="15.028" transform="translate(67.168 38) rotate(-180)" width="2.5" x="32.334" y="11.486" />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default PagyNavigation;
