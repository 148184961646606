import get from "lodash/get";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";

export default function (breakpointsValuesMap) {
  const twConfig = resolveConfig(tailwindConfig);
  const breakpointValues = Object.entries(twConfig.theme.screens)
    .map(([key, breakpoint]) => {
      return [key, parseInt(breakpoint, 10), breakpointsValuesMap[key]];
    })
    .filter(([, , value]) => {
      return value !== undefined;
    })
    .sort(([, breakpointA], [, breakpointB]) => {
      return breakpointA - breakpointB;
    })
    .findLast(([, breakpoint]) => {
      return matchMedia(`(min-width: ${breakpoint}px)`).matches;
    });

  return get(breakpointValues, "[2]", breakpointsValuesMap?.default);
}
