import first from "lodash/first";
import React, { useEffect, useMemo, useRef } from "react";

import ContentRow from "./ContentRow";
import { useProtocolListQuery } from "./queries";

const ProtocolList = () => {
  const protocolListQuery = useProtocolListQuery();
  const inProgressRef = useRef(null);
  const firstContentToScrollTo = useMemo(() => {
    const candidates = protocolListQuery.data?.filter(({ cannotViewProtocol, status }) => {
      return ["reteach_needed", "in_progress"].includes(status) && !cannotViewProtocol;
    });

    return first(candidates);
  }, [protocolListQuery.data]);

  useEffect(() => {
    if (inProgressRef.current) {
      inProgressRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [protocolListQuery.data]);

  if (!protocolListQuery.data) {
    if (protocolListQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {protocolListQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <table
      className="table-fixed border-collapse w-full divide-y divide-gray-200 mx-0 sm:mx-0 mt-6 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
      key="content"
    >
      <thead className="hidden sm:table-header-group">
        <tr className="text-left text-zinc-900 text-sm md:text-base">
          <th
            className="bg-gray-50 py-4 px-4 sm:px-6 w-1/2 sm:w-2/5 font-medium md:rounded-tl-lg"
            scope="col"
          >
            Name
          </th>
          <th className="bg-gray-50 py-4 px-4 sm:px-6 w-1/3 font-medium" scope="col">
            Content
          </th>
          <th className="bg-gray-50 py-4 px-4 sm:px-6 font-medium" scope="col">
            Status
          </th>
          <th className="bg-gray-50 py-4 w-12 md:rounded-tr-lg" />
        </tr>
      </thead>
      {protocolListQuery.data.map((content) => (
        <tbody
          className="scroll-mt-16"
          key={content.id}
          ref={content.id === firstContentToScrollTo?.id ? inProgressRef : null}
        >
          <ContentRow content={content} />
        </tbody>
      ))}
    </table>
  );
};

export default ProtocolList;
