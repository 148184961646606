import React from "react";

import ConfirmationModalForMutation from "../common/./ConfirmationModalForMutation";
import { useSubmitLessonMutation } from "./queries";
import CheckCircleOutline from "assets/icons/check-circle-outline.svg";

const CompleteLessonModal = ({ children, studentProtocolId, lessonId, isBaseline, onComplete }) => {
  const submitLessonMutation = useSubmitLessonMutation({ studentProtocolId, lessonId, isBaseline });

  return (
    <ConfirmationModalForMutation
      icon={
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <CheckCircleOutline className="h-6 w-6 text-green-600" strokeWidth={2} />
        </div>
      }
      message={[
        <p className="text-sm text-gray-500 font-normal" key="p-1">
          Double-check if every part has been taught. Once marked complete, it cannot be changed!
        </p>,
        <p className="text-sm text-zinc-500 font-normal" key="p-2">
          <strong>Are you sure you want to mark this lesson as complete?</strong>
        </p>,
      ]}
      onConfirm={onComplete}
      mutation={submitLessonMutation}
      mutateParams="completed"
      submitButtonLabel="Complete Lesson"
      title="Lesson Completion Check"
    >
      {children}
    </ConfirmationModalForMutation>
  );
};

export default CompleteLessonModal;
