import React from "react";
import cx from "classnames";

import { tutorTimeOffStatus } from "../../constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";

const { APPROVED } = tutorTimeOffStatus;

export default ({ date }) => {
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const { getTimeOffStatus } = useTutorTimeOffs();

  return getTimeOffStatus(date) ? (
    <div className={cx("absolute top-0 right-[2px] sm:right-[4px] flex items-center h-full", {
      "text-zinc-500/80": isCalendarMode,
      "text-white/80": isSubMode
    })}>
      <Tooltip>
        <TooltipTrigger>
          <button
            className={cx("flex items-center justify-center w-6 sm:w-10 ml-1 align-middle h-8", {
              "text-zinc-500/80 hover:text-zinc-500": isCalendarMode,
              "text-white/80 hover:text-white": isSubMode
            })}
            type="button"
          >
            {getTimeOffStatus(date) === APPROVED ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 block" data-testid="tto-approved-icon">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 21" strokeWidth={1.5} stroke="none" className="w-6 h-6 fill-current block" data-testid="tto-pending-icon">
                <path d="M2 19C1.45 19 0.979167 18.8042 0.5875 18.4125C0.195833 18.0208 0 17.55 0 17V6C0 5.45 0.195833 4.97917 0.5875 4.5875C0.979167 4.19583 1.45 4 2 4H6V2C6 1.45 6.19583 0.979167 6.5875 0.5875C6.97917 0.195833 7.45 0 8 0H12C12.55 0 13.0208 0.195833 13.4125 0.5875C13.8042 0.979167 14 1.45 14 2V4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V10.275C19.7 10.0583 19.3833 9.87083 19.05 9.7125C18.7167 9.55417 18.3667 9.41667 18 9.3V6H2V17H9.075C9.125 17.35 9.2 17.6917 9.3 18.025C9.4 18.3583 9.525 18.6833 9.675 19H2ZM8 4H12V2H8V4ZM16 21C14.6167 21 13.4375 20.5125 12.4625 19.5375C11.4875 18.5625 11 17.3833 11 16C11 14.6167 11.4875 13.4375 12.4625 12.4625C13.4375 11.4875 14.6167 11 16 11C17.3833 11 18.5625 11.4875 19.5375 12.4625C20.5125 13.4375 21 14.6167 21 16C21 17.3833 20.5125 18.5625 19.5375 19.5375C18.5625 20.5125 17.3833 21 16 21ZM16.5 15.8V13H15.5V16.2L17.65 18.35L18.35 17.65L16.5 15.8Z" />
              </svg>
            )}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-full hidden sm:block">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
            </svg>
          </button>
        </TooltipTrigger>
        <TooltipContent>
          Your time off request is {getTimeOffStatus(date)}.
        </TooltipContent>
      </Tooltip>
    </div>
  ) : null;
}
