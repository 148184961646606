import axios from "axios";

import { getCsrfToken } from "../utils";

const instance = axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": getCsrfToken(),
  },
});

instance.axiosDelete = instance.delete

instance.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401) {
    window.location.href = window.RAILS_APP_LOGOUT_PATH;
  }

  return Promise.reject(error);
});

export const { get, patch, put, post, axiosDelete } = instance;
