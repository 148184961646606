import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { useMarkWordAsTaughtMutation } from "./queries";

const Word = ({ content, id, index, needsTeaching }) => {
  const markWordAsTaughtMutation = useMarkWordAsTaughtMutation(id);

  return (
    <button
      className={twMerge(cx(
        "button-secondary w-[124px] py-0 h-[32px] flex items-center justify-center", {
          "disabled:bg-green-100 disabled:text-green-800": !needsTeaching,
        }
      ))}
      disabled={!needsTeaching}
      onClick={markWordAsTaughtMutation.mutate}
      type="button"
    >
      {index}. {content}
      {needsTeaching ? null : (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )}
    </button>
  );
};

export default Word;
