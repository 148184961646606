import cx from "classnames";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import WordListItem from "./WordListItem";
import { useStudentAssessmentQuery } from "../StudentAssessment";

const WordList = ({ disabled, onGoBack, shouldPickEndWord }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { currentRoundWords, width } = studentAssessmentQuery.data || {};
  const words = useMemo(() => currentRoundWords || [], [currentRoundWords]);

  return (
    <ul
      className={twMerge(cx("grid gap-2 md:gap-4 mb-9", {
        "grid-cols-2": width === 2,
        "grid-cols-3": width === 3,
        "grid-cols-4": width === 4,
        "grid-cols-5": width === 5,
      }))}
    >
      {words.map((word) => (
        <li key={word.id}>
          <WordListItem
            disabled={disabled}
            incorrectCount={word.incorrectCount}
            isEndWord={word.end}
            label={word.text}
            onGoBack={onGoBack}
            readingChallengeWordId={word.readingChallengeWordId}
            shouldPickEndWord={shouldPickEndWord}
          />
        </li>
      ))}
    </ul>
  );
};

WordList.defaultProps = {
  shouldPickEndWord: false,
};

export default WordList;
