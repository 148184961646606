import cx from "classnames";
import { addDays, eachDayOfInterval, format, isToday, isWeekend } from "date-fns";
import React, { useContext, useMemo } from "react";

import { DAY_WITH_SHORT_MONTH, SHORT_DAY_OF_WEEK } from "../../../constants";
import { CurrentDateContext } from "../../../contexts/Calendar";

export default ({ children }) => {
  const { currentDate } = useContext(CurrentDateContext);
  const dateRange = useMemo(() => {
    return eachDayOfInterval({
      start: currentDate,
      end: addDays(currentDate, 6),
    }).filter(date => !isWeekend(date));
  }, [currentDate]);

  return (
    <ul className="border-t -mt-[1px]">
      {dateRange.map(date => (
        <li
          className={cx("flex border-b", {
            "bg-sky-50/60 border-t -mt-[1px]": isToday(date),
          })}
          key={date.toISOString()}
        >
          <div className="flex-none w-[76px] md:w-[110px] text-right py-2 p-2">
            <h2 className="font-medium text-sm md:text-lg">
            <span
              className={cx(
                "leading-none rounded-full py-0.5 px-1.5",
                isToday(date) ?  "text-sky-500": "text-zinc-500"
              )}
            >
              {format(date, DAY_WITH_SHORT_MONTH)}
            </span>
            </h2>
            <span className={cx("text-xs uppercase pr-2", isToday(date) ?  "text-sky-500": "text-zinc-500/80")}>
            {format(date, SHORT_DAY_OF_WEEK)}
          </span>
          </div>
          <div className="grow border-l min-w-0">
            {children(date)}
          </div>
        </li>
      ))}
    </ul>
  );
}
