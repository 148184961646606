import padStart from "lodash/padStart";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * @param {number} startFrom
 * @param {number} interval
 */
export default (startFrom, interval = 1000) => {
  const [timerId, setTimerId] = useState(null);
  const [tick, setTick] = useState(startFrom);
  const [isPaused, setIsPaused] = useState(true);
  const start = useCallback(() => {
    clearInterval(timerId);
    setTimerId(setInterval(() => {
      setTick(currentTick => currentTick - interval);
    }, interval));
    setIsPaused(false);
  }, [interval, timerId]);
  const pause = useCallback(() => {
    clearInterval(timerId);
    setIsPaused(true);
  }, [timerId]);
  const reset = useCallback(() => {
    setTick(startFrom);
    clearInterval(timerId);
    setTimerId(null);
    setIsPaused(true);
  }, [startFrom, timerId]);
  const isCompleted = tick === 0 && timerId === null;
  const isInProgress = timerId !== null && !isPaused;
  const minutes = padStart(Math.floor(tick / interval / 60), 2, "0");
  const seconds = padStart(tick / interval % 60, 2, "0");
  const remaining = tick / interval;

  useEffect(() => {
    if (tick === 0) {
      clearInterval(timerId);
      setTimerId(null);
    }
  }, [tick, timerId]);
  useEffect(() => {
    setTick(startFrom);
  }, [startFrom]);

  return useMemo(() => ({
    isCompleted,
    isInProgress,
    isPaused,
    minutes,
    pause,
    reset,
    remaining,
    seconds,
    start,
  }), [
    isCompleted,
    isInProgress,
    isPaused,
    minutes,
    pause,
    reset,
    remaining,
    seconds,
    start,
  ]);
}
