import React, { useContext } from "react";

import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { getCsrfToken } from "../../utils";

export default () => {
  const paths = useContext(RailsPathsContext);

  /*
   * This hidden form is used by QA automation to update remaining seconds of a lesson card.
   */
  return (
    <form
      action={paths.updateSecondsRemaining}
      className="hidden"
      data-testid="update-seconds-remaining-form"
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" data-testid="update-seconds-remaining-input" name="seconds_remaining" value="0" />
      <button data-testid="update-seconds-remaining-submit-button" type="submit">
        Submit
      </button>
    </form>
  );
};
