import React from "react";

import ExclamationTriangleIcon from "assets/icons/exclamation-triangle-outline.svg";

import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import StudentAssessmentResultsDrawer from "../StudentAssessmentResultsDrawer";
import { useAutoReteachFocusBannerQuery } from "./queries";

const AutoReteachFocusBanner = ({ studentLessonId }) => {
  const autoReteachFocusBannerQuery = useAutoReteachFocusBannerQuery(studentLessonId);
  const data = autoReteachFocusBannerQuery.data;

  if (!autoReteachFocusBannerQuery.data) {
    if (autoReteachFocusBannerQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {autoReteachFocusBannerQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return data.studentAssessment !== undefined && data.concepts !== undefined ? (
    <div className="top-full w-full border-2 rounded-lg -mt-[1px] border-yellow-200 bg-yellow-50 text-zinc-700 text-xs md:text-sm px-4 md:px-8 py-3 flex items-center justify-between">
      <div className="flex items-center">
        <span className="w-8 h-8 rounded-full flex items-center justify-center shrink-0 bg-orange-200 mr-2">
          <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500" />
        </span>
        <strong> Reteach Focus: </strong>
        <div className="ml-1">
          {data.concepts
            .flat()
            .map((concept) => {
              return concept?.trim();
            })
            .filter((value) => value && value.length > 0)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ")}
        </div>
      </div>
      <Drawer>
        <DrawerTrigger>
          <button
            className="flex items-center text-sm text-brand-600 font-semibold hover:text-brand-700 hover:underline space-x-0.5"
            data-testid={`student-assessment-results-item-${data.studentAssessment.id}`}
            type="button"
          >
            <span>Details &rarr;</span>
          </button>
        </DrawerTrigger>
        <DrawerContent data-testid={`auto-reteach-focus-drawer-${data.studentAssessment.id}`}>
          <StudentAssessmentResultsDrawer {...data.studentAssessment} />
        </DrawerContent>
      </Drawer>
    </div>
  ) : null;
};

export default AutoReteachFocusBanner;
