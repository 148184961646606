import { useMutation } from "@tanstack/react-query";
import isFunction from "lodash/isFunction";
import { useContext } from "react";

import { patch } from "../../api";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { useStudentAssessmentContext, useStudentAssessmentQuery } from "../StudentAssessment";
import { readingAssessmentItemType } from "../../constants";

const { WORD } = readingAssessmentItemType;

export const useUpdateWordMutation = () => {
  const paths = useContext(RailsPathsContext);
  const { onUpdateWordEndCallback } = useStudentAssessmentContext();

  return useMutation({
    mutationFn: async ({ correct, id, ended }) => {
      const response = await patch(paths.updateWord, {
        correct,
        ended,
        word_id: id,
      });

      return response.data;
    },
    onSuccess: (data) => {
      if (isFunction(onUpdateWordEndCallback)) {
        onUpdateWordEndCallback(data);
      }
    },
  });
};

export const useStudentAssessmentWordCountQuery = () => {
  return useStudentAssessmentQuery({
    select: (data) => {
      const words =
        data.readingAssessmentItems
          .sort((a, b) => {
            return a.sequenceNumber - b.sequenceNumber;
          })
          .filter((item) => {
            return item.assessmentItemType === WORD;
          }) || [];
      const endWordIndex = words.findIndex((word) => word.id === data.endItemId);
      const attempted = endWordIndex + 1;
      const errored = words.filter((word, i) => {
        return data.studentIncorrectReadingAssessmentItemIds.includes(word.id) && i <= endWordIndex;
      }).length;

      return {
        attempted,
        correct: attempted - errored,
        errored,
      };
    },
  });
};
