import cx from "classnames";
import { format } from "date-fns";
import React, { useContext } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { HOUR_MINUTE_TWELVE } from "../../constants";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import SubSessionRequestsList from "./SubSessionRequestsList";

const WeeklySubSessionRequestItem = ({ hideTimestamp, sessions, timeSlot }) => {
  const { isSubMode } = useCalendarViewMode();
  const { selectedTimeBlock, setSelectedTimeBlock } = useContext(SelectedTimeBlockContext);
  const onOpenChange = (open) => {
    if (!isSubMode) {
      setSelectedTimeBlock(open ? timeSlot.toString() : null);
    }
  }

  return (
    <Popover
      offset={{ alignmentAxis: -20 }}
      onOpenChange={onOpenChange}
      open={selectedTimeBlock === timeSlot.toString()}
      placement="right-start"
    >
      <PopoverTrigger>
        <div
          className="bg-orange-100 border-orange-300 hover:bg-orange-200/70 flex items-start justify-between rounded-[15px] h-full pl-2.5 pr-[5px] py-[4.5px] border cursor-pointer relative"
          data-testid={`tutor-calendar-weekly-view-sub-session-requests-for-${format(timeSlot, HOUR_MINUTE_TWELVE)}`}
        >
          <div className="flex flex-nowrap items-center grow whitespace-nowrap w-full">
            <h3 className="text-[12px] grow leading-tight truncate">
              {hideTimestamp ? null : (
                <span className="hidden md:inline-block w-[32px] text-right font-light mr-2.5 text-yellow-800/70">
                  {format(timeSlot, HOUR_MINUTE_TWELVE)}
                </span>
              )}
              <span className={cx("text-[11px] md:text-[13px] font-medium text-yellow-800")}>
                Sub Opportunity
              </span>
            </h3>
            <div className="bg-yellow-300 flex items-center justify-center rounded-full w-[16px] h-[16px] ml-1 text-xs font-semibold text-yellow-700">
              {sessions.length}
            </div>
          </div>
          {selectedTimeBlock === timeSlot.toString() ?
            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[15px] bg-sky-500/20 outline outline-sky-500 outline-offset-1 pointer-events-none" />
          : null}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <SubSessionRequestsList sessions={sessions} />
      </PopoverContent>
    </Popover>
  );
};

export default WeeklySubSessionRequestItem;
