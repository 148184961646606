import isFunction from "lodash/isFunction";
import React, { useEffect, useState } from "react";

import { useCompleteAssessmentMutation, useStudentAssessmentQuery } from "./queries";
import useStudentAssessmentContext from "./useStudentAssessmentContext";
import SetNewGoalForm from "./SetNewGoalForm";
import ContinueLessonButton from "./ContinueLessonButton";

const CompleteAssessmentForm = ({ hideReadingStats, submitButtonLabel }) => {
  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { onCompleteAssessment, setOpen } = useStudentAssessmentContext();
  const { correctWordsCount } = studentAssessmentQuery.data || {};
  const [nextGoalValue, setNextGoalValue] = useState(correctWordsCount ?? "");
  const onChangeNextGoalValue = (event) => {
    setNextGoalValue(event.target.value);
  };
  const onSubmit = (event) => {
    if (isFunction(onCompleteAssessment)) {
      onCompleteAssessment(event);
    } else {
      completeAssessmentMutation.mutate(nextGoalValue, {
        onSuccess: () => {
          setOpen(false);
        },
      });
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (correctWordsCount !== undefined) {
      setNextGoalValue(correctWordsCount);
    }
  }, [correctWordsCount]);

  return (
    <form onSubmit={onSubmit}>
      {!hideReadingStats ? (
        <SetNewGoalForm onChange={onChangeNextGoalValue} value={nextGoalValue} />
      ) : null}
      <ContinueLessonButton
        disabled={completeAssessmentMutation.isLoading}
        submitButtonLabel={submitButtonLabel}
      />
    </form>
  );
};

export default CompleteAssessmentForm;
