import cx from "classnames";
import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import Close from "../Floating/Close";

const ModalClose = forwardRef(({ children, className, ...props }, ref) => (
  <Close
    className={twMerge(cx("button-secondary w-auto", className))}
    ref={ref}
    {...props}
  >
    {children}
  </Close>
));

export default ModalClose;
