import React from "react";

import useFloating from "./useFloating";
import { FloatingContext } from "./context";

const FloatingProvider = ({ children, ...options }) => {
  const modal = useFloating(options);

  return (
    <FloatingContext.Provider value={modal}>
      {children}
    </FloatingContext.Provider>
  );
}

export default FloatingProvider;
