import { format } from "date-fns";
import React, { useContext } from "react";
import cx from "classnames";

import { MONTH_NAME_WITH_YEAR } from "../../constants";
import { CurrentDateContext } from "../../contexts/Calendar";
import { DateNavigator } from "../common/Calendar";
import HeaderToolbar from "./HeaderToolbar";
import TimezoneNote from "./TimezoneNote";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";

export default ({ timeZoneName, subBoardEnabled }) => {
  const { currentDate } = useContext(CurrentDateContext);
  const { isCalendarMode, isSubMode } = useCalendarViewMode();

  return (
    <header
      className={cx("border-b flex flex-col xl:flex-row items-center justify-between lg:justify-around py-2 md:py-4 xl:py-7 px-2 md:px-5 lg:px-8 sticky top-[55px] md:top-[61px] lg:top-0 z-40 lg:gap-2.5", {
        "bg-white": isCalendarMode,
        "bg-zinc-800 text-white border-zinc-600": isSubMode,
      })}
    >
      <div className="flex flex-1 items-center overflow-visible w-full mb-1 lg:mb-0">
        <div className="flex flex-1 items-center overflow-visible w-full mb-1 lg:mb-0">
          <DateNavigator />
          <h1 className="text-sm md:text-xl lg:text-2xl font-medium ml-2 md:ml-3 lg:ml-5 whitespace-nowrap truncate">
            {format(currentDate, MONTH_NAME_WITH_YEAR)}
          </h1>
        </div>
        <div className={cx("text-xs", isSubMode ? "text-zinc-400" : "text-zinc-500", "flex xl:hidden")}>
          <TimezoneNote timeZoneName={timeZoneName}/>
        </div>
      </div>
      <div className={cx("flex items-center text-xs pt-0.5 whitespace-nowrap w-full xl:w-auto", isSubMode ? "text-zinc-400" : "text-zinc-500")}>
        <div className={cx("mr-4 text-xs hidden xl:flex", isSubMode ? "text-zinc-400" : "text-zinc-500")}>
          <TimezoneNote timeZoneName={timeZoneName} />
        </div>
        <HeaderToolbar subBoardEnabled={subBoardEnabled} />
      </div>
    </header>
  );
};
