import { useContext } from "react";

import { PagyContext } from "./";

const useCurrentPagyPage = () => {
  const { pagy } = useContext(PagyContext);

  return pagy.page;
};

export default useCurrentPagyPage;
