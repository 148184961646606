import cx from "classnames";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { useUpdateQuestionMutation } from "./queries";
import { useStudentAssessmentQuery } from "../StudentAssessment";

export default ({ answers, id, question, taskId, checkboxes=true }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { studentQuestionAnswers } = studentAssessmentQuery.data || {
    studentQuestionAnswers: [],
  };
  const updateQuestionMutation = useUpdateQuestionMutation();
  const onClickCorrect = () => {
    updateQuestionMutation.mutate({ correct: true, questionId: id, taskId });
  };
  const onClickIncorrect = () => {
    updateQuestionMutation.mutate({ correct: false, questionId: id, taskId });
  };
  const studentAnswer = useMemo(() => {
    return studentQuestionAnswers.find(studentQuestionAnswer => {
      return studentQuestionAnswer.readingAssessmentQuestionId === id;
    })
  }, [id, studentQuestionAnswers]);

  return (
    <div className="py-4 space-y-4">
      <div className="flex items-start justify-between space-x-1">
        <h4 className="mt-1.5">{question}</h4>
        {checkboxes &&
        <div className="flex space-x-1">
          <button
            className={twMerge(cx("button-secondary p-2", {
              "bg-red-50 ring-red-300 text-red-700 enabled:hover:bg-red-100": studentAnswer?.correct === false,
            }))}
            disabled={updateQuestionMutation.isLoading}
            onClick={onClickIncorrect}
            title="Answer is not correct"
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
          <button
            className={twMerge(cx("button-secondary p-2", {
              "bg-green-50 ring-green-300 text-green-700 enabled:hover:bg-green-100": studentAnswer?.correct === true,
            }))}
            disabled={updateQuestionMutation.isLoading}
            onClick={onClickCorrect}
            title="Answer is correct"
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
            </svg>
          </button>
        </div>
        }
      </div>
      <div className="text-gray-700">
        <h5 className="text-sm font-medium">Answers</h5>
        <ul className="list-disc pl-3 text-gray-700 font-normal">
          {answers.map(answer => (
            <li key={answer.id}>{answer.answer}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
