import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { TutorCalendarProvider } from "../../contexts/TutorCalendar";
import { CalendarProvider } from "../../contexts/Calendar";
import TutorCalendar from "../../components/TutorCalendar";
import TutorAvailability from "../../components/TutorAvailability";
import { CALENDAR_WEEKLY_VIEW } from "../../constants";
import ScreenerStudentAssessment from "../ScreenerStudentAssessment";
import StudentToday from "../StudentToday";
import TutorTimeOff from "../TutorTimeOff";
import { TableProvider } from "../common/Table";
import { SparkCookieProvider } from "../common/SparkCookie";
import StudentProgress from "../StudentProgress";
import Student from "../Student";
import StudentNotes from "../StudentNotes";
import TutorStudentList from "../TutorStudentDashboard/TutorStudentList";
import TutorStudentDashboard from "../TutorStudentDashboard";
import { RedirectToDashboard } from "./RedirectToDashboard";

export const SHOW_SUB_REQUESTS_COOKIE_KEY = "calendar_show_sub_requests";
const REACT_QUERY_STALE_TIME = 20 * 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: REACT_QUERY_STALE_TIME,
    },
  },
});
/*
 * This might seem hacky, but we try to fix an issue where the High Frequency Words modal wasn't
 * being updated automatically when a word was marked as taught. The drawer is rendered in two
 * different places by two different js bundles (TutorApp and
 * StudentHighFrequencyWordsMasteryDrawerTrigger), so we need a way to tell <QueryClientProvider />
 * to use the same queryClient instance so changes are propagated correctly everywhere.
 */
window.__SPARK_TUTOR_APP_QUERY_CLIENT = window.__SPARK_TUTOR_APP_QUERY_CLIENT = queryClient;

const TutorApp = (props) => {
  const {
    allowDeletion,
    approvalThreshold,
    fullName,
    missingAvailabilityName,
    shouldShowMissingAvailabilityPrompt,
    subBoardEnabled,
    timeZoneName,
    timeZoneOffset,
    tutorMaxHoursPerDay,
  } = props;

  return (
    <StrictMode>
      <SparkCookieProvider>
        <QueryClientProvider client={window.__SPARK_TUTOR_APP_QUERY_CLIENT}>
          <ReactQueryDevtools />
          <BrowserRouter>
            <Routes>
              <Route
                path="/calendar"
                element={
                  <CalendarProvider defaultView={CALENDAR_WEEKLY_VIEW}>
                    <TutorCalendarProvider>
                      <TutorCalendar
                        timeZoneName={timeZoneName}
                        timeZoneOffset={timeZoneOffset}
                        subBoardEnabled={subBoardEnabled}
                        approvalThreshold={approvalThreshold}
                      />
                    </TutorCalendarProvider>
                  </CalendarProvider>
                }
              />
              <Route
                path="/time_off"
                element={
                  <TableProvider>
                    <TutorTimeOff fullName={fullName} timeZoneName={timeZoneName} />
                  </TableProvider>
                }
              />
              <Route
                path="/tutor_availabilities"
                element={
                  <TutorAvailability
                    timeZoneName={timeZoneName}
                    timeZoneOffset={timeZoneOffset}
                    tutorMaxHoursPerDay={tutorMaxHoursPerDay}
                  />
                }
              />
              <Route
                path="/dashboard"
                element={<TutorStudentDashboard fullName={fullName} timeZoneName={timeZoneName} />}
              >
                <Route
                  path="all"
                  element={
                    <TutorStudentList
                      all={true}
                      missingAvailabilityName={missingAvailabilityName}
                      shouldShowMissingAvailabilityPrompt={shouldShowMissingAvailabilityPrompt}
                    />
                  }
                />
                <Route
                  index
                  element={
                    <TutorStudentList
                      all={false}
                      missingAvailabilityName={missingAvailabilityName}
                      shouldShowMissingAvailabilityPrompt={shouldShowMissingAvailabilityPrompt}
                    />
                  }
                />
              </Route>
              <Route path="/" element={<RedirectToDashboard />} />
              <Route path="students/:id" element={<Student />}>
                <Route path="progress" element={<StudentProgress />} />
                <Route path="notes" element={<StudentNotes allowDeletion={allowDeletion} />} />
                <Route
                  path="student_assessments/:studentAssessmentId/edit"
                  element={<ScreenerStudentAssessment />}
                />
                <Route index element={<StudentToday />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
        <ToastContainer bodyClassName="text-sm" position="bottom-right" />
      </SparkCookieProvider>
    </StrictMode>
  );
};

export default TutorApp;
