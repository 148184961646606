import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import UtteranceListItem from "./UtteranceListItem";
import { useStudentAssessmentQuery } from "../StudentAssessment";
import { useNextRoundMutation } from "./queries";

const TRANSITION_TO_NEXT_ROUND_DURATION = 0.1;

export default ({ disabled }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const nextRoundMutation = useNextRoundMutation();
  const { currentRound, currentRoundWords, secondsRemaining, width } = studentAssessmentQuery.data || {};
  const utterances = useMemo(() => currentRoundWords || [], [currentRoundWords]);

  return (
    <div className="flex flex-col">
      <p className="mb-6 text-base">
        Round:{" "}
        <AnimatePresence mode="wait">
          <motion.span
            animate={{ y: 0, opacity: 1 }}
            className="inline-block"
            exit={{ y: -6, opacity: 0 }}
            initial={{ y: 6, opacity: 0 }}
            key={currentRound}
            transition={{ duration: TRANSITION_TO_NEXT_ROUND_DURATION }}
          >
            {currentRound}
          </motion.span>
        </AnimatePresence>
      </p>
      <AnimatePresence mode="wait">
        <motion.ul
          animate={{ x: 0, opacity: 1 }}
          className={twMerge(cx("grid gap-2 md:gap-4 mb-9", {
            "grid-cols-2": width === 2,
            "grid-cols-3": width === 3,
            "grid-cols-4": width === 4,
            "grid-cols-5": width === 5,
          }))}
          exit={{ x: -16, opacity: 0 }}
          initial={{ x: 16, opacity: 0 }}
          key={currentRound}
          transition={{ duration: TRANSITION_TO_NEXT_ROUND_DURATION }}
        >
          {utterances.map((word) => (
            <li key={word.id}>
              <UtteranceListItem
                correct={word.correct}
                disabled={disabled}
                incorrectCount={word.incorrectCount}
                isEndWord={word.end}
                label={word.text}
                readingChallengeWordId={word.readingChallengeWordId}
                round={currentRound}
              />
            </li>
          ))}
        </motion.ul>
      </AnimatePresence>
      {secondsRemaining > 0 ?
        <div className="flex justify-end">
          <button
            className="button-secondary flex items-center"
            disabled={disabled || nextRoundMutation.isLoading}
            onClick={nextRoundMutation.mutate}
            type="button"
          >
            Next Round
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-3 h-3 ml-1.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      : null}
    </div>
  );
}
