import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    logoutTimeout: { type: Number, default: 14400000 },
    logoutUrl: String,
  }

  connect() {
    this.refreshInterval = 1000;
    this.logoutTimeoutSeconds = this.logoutTimeoutValue / 1000;
    this.startAutomaticLogoutCountdown();
  }

  disconnect() {
    this.clearAutomaticLogoutTimer();
  } 

  startAutomaticLogoutCountdown() {
    this.automaticLogoutTimer = setInterval(() => {
      this.logoutIfTimeoutReached();
    }, this.refreshInterval);
  }
  
  logoutIfTimeoutReached() {
    if (this.logoutTimeoutSeconds <= 0) {
      window.location.href = this.logoutUrlValue;
    } else {
      this.logoutTimeoutSeconds--;
    }    
  }

  clearAutomaticLogoutTimer() {
    if (this.automaticLogoutTimer) {
      clearInterval(this.automaticLogoutTimer);
    }
  }
}
