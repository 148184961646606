import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import cx from "classnames";
import React, {
  createContext,
  cloneElement,
  forwardRef,
  useContext,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import noop from "lodash";
import { twMerge } from "tailwind-merge"

import {
  TOOLTIP_CLOSE_DELAY_IN_MS,
  TOOLTIP_DEFAULT_SHIFT_PADDING,
  TOOLTIP_DEFAULT_Y_AXIS_OFFSET,
  TOOLTIP_OPEN_DELAY_IN_MS
} from "../../constants";

const TooltipContext = createContext(null);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context === null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export const TooltipTrigger = forwardRef(({ children, ...props }, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setReference, propRef, children?.ref]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props
    })
  );
});

export const TooltipContent = forwardRef(({ children, className, dark, ...props }, propRef) => {
  const { arrowRef, context: floatingContext, onAfterOpenChange, ...context } = useTooltipContext();
  const ref = useMergeRefs([ context.refs.setFloating, propRef ]);

  useEffect(() => {
    onAfterOpenChange(floatingContext.open);
  }, [floatingContext.open, onAfterOpenChange])

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <div
        className={twMerge(cx(
          "rounded-lg text-sm ring-1",
          "px-3.5 py-2.5 z-[900] max-w-[calc(100vw-10px)] min-w-min text-center",
          dark ? "bg-black border-black ring-black text-white" : "bg-white shadow-xl ring-zinc-200 text-zinc-600",
          className,
        ))}
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...props.style,
        }}
        {...context.getFloatingProps(props)}
      >
        {children}
        <FloatingArrow
          className={dark ? "stroke-black" : "stroke-zinc-200"}
          context={floatingContext}
          fill={dark ? "black" : "white"}
          ref={arrowRef}
          strokeWidth={1}
        />
      </div>
    </FloatingPortal>
  );
});

export const Tooltip = ({
  children,
  closeDelay,
  enabled,
  offset: offsetConfig,
  onOpenChange: controlledOnOpenChange,
  onAfterOpenChange = noop,
  open: controlledOpen,
  openDelay,
  placement = "top",
  hide,
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const mainAxisOffset = TOOLTIP_DEFAULT_Y_AXIS_OFFSET + arrowRef.current?.getBoundingClientRect()?.height / 2;
  const floating = useFloating({
    middleware: [
      offset({ mainAxis: mainAxisOffset, ...offsetConfig }),
      flip(),
      shift({ padding: TOOLTIP_DEFAULT_SHIFT_PADDING }),
      arrow({ element: arrowRef }),
    ],
    open: controlledOpen || isOpen,
    placement,
    onOpenChange: controlledOnOpenChange || setIsOpen,
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(floating.context, {
    delay: {
      close: closeDelay,
      open: openDelay,
    },
    enabled,
    handleClose: safePolygon(),
  });
  const role = useRole(floating.context, { role: "tooltip" });
  const interactions = useInteractions([ hover, role ]);
  const value = useMemo(() => ({
    ...floating,
    ...interactions,
    arrowRef,
    onAfterOpenChange,
  }), [floating, interactions, onAfterOpenChange]);
  useEffect(() => {
    if (hide) {
      setIsOpen(false);
    }
  }, [hide]);

  return (
    <TooltipContext.Provider value={value}>
      {children}
    </TooltipContext.Provider>
  );
};

Tooltip.defaultProps = {
  closeDelay: TOOLTIP_CLOSE_DELAY_IN_MS,
  enabled: true,
  openDelay: TOOLTIP_OPEN_DELAY_IN_MS,
};
