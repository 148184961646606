import { useQuery } from "@tanstack/react-query";
import cx from "classnames";
import uniqBy from "lodash/uniqBy";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import CheckCircleIcon from "assets/icons/check-circle-outline.svg";
import DocumentTextMiniIcon from "assets/icons/document-text-mini.svg";
import ExclamationTriangleIcon from "assets/icons/exclamation-triangle-outline.svg";

import { get } from "../../api";
import SkillBreakdownItem from "./SkillBreakdownItem";
import { Q_RETEACH_PLAN_URI } from "../../constants";

const TabSummary = ({
  id,
  meta,
  paths,
  shouldDisplayLettersInSkillBreakdown,
  shouldDisplayQReteachPlan,
}) => {
  const { completed, correctCount, failed, passed, totalCount } = meta;
  const query = useQuery({
    queryKey: ["student-auto-reteach-data", id],
    queryFn: async ({ signal }) => {
      const response = await get(paths.summary, { signal });
      return response.data;
    },
  });
  const { reteachPlan, summary, reteachOverviewOverrideText } = query.data || {};
  const skillsBreakdown = useMemo(() => {
    return summary
      ?.map((summary) => {
        const totalCount = summary.concepts.reduce((acc, concept) => {
          return acc + concept.totalCount;
        }, 0);
        const incorrectCount = summary.concepts.reduce((acc, concept) => {
          return acc + concept.incorrectCount;
        }, 0);
        const concepts = shouldDisplayLettersInSkillBreakdown
          ? [
              {
                name: `Incorrect: ${summary.incorrectAnswers.sort((a, b) => a.localeCompare(b)).join(", ")}`,
              },
              {
                name: `Correct: ${summary.correctAnswers.sort((a, b) => a.localeCompare(b)).join(", ")}`,
              },
            ]
          : summary.concepts;

        return {
          ...summary,
          concepts,
          correctCount: totalCount - incorrectCount,
          incorrectCount,
          totalCount,
        };
      })
      .sort((a, b) => {
        return b.incorrectCount - a.incorrectCount;
      });
  }, [shouldDisplayLettersInSkillBreakdown, summary]);
  const conceptsNeedingAttention = useMemo(() => {
    if (shouldDisplayLettersInSkillBreakdown) {
      return summary
        ?.filter((summary) => {
          return summary.componentName !== "Alphabet Knowledge";
        })
        .flatMap((summary) => {
          return summary.incorrectAnswers;
        })
        .map((answer) => ({
          name: answer,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return skillsBreakdown
      ?.flatMap((breakdown) => {
        return breakdown.concepts;
      })
      .filter((concept) => {
        const correctCount = concept.totalCount - concept.incorrectCount;

        return correctCount / concept.totalCount <= 0.6;
      });
  }, [shouldDisplayLettersInSkillBreakdown, skillsBreakdown, summary]);

  if (!query.data) {
    if (query.isError) {
      return (
        <span className="flex items-center justify-center grow text-red-600 min-h-[200px]">
          Error: {query.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center grow text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  const componentsNeedingAttention = skillsBreakdown.filter((breakdown) => {
    return breakdown.correctCount / breakdown.totalCount <= 0.6;
  });
  const supplementaryMaterials = [
    ...uniqBy(
      reteachPlan
        .flatMap((plan) => {
          return plan.materials;
        })
        .filter((material) => {
          return material.uri !== null && material.label !== null;
        }),
      "uri"
    ),
    ...(shouldDisplayQReteachPlan
      ? [
          {
            label: "Q Reteach Plan",
            uri: Q_RETEACH_PLAN_URI,
          },
        ]
      : []),
  ];

  return (
    <div className="py-6 space-y-8">
      <div
        className={twMerge(
          cx(
            "border rounded-xl flex sm:items-center px-2 sm:px-4 py-6 sm:py-4 divide-x divide-gray-300",
            {
              "bg-orange-50 border-orange-300": failed,
              "bg-green-50 border-green-300": passed || completed,
            }
          )
        )}
        data-testid={`student-assessment-results-drawer-overview-text-${id}`}
      >
        <div
          className={twMerge(
            cx(
              "text-[28px] font-semibold pl-0 sm:pl-2 pr-4 sm:pr-6 space-x-2 flex flex-col sm:flex-row items-center shrink-0",
              {
                "text-orange-400": failed,
                "text-green-500": passed || completed,
              }
            )
          )}
        >
          <div
            className={twMerge(
              cx("w-8 h-8 rounded-full flex items-center justify-center shrink-0", {
                "bg-orange-200": failed,
                "bg-green-200": passed || completed,
              })
            )}
          >
            {failed ? (
              <ExclamationTriangleIcon className="w-6 h-6" />
            ) : passed || completed ? (
              <CheckCircleIcon className="w-6 h-6" />
            ) : null}
          </div>
          <span>
            {correctCount}/{totalCount}
          </span>
        </div>
        <div className="pl-6 sm:py-5 text-gray-700 space-y-2">
          {passed ? (
            <p className="text-base sm:text-lg font-medium">
              <em>
                Your student passed! Note the error areas to review in future lessons or include an
                oral drill as needed.
              </em>
            </p>
          ) : (
            [
              <p className="text-base sm:text-lg font-medium" key="text">
                <em>
                  Your student could use more review. Focus on these areas in upcoming lessons:
                </em>
              </p>,
              <ul className="text-xs sm:text-sm list-disc list-inside space-y-1" key="list">
                {componentsNeedingAttention.length > 0 ? (
                  <li>
                    {componentsNeedingAttention
                      .map((component) => {
                        return component.componentName?.trim();
                      })
                      .filter((value, index, self) => self.indexOf(value) === index)
                      .filter((value) => value && value.length > 0)
                      .join(", ")}
                  </li>
                ) : null}
                {conceptsNeedingAttention.length > 0 ? (
                  <li>
                    {shouldDisplayLettersInSkillBreakdown ? "Letters: " : null}
                    {conceptsNeedingAttention
                      .map((concept) => {
                        return concept.name?.trim();
                      })
                      .filter((value, index, self) => self.indexOf(value) === index)
                      .filter((value) => value && value.length > 0)
                      .join(", ")}
                  </li>
                ) : null}
              </ul>,
            ]
          )}
        </div>
      </div>
      {summary.length > 0 ? (
        <div
          className="max-w-[696px] mx-auto space-y-4"
          data-testid={`student-assessment-results-drawer-skills-breakdown-${id}`}
        >
          <h2 className="text-lg uppercase text-gray-600 font-semibold">Skills Breakdown</h2>
          <ul className="space-y-1">
            {skillsBreakdown.map((breakdown) => (
              <SkillBreakdownItem key={breakdown.componentName} {...breakdown} />
            ))}
          </ul>
        </div>
      ) : null}
      {reteachOverviewOverrideText ? (
        <div
          className="max-w-[696px] mx-auto space-y-4"
          data-testid={`student-assessment-results-drawer-override-text-${id}`}
        >
          <p className="text-base sm:text-lg font-medium">{reteachOverviewOverrideText}</p>
        </div>
      ) : reteachPlan.length > 0 ? (
        <div
          className="max-w-[696px] mx-auto space-y-4"
          data-testid={`student-assessment-results-drawer-reteach-overview-${id}`}
        >
          <h2 className="text-lg uppercase text-gray-600 font-semibold">Reteach Overview</h2>
          {supplementaryMaterials.length > 0 ? (
            <ul className="flex space-x-5 pt-1">
              {supplementaryMaterials.map((material) => (
                <li key={material.uri}>
                  <a
                    className="flex items-center text-sm text-brand-600 font-semibold hover:text-brand-700 hover:underline space-x-0.5"
                    href={material.uri}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <DocumentTextMiniIcon className="w-5 h-5" />
                    <span>{material.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          ) : null}
          <ul className="text-gray-600 divide-y divide-gray-200">
            {reteachPlan.map((plan) => (
              <li className="flex items-center justify-between p-4" key={plan.lessonId}>
                <div className="flex items-center space-x-4">
                  <h3 className="text-base text-gray-600 font-semibold">{plan.lessonName}</h3>
                </div>
                {plan.contentURL ? (
                  <a
                    className="flex items-center text-sm text-brand-600 font-semibold hover:text-brand-700 hover:underline space-x-0.5"
                    href={plan.contentURL}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <DocumentTextMiniIcon className="w-5 h-5" />
                    <span>Slides</span>
                  </a>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default TabSummary;
