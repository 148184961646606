import { useIntersectionObserver } from "@uidotdev/usehooks";
import cx from "classnames";
import noop from "lodash/noop";
import React from "react";
import { twMerge } from "tailwind-merge";

import { readingAssessmentStatusType } from "../../constants";
import { useStudentAssessmentQuery } from "./queries";
import { useStudentAssessmentContext } from "./index";

const { IN_PROGRESS } = readingAssessmentStatusType;

const StudentAssessmentWrapper = ({ children }) => {
  const context = useStudentAssessmentContext();
  const { BeforeContent } = { BeforeContent: noop, ...context.components };
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px"
  });
  const studentAssessmentQuery = useStudentAssessmentQuery({
    enabled: !!entry?.isIntersecting,
  });

  return (
    <div
      data-testid={context["data-testid"]}
      className={twMerge(cx("bg-white rounded-lg border border-l-8 border-zinc-200", {
        "border-l-brand-500": studentAssessmentQuery.data?.status === IN_PROGRESS,
      }))}
      ref={ref}
    >
      <BeforeContent {...context} />
      <div className="flex flex-col px-4 sm:px-10 py-6 lg:pt-10 md:pb-10 space-y-8 sm:space-y-6">
        {children}
      </div>
    </div>
  );
};

export default StudentAssessmentWrapper;
