import { createContext, useContext } from "react";

export const FloatingContext = createContext(null);
export const useFloatingContext = () => {
  const context = useContext(FloatingContext);

  if (context === null) {
    throw new Error("Floating components must be wrapped in <Floating />");
  }

  return context;
};
