import { useMutation } from "@tanstack/react-query";
import isFunction from "lodash/isFunction";
import { useContext } from "react";

import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import {
  useSetStudentAssessmentQueryOnSuccess,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";
import { readingAssessmentItemType } from "../../constants";
import { patch } from "../../api";

const { VOCAB_WORD, WORD } = readingAssessmentItemType;

export const useUpdateWordMutation = () => {
  const paths = useContext(RailsPathsContext);
  const { onUpdateWordEndCallback } = useStudentAssessmentContext();

  return useMutation({
    mutationFn: async ({ correct, id, ended }) => {
      const response = await patch(paths.updateWord, {
        correct,
        ended,
        word_id: id,
      });

      return response.data;
    },
    onSuccess: (data) => {
      if (isFunction(onUpdateWordEndCallback)) {
        onUpdateWordEndCallback(data);
      }
    },
  });
};

export const useUpdateTaskMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async ({ completed, id }) => {
      const response = await patch(paths.completeTask, {
        completed,
        task_id: id,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useUpdateQuestionMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async ({ correct, questionId, taskId }) => {
      const response = await patch(paths.completeQuestion, {
        correct,
        question_id: questionId,
        task_id: taskId,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useStudentAssessmentWordCountQuery = () => {
  return useStudentAssessmentQuery({
    select: (data) => {
      const words =
        data.readingAssessmentItems
          .sort((a, b) => {
            return a.sequenceNumber - b.sequenceNumber;
          })
          .filter((item) => {
            return [VOCAB_WORD, WORD].includes(item.assessmentItemType);
          }) || [];
      const endWordIndex = words.findIndex((word) => word.id === data.endItemId);
      const attempted = endWordIndex + 1;
      const errored = words.filter((word, i) => {
        return data.studentIncorrectReadingAssessmentItemIds.includes(word.id) && i <= endWordIndex;
      }).length;

      return {
        attempted,
        correct: attempted - errored,
        errored,
      };
    },
  });
};
