import React from "react";
import { Modal, ModalClose, ModalContent } from "../common/Modal";

const AssessmentResetCompletedModal = ({ isConfirmationModalOpen, setIsConfirmationModalOpen }) => {
  const reload = () => {
    window.location.reload();
  }

  return (
    <Modal
      open={isConfirmationModalOpen}
      onOpenChange={setIsConfirmationModalOpen}
    >
    <ModalContent data-testid={`student-assessment-reset-completed`}>
        <div className="flex sm:items-start pr-6">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 text-green-600 sm:mx-0 sm:h-10 sm:w-10">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </div>
          <div className="flex flex-col">
            <div className="mt-0 text-center sm:ml-4 sm:text-left">
              <h3 className="text-base font-medium leading-6 text-gray-900 py-2 mb-2">Assessment Reset Completed</h3>
              <div>
                <p className="text-sm text-gray-500 font-normal">You have successfully reset this assessment!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:mt-4 sm:flex w-full">
          <span className="mx-auto">
            <ModalClose className="w-[220px] bg-green-600 ring-green-800 text-white enabled:hover:bg-green-700" onClick={reload}>Got it!</ModalClose>
          </span>
        </div>
      </ModalContent>
    </Modal>
  );
}

export default AssessmentResetCompletedModal;
