import {useMutation, useQuery} from "@tanstack/react-query";
import { toast } from "react-toastify";
import {useRef} from "react";

import {axiosDelete, get} from "../../api";
import {useParams} from "react-router-dom";

const REACT_QUERY_STALE_TIME = 5000;

export const useStudentAssessmentResultsQuery = (assessmentId, studentAssessmentableId, isBaseline, student) => {
  const { id: studentId } = useParams();
  student = student !== undefined ? student : studentId
  const studentAssessmentableType = isBaseline ? "StudentBaseline" : "StudentProtocol";
  return useQuery({
    queryKey: ["student_assessment_results", studentId, assessmentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${student}/student_assessment_results/${assessmentId}`, {
        signal, params: {
          student_assessmentable_id: studentAssessmentableId,
          student_assessmentable_type: studentAssessmentableType
        }
      });

      return response.data;
    },
    keepPreviousData: false,
    staleTime: REACT_QUERY_STALE_TIME,
  });
};

export const useDeleteStudentAssessment = () => {
  const toastIdRef = useRef();

  return useMutation({
    mutationFn: async ({path, formData: data}) => {
      const response = await axiosDelete(path, { data });
      return response.data;
    },
    onError: () => {
      toast.update(toastIdRef.current, {
        autoClose: 3200,
        isLoading: false,
        render: "There was a problem resetting the assessment. Please check your selections and try again.",
        type: "error",
      })
    }
  });
};

export const useStudentAssessmentResultQuery = (studentAssessmentResultId) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-assessment-result", studentAssessmentResultId],
    queryFn: async () => {
      const response = await get(`/students/${studentId}/student_assessment_results/${studentAssessmentResultId}/presence`);

      return response.data;
    },
  });
};
