import cx from "classnames";
import pick from "lodash/pick";
import uniqBy from "lodash/uniqBy";
import React, { useMemo, useState } from "react";

import { useUpdateTaskMutation } from "./queries";
import {
  useStudentAssessmentContext,
  useStudentAssessmentQuery
} from "../StudentAssessment";
import {
  readingAssessmentItemType,
  readingAssessmentTaskType
} from "../../constants";
import QuestionList from "./QuestionList";

const readNumberToExpandedContentMap = {
  1: "Complete a 1 minute cold read challenge. The student will read the passage as accurately and as quickly as they can. Set a words-read-per-minute goal (WRPM) for read 2. (Alternatively, you may set a goal around expression or accuracy).",
  2: "Complete a 1 minute challenge. Celebrate the student's reading, and set a goal for read #3.",
  3: "Complete a 1 minute challenge. Compare day 1 WRPM to day 3 WRPM. Celebrate growth, sharing feedback around expression, accuracy, and/or rate.",
};
const { ONE_MINUTE_CHALLENGE, QUESTIONS } = readingAssessmentTaskType;

export default ({ questions, readNumber, id, task, taskType }) => {
  const { gradeLevels } = useStudentAssessmentContext();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateTaskMutation = useUpdateTaskMutation();
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    genre,
    readingAssessmentItems,
    studentReadingAssessmentTasksIds,
    studentGradeLevel,
  } = studentAssessmentQuery.data || {};
  const vocabWords = useMemo(() => {
    const words = readingAssessmentItems.filter(item => {
      return item.assessmentItemType === readingAssessmentItemType.VOCAB_WORD;
    });

    return uniqBy(words, "vocabWordFullText");
  }, [readingAssessmentItems]);
  const readingPassageTitle = useMemo(() => {
    return readingAssessmentItems.filter(item => {
      return item.assessmentItemType === readingAssessmentItemType.TITLE;
    }).map(word => word.text).join(" ");
  }, [readingAssessmentItems])
  const onChange = (event) => {
    updateTaskMutation.mutate({ completed: event.target.checked, id });
  };
  const onToggleExpanded = () => {
    setIsExpanded(prevState => !prevState);
  };
  const checked = studentReadingAssessmentTasksIds.includes(id);
  const shouldDoEchoRead = useMemo(() => {
    const echoReadGradeLevels = pick(gradeLevels, ["kindergarten", "first_grade", "second_grade"]);

    return Object.keys(echoReadGradeLevels).includes(studentGradeLevel);
  }, [gradeLevels, studentGradeLevel]);
  const subtitle = useMemo(() => {
    if (task === "1 Min Cold Read" || taskType === ONE_MINUTE_CHALLENGE) {
      return "1 Min Reading Challenge";
    } else if (task === "Echo or Partner Reading") {
      return "Practice Fluent Reading";
    } else if (taskType === QUESTIONS) {
      return "Finish passage";
    } else {
      return task;
    }
  }, [task, taskType]);
  const title = useMemo(() => {
    if (task === "Introduce Text") {
      return `${readingPassageTitle}${genre ? ` (${genre})` : null}`;
    } else if (task === "Key Vocabulary") {
      return vocabWords.map(word => word.vocabWordFullText).join(", ");
    } else if (task === "1 Min Cold Read") {
      return "Cold Read";
    } else if (taskType === ONE_MINUTE_CHALLENGE) {
      return readNumber === 3 ? "Final read" : "Complete Reading Challenge";
    } else if (task === "Echo or Partner Reading") {
      return shouldDoEchoRead ? "Echo Reading" : "Partner Reading";
    } else if (taskType === QUESTIONS) {
      return "Check Comprehension";
    }
  }, [genre, readingPassageTitle, readNumber, shouldDoEchoRead, task, taskType, vocabWords]);
  const content = useMemo(() => {
    if (task === "Key Vocabulary") {
      return (
        <dl>
          {vocabWords.map(word => [
            <dt className="font-medium text-gray-700 pt-2.5 pb-0.5" key={`word-${word.id}`}>{word.vocabWordFullText}</dt>,
            <dd className="text-sm text-gray-500 pb-2.5" key={`definition-${word.definition}`}>{word.definition}</dd>,
          ])}
        </dl>
      );
    } else if (task === "Echo or Partner Reading") {
      return (
        <p className="text-sm text-gray-700">
          {shouldDoEchoRead
            ? "Practice fluent reading with the student using Echo Reading: read a sentence, then have the student repeat it. Do it for each sentence."
            : "Practice fluent reading with the student using Partner Reading: alternate reading sentences, then switch who starts."}
        </p>
      );
    } else if (taskType === ONE_MINUTE_CHALLENGE) {
      return (
        <p className="text-sm text-gray-700">
          {readNumberToExpandedContentMap[readNumber]}
        </p>
      );
    } else if (taskType === QUESTIONS) {
      return (
        <QuestionList questions={questions} taskId={id} checkboxes={false}/>
      );
    }
  }, [id, questions, readNumber, shouldDoEchoRead, task, taskType, vocabWords]);

  return (
    <div>
      <div className="flex">
        <input
          checked={checked}
          className="hidden peer"
          disabled={[ONE_MINUTE_CHALLENGE, QUESTIONS].includes(taskType) || updateTaskMutation.isLoading}
          id={`reading-assessment-task-${id}`}
          onChange={onChange}
          type="checkbox"
        />
        <label
          className="bg-gray-200 peer-checked:bg-green-500 text-gray-200 peer-checked:text-white cursor-pointer w-6 h-6 rounded-full mr-4 flex items-center justify-center peer-disabled:opacity-50 shrink-0 mt-2"
          htmlFor={`reading-assessment-task-${id}`}
          tabIndex={0}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </svg>
        </label>
        <label
          className={cx("flex flex-col grow", {
            "cursor-pointer": content !== undefined,
          })}
          htmlFor={`reading-assessment-task-button-${id}`}
        >
          <span className="text-gray-700 text-xs uppercase">{subtitle}</span>
          <span className="font-medium">{title}</span>
        </label>
        {content !== undefined ?
          <button id={`reading-assessment-task-button-${id}`} onClick={onToggleExpanded} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={cx("w-5 h-5 transition-all", {
                "rotate-180": isExpanded,
              })
            }>
              <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
            </svg>
          </button>
        : null}
      </div>
      {isExpanded ?
        <div className="pl-10 pt-4 pb-2">{content}</div>
      : null}
    </div>
  );
}
