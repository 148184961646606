import difference from "lodash/difference";
import range from "lodash/range";
import React, { useMemo, useState } from "react";
import Select from "react-select";

import FormSelectOption from "./FormSelectOption";
import { formatTimeToMeridiem, minutesCountToTime,  timeToMinutesCount } from "../../utils";
import { TUTOR_AVAILABILITY_START_HOUR_EASTERN, TUTOR_AVAILABILITY_END_HOUR_EASTERN } from "../../constants";

const getAvailableTimesInMinutesCount = (intervals, timeZoneOffset, offset = 0) => {
  const allIntervals = range(
    (TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset) * 60,
    (TUTOR_AVAILABILITY_END_HOUR_EASTERN + timeZoneOffset) * 60,
    15
  );
  const unavailableIntervals = intervals.map(availability => {
    const startTimeInMinutes = timeToMinutesCount(availability.start_time);
    const endTimeInMinutes = timeToMinutesCount(availability.end_time);

    return range(startTimeInMinutes + offset, endTimeInMinutes - offset, 15);
  });

  return difference(allIntervals, ...unavailableIntervals);
};

export default ({ availabilities, highDemandIntervals, onAddAvailability, timeZoneOffset }) => {
  const [selectedStartTime, setSelectedStartTime] = useState({ label: null, value: null });
  const availableStartTimesInMinutes = useMemo(() => {
    return getAvailableTimesInMinutesCount(availabilities, timeZoneOffset);
  }, [availabilities, timeZoneOffset]);
  const availableEndTimesInMinutes = useMemo(() => {
    return getAvailableTimesInMinutesCount(availabilities, timeZoneOffset, 15);
  }, [availabilities, timeZoneOffset]);
  const endTimeStartIndex = useMemo(() => {
    return selectedStartTime.value
      ? availableEndTimesInMinutes.indexOf(timeToMinutesCount(selectedStartTime.value))
      : -1;
  }, [availableEndTimesInMinutes, selectedStartTime.value]);
  const endTimeEndIndex = useMemo(() => {
    return availableEndTimesInMinutes.findIndex((startTimeInMinutes, i) => {
      if (i > endTimeStartIndex) {
        if (availableEndTimesInMinutes[i + 1]) {
          return availableEndTimesInMinutes[i + 1] - startTimeInMinutes > 15;
        } else {
          return availableEndTimesInMinutes[availableEndTimesInMinutes.length - 1];
        }
      }
    });
  }, [availableEndTimesInMinutes, endTimeStartIndex]);
  const endTimeOptions = availableEndTimesInMinutes
    .slice(endTimeStartIndex + 1, endTimeEndIndex + 1)
    .map(endTimeInMinutes => {
      const time = minutesCountToTime(endTimeInMinutes);

      return {
        label: formatTimeToMeridiem(time),
        value: time,
      };
    });
  const startTimeOptions = availableStartTimesInMinutes.map(startTimeInMinutes => {
    const time = minutesCountToTime(startTimeInMinutes);

    return {
      label: formatTimeToMeridiem(time),
      value: time,
    };
  });
  const onChangeStartTime = (data) => {
    setSelectedStartTime(data);
  };
  const onChangeEndTime = (data) => {
    onAddAvailability(selectedStartTime.value, data.value);
  };

  return (
    <div className="flex items-center mb-3">
      <Select
        className="w-[124px] md:w-[134px] mr-2 md:mr-4 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isSearchable={false}
        onChange={onChangeStartTime}
        options={startTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 })
        }}
        value={selectedStartTime}
      />
      <span className="text-gray-500 text-sm md:text-base">to</span>
      <Select
        className="w-[124px] md:w-[134px] ml-2 md:ml-4 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isDisabled={selectedStartTime.value === null}
        isSearchable={false}
        onChange={onChangeEndTime}
        options={endTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 })
        }}
        value={{
          label: "",
          value: null,
        }}
      />
    </div>
  )
}
