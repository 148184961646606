import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import FloatingContent from "../Floating/Content";
import { useFloatingContext } from "../Floating/context";

const DrawerContent = ({ children, "data-testid": dataTestid }) => {
  const { context: floatingContext } = useFloatingContext();

  return (
    <AnimatePresence>
      {floatingContext.open ? (
        <FloatingContent data-testid={dataTestid}>
          <motion.div
            className="w-full lg:w-[calc(100%-80px)] max-w-[920px] bg-white min-h-screen absolute right-0 top-0 bottom-0 ml-10 overflow-auto shadow-xl"
            initial={{ translateX: "100%" }}
            animate={{ translateX: "0%" }}
            exit={{ translateX: "100%" }}
            transition={{
              // attempting to mimic Tailwind's transitions
              duration: 0.4,
              ease: [0.4, 0, 0.2, 1],
              type: "tween",
            }}
          >
            {children}
          </motion.div>
        </FloatingContent>
      ) : null}
    </AnimatePresence>
  );
};

export default DrawerContent;
