import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import {
  useStartAssessmentMutation,
  useStudentAssessmentQuery,
  useUpdateSecondsRemainingMutation,
} from "./queries";
import { readingAssessmentStatusType } from "../../constants";
import ResetButton from "./ResetButton";

export default ({ className, countdownTimer }) => {
  const [shouldResume, setShouldResume] = useState(false);
  const [isResetConfirmationModalOpen, setIsResetConfirmationModalOpen] = useState(false);
  const startAssessmentMutation = useStartAssessmentMutation();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const updateSecondsRemainingMutation = useUpdateSecondsRemainingMutation();
  const onStartCountdownTimer = () => {
    startAssessmentMutation.mutate(null, {
      onSuccess: () => {
        countdownTimer.start();
        setShouldResume(true);
      },
    });
  };
  const onResumeCountdownTimer = () => {
    countdownTimer.start();
    setShouldResume(true);
  };
  const onPauseCountdownTimer = () => {
    updateSecondsRemainingMutation.mutate(countdownTimer.remaining);
    countdownTimer.pause();
    setShouldResume(false);
  };

  useEffect(() => {
    if (isResetConfirmationModalOpen) {
      countdownTimer.pause();
    } else if (shouldResume) {
      countdownTimer.start();
    }
    /*
     * We just need `pause` and `start` from countdownTimer, but ESLint is not satisfied only with
     * those, it wants the whole things: countdownTimer, but countdownTimer is not stable (since we're
     * getting it as a prop from parent) and causes an infinite re-render of the component. A proper
     * fix would be to make it stable, but until then, we disable the rule.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetConfirmationModalOpen, shouldResume]);

  return studentAssessmentQuery.data?.status === readingAssessmentStatusType.IN_PROGRESS ? (
    <div className={twMerge("flex items-center justify-between", className)}>
      <button
        className="button-secondary ring-brand-600 grow max-w-[200px] min-w-[180px] relative text-center text-3xl text-brand-500 font-medium h-[44px] flex items-center justify-center"
        onClick={countdownTimer.isPaused ? onResumeCountdownTimer : onPauseCountdownTimer}
        disabled={updateSecondsRemainingMutation.isLoading}
        type="button"
      >
        {countdownTimer.minutes}:{countdownTimer.seconds}
        <span className="absolute left-0 top-1/2 -translate-y-1/2 px-2.5 text-zinc-400 hover:text-zinc-500">
          {countdownTimer.isPaused ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
              data-one-minute-challenge-target="playIcon"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
              data-one-minute-challenge-target="pauseIcon"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM9 8.25a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75H9zm5.25 0a.75.75 0 00-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 00.75-.75V9a.75.75 0 00-.75-.75h-.75z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </span>
      </button>
      <ResetButton
        countdownTimer={countdownTimer}
        isConfirmationModalOpen={isResetConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsResetConfirmationModalOpen}
        setShouldTimerResume={setShouldResume}
      />
    </div>
  ) : (
    <button
      className="button-primary flex items-center justify-center w-full max-w-[200px] text-sm"
      disabled={startAssessmentMutation.isLoading}
      onClick={onStartCountdownTimer}
      type="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 25"
        stroke="currentColor"
        strokeWidth="2"
        className="w-6 h-6 mr-2"
      >
        <path d="M12 22.5C16.4183 22.5 20 18.9183 20 14.5C20 10.0817 16.4183 6.5 12 6.5C7.58172 6.5 4 10.0817 4 14.5C4 18.9183 7.58172 22.5 12 22.5Z" />
        <path
          d="M14 2.5H10M12 2.5V6.5M17.5 8.5L19 7M12 11.5V14.5H9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Start timer
    </button>
  );
};
