import React from "react";

import {
  StudentAssessmentHeader,
  useStudentAssessmentQuery
} from "../StudentAssessment";
import { readNumberToTitleMap } from "./";

export default () => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { readNumber } = studentAssessmentQuery.data || {};

  return (
    <StudentAssessmentHeader
      assessmentName="Fluency"
      assessmentSubtitle={`Read #${readNumber}: ${readNumberToTitleMap[readNumber]}`}
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
        </svg>
      }
      tooltipContent={
        <>
          <h5 className="font-medium mb-1.5">Fluency Overview</h5>
          <p>Research indicates that fluency is one of the critical building blocks of reading, because fluency development is directly related to comprehension. In order for students to fluently recognize and read words, they must be able to effortlessly connect sounds to their corresponding letter(s). Carefully designed opportunities for practice leads to students becoming increasingly automatic with words, leading to fluent reading.</p>
        </>
      }
    />
  );
}
