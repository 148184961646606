import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";

const ResetButton = ({ onClick, onCancel, differentiator, studentName }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState(undefined);

  const inputRef = useRef();

  const onChangeInputValue = (event) => {
    setInputValue(event.target.value);
  };

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      setIsConfirmationModalOpen(false);
      onClick(formData);
    },
    [setIsConfirmationModalOpen, onClick]
  );

  const onChangeReason = ({ value }) => {
    setSelectValue(value);
  };

  const formValid =
    (selectValue === "Other" && inputValue.length > 0) || (selectValue !== "Other" && selectValue);

  const reasons = [
    "Entered data for wrong student",
    "Tech issues",
    "Incorrect protocol placement",
    "School change",
    "Training purpose",
    "Other",
  ].map((reason) => {
    return {
      label: reason,
      value: reason,
    };
  });

  useEffect(() => {
    if (selectValue === "Other") {
      inputRef.current.focus();
    }
  }, [selectValue]);

  return (
    <Modal open={isConfirmationModalOpen} onOpenChange={setIsConfirmationModalOpen}>
      <ModalTrigger>
        <button
          data-testid={`button-student-assessment-reset-content-${differentiator}`}
          className="button-secondary flex items-center ring-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-4 h-4 mr-1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          Reset Assessment
        </button>
      </ModalTrigger>
      <ModalContent data-testid={`student-assessment-reset-content-${differentiator}`}>
        <form onSubmit={onSubmit}>
          <div className="flex sm:items-start pr-6">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-orange-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div className="flex flex-col">
              <div className="mt-0 text-center sm:ml-4 sm:text-left">
                <h3 className="text-base font-medium leading-6 text-gray-900 py-2 mb-2">
                  Assessment Reset Warning
                </h3>
                <div>
                  <p className="text-sm text-gray-500 font-normal">
                    This will reset all records for this assessment attempt. If you still want to
                    reset, make sure to select a reason. ({studentName}){" "}
                  </p>
                </div>
              </div>
              <Select
                name="reset_reason"
                placeholder="Select reason for resetting"
                className="mt-3 pr-4 text-center sm:ml-4 sm:text-left w-full"
                isSearchable={false}
                onChange={onChangeReason}
                options={reasons}
              />
              {selectValue === "Other" ? (
                <label className="flex flex-col mt-3 pr-4 text-center sm:ml-4 sm:text-left w-full">
                  <p className="text-sm text-gray-500 font-normal">Enter reason</p>
                  <input
                    type="text"
                    name="other_reason"
                    ref={inputRef}
                    maxLength={100}
                    placeholder="Type reason here (limit 100 characters)"
                    className="rounded-lg border-gray-300 mt-2"
                    onChange={onChangeInputValue}
                    value={inputValue}
                  />
                </label>
              ) : null}
            </div>
          </div>
          <div className="pt-6 space-y-2 sm:space-y-0 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              className="button-tertiary sm:ml-3 w-full sm:w-auto"
              type="submit"
              disabled={!formValid}
            >
              Reset Assessment
            </button>
            <ModalClose onClick={onCancel}>Cancel</ModalClose>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ResetButton;
