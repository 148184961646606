import cx from "classnames";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import LessonPlan from "../LessonPlan";
import RehearsalBanner from "../common/RehearsalBanner";
import CompleteLessonModal from "../StudentProgress/CompleteLessonModal";
import { useCurrentContentQuery } from "./queries";
import { useStartAssessmentMutation } from "../StudentProgress/queries";
import AutoReteachFocusBanner from "../AutoReteachFocusBanner";
import BellOutline from "assets/icons/bell-outline.svg";

const CurrentContent = () => {
  const [isDisabledWithoutUpdate, setIsDisabledWithoutUpdate] = useState(false);
  const { id: studentId } = useParams();
  const currentContentQuery = useCurrentContentQuery();
  const {
    contentDisplayName,
    contentURL,
    displayReteachBanner,
    id,
    isAssessment,
    isLesson,
    linkToEditStudentAssessment,
    mostRecentAssessmentIsInProgress,
    protocolType,
    studentAssessmentableId,
    studentAssessmentableType,
    studentInstructionalPlanId,
    studentLessonId,
    studentLessonIsCompleted,
    studentProtocolId,
  } = currentContentQuery.data || {};
  const startAssessmentMutation = useStartAssessmentMutation({
    assessmentId: id,
    studentAssessmentableId,
    studentAssessmentableType,
    studentInstructionalPlanId,
  });
  const onStartAssessment = (event) => {
    startAssessmentMutation.mutate();
    event.preventDefault();
  };

  if (!currentContentQuery.data) {
    if (currentContentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {currentContentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <div className="bg-zinc-50 p-4 md:p-6 xl:p-10 space-y-4">
      <RehearsalBanner />
      {isLesson ? (
        [
          <div className="flex items-center justify-between text-sm space-x-2" key="header">
            <div className="flex space-x-2 min-w-0">
              <h3 className="truncate min-w-0">{contentDisplayName}</h3>
              <a
                className="text-brand-500 hover:text-brand-800 hover:underline"
                href={contentURL}
                rel="noreferrer"
                target="_blank"
              >
                Content
              </a>
            </div>
            <div>
              <CompleteLessonModal
                studentProtocolId={studentProtocolId}
                lessonId={id}
                isBaseline={isAssessment}
                onComplete={() => setIsDisabledWithoutUpdate(true)}
              >
                <button
                  className={twMerge(
                    cx(
                      "button-secondary inline-flex items-center justify-center py-3 pl-8 md:pl-4 md:w-36 relative",
                      {
                        "disabled:bg-green-100 disabled:text-green-800 enabled:hover:bg-green-100":
                          studentLessonIsCompleted || isDisabledWithoutUpdate,
                      }
                    )
                  )}
                  data-testid="complete-lesson-modal-trigger"
                  disabled={studentLessonIsCompleted || isDisabledWithoutUpdate}
                  type="button"
                >
                  {studentLessonIsCompleted || isDisabledWithoutUpdate
                    ? [
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 absolute left-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          key="svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>,
                        <span key="label">Completed</span>,
                      ]
                    : "Complete Lesson"}
                </button>
              </CompleteLessonModal>
            </div>
          </div>,
          displayReteachBanner ? (
            <AutoReteachFocusBanner
              studentId={studentId}
              studentLessonId={studentLessonId}
              key="reteach-banner"
            />
          ) : null,
          <LessonPlan studentId={studentId} key="lesson-cards" />,
        ]
      ) : isAssessment ? (
        <div className="divide-y space-y-6">
          <div className="flex flex-row items-center justify-between text-sm space-x-2">
            <h3 className="truncate min-w-0">{contentDisplayName}</h3>
            {mostRecentAssessmentIsInProgress ? (
              <a
                className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
                data-testid="student-continue-assessment"
                href={linkToEditStudentAssessment}
              >
                Continue Assessment
              </a>
            ) : (
              <form onSubmit={onStartAssessment}>
                <button
                  className="button-primary min-w-[9rem] whitespace-nowrap shrink-0"
                  data-testid="student-start-assessment"
                  disabled={startAssessmentMutation.isLoading}
                  type="submit"
                >
                  Start Assessment
                </button>
              </form>
            )}
          </div>
          {protocolType === "Screener" ? (
            <div
              className="flex items-center space-x-2 pt-7"
              data-testid="reminder-continue-lesson-banner"
            >
              <BellOutline className="w-6 h-6" />
              <p className="text-sm text-zinc-600">
                <strong>Just a reminder:</strong> After the assessment, you&apos;ll continue with
                the student&apos;s lesson.{" "}
              </p>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="text-sm text-yellow-800 border border-yellow-300 bg-yellow-50 rounded-md py-3 px-4">
          This student has completed all content.
        </div>
      )}
    </div>
  );
};

export default CurrentContent;
