import React from "react";
import { twMerge } from "tailwind-merge";

import { assignmentTypes } from "../../constants";

const assignmentTypeToNiceName = {
  [assignmentTypes.PRIMARY]: "Permanent",
  [assignmentTypes.SUB_LONG_TERM]: "Substitute",
  [assignmentTypes.SUB_SHORT_TERM]: "Substitute",
};

export default ({ assignmentType }) => (
  <span className={twMerge(
    "inline-flex items-center rounded-full px-2.5 py-0.5 text-[11px] font-medium ml-1.5 whitespace-nowrap",
    assignmentType === assignmentTypes.PRIMARY ? "bg-blue-100 text-blue-800" : "bg-purple-100 text-purple-800",
  )}>
    {assignmentTypeToNiceName[assignmentType]}
  </span>
);
