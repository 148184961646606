import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDays, format, parse, parseISO } from "date-fns";
import { useContext, useMemo } from "react";

import { get, post } from "../../api";
import { DEFAULT_DATE_FORMAT, SHORT_DATE } from "../../constants";
import { CurrentDateContext, CurrentViewContext } from "../../contexts/Calendar";

const REACT_QUERY_STALE_TIME = 5000;

const useDateForParams = () => {
  const { currentDate } = useContext(CurrentDateContext);
  const endDate = format(addDays(currentDate, 6), DEFAULT_DATE_FORMAT);
  const startDate = format(currentDate, DEFAULT_DATE_FORMAT);

  return useMemo(() => ({ endDate, startDate }), [endDate, startDate]);
};

export const useTimeBlocksQuery = () => {
  const { currentDate } = useContext(CurrentDateContext);
  const { endDate, startDate } = useDateForParams();

  return useQuery({
    queryKey: ["events", currentDate],
    queryFn: async ({ signal }) => {
      const { data } = await get("/calendar.json",{
        params: {
          end_date: endDate,
          start_date: startDate,
        },
        signal,
      });
      const { calendarEvents, maxDate, minDate, tutorTimeOffs } = data;

      return {
        calendarEvents: calendarEvents.map(({ endTime, startTime, tutorStudentUuid, ...rest }) => ({
          ...rest,
          endTime: parseISO(endTime),
          id: `${tutorStudentUuid}-${startTime}-${endTime}`,
          startTime: parseISO(startTime),
          tutorStudentUuid,
        })),
        maxDate,
        minDate,
        tutorTimeOffs,
      };
    },
    keepPreviousData: true,
    staleTime: REACT_QUERY_STALE_TIME,
  });
};

export const useSuggestionsQuery = () => {
  return useQuery({
    queryKey: ["suggestions"],
    queryFn: async ({ signal }) => {
      const { data } = await get("/tutor_students.json", { signal });

      return data.map(({ endDate, endTime, startDate, startTime, ...rest }) => ({
        ...rest,
        endDate: parse(endDate, SHORT_DATE, new Date()),
        endTime: parseISO(endTime),
        startDate: parse(startDate, SHORT_DATE, new Date()),
        startTime: parseISO(startTime),
      }));
    },
    keepPreviousData: true,
    staleTime: REACT_QUERY_STALE_TIME,
  });
};

export const useSubSessionRequestsQuery = () => {
  const { currentDate } = useContext(CurrentDateContext);
  const { showSubSessionRequests } = useContext(CurrentViewContext);
  const { endDate, startDate } = useDateForParams();

  return useQuery({
    enabled: showSubSessionRequests,
    queryKey: ["subRequests", currentDate],
    queryFn: async ({ signal }) => {
      const { data } = await get("/sub_session_requests.json", {
        params: {
          sub_session: {
            end_date: endDate,
            start_date: startDate,
          },
        },
        signal,
      });

      return data.map(({ endTime, startTime, ...rest }) => ({
        ...rest,
        endTime: parseISO(endTime),
        startTime: parseISO(startTime),
      }));
    },
    keepPreviousData: true,
    staleTime: REACT_QUERY_STALE_TIME,
  });
};

export const useClaimSubSessionRequestMutation = ({
  programEnrollmentId,
  startTime,
}) => {
  const { currentDate } = useContext(CurrentDateContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => await post("/tutor_students/claim_sub_request", {
      program_enrollment_id: programEnrollmentId,
      start_date: startTime
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["suggestions"] });
      queryClient.invalidateQueries({ queryKey: ["events", currentDate] });
      queryClient.invalidateQueries({ queryKey: ["subRequests", currentDate] });
    },
  });
}

export const useCancelSubSessionRequestMutation = ({
  startTime,
  tutorStudentUuid,
}) => {
  const { currentDate } = useContext(CurrentDateContext);
  const queryClient = useQueryClient();
  const date = format(startTime, DEFAULT_DATE_FORMAT);

  return useMutation({
    mutationFn: async () => await post("/sub_session_requests/cancel_request", {
      sub_session_request: {
        days_and_tutor_students: {
          [date]: [tutorStudentUuid]
        },
      }
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["suggestions"] });
      queryClient.invalidateQueries({ queryKey: ["events", currentDate] });
      queryClient.invalidateQueries({ queryKey: ["subRequests", currentDate] });
    },
  });
}
