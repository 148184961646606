import { Controller } from "@hotwired/stimulus";

import { getValueBasedOnCurrentTwBreakpoint } from "../utils";

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static targets = ["root"];
  static values = {
    offset: Object,
  };

  rootTargetConnected(target) {
    const offset = getValueBasedOnCurrentTwBreakpoint(this.offsetValue);
    const top = document.documentElement.scrollTop + target.getBoundingClientRect().top - offset;

    requestAnimationFrame(() => {
      window.scroll({ top, behavior: "smooth" });
    });
  }
}
