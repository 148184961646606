import React from "react";

import { DrawerClose } from "../common/Drawer";
import { Tab, TabList, TabPanel, Tabs } from "../common/Tabs";
import Header from "./Header";
import TabSummary from "./TabSummary";
import TabAssessment from "./TabAssessment";

const StudentAssessmentResultsDrawer = (props) => (
  <div className="px-6 sm:px-10 pt-6 pb-14 space-y-7 min-h-full flex flex-col" key="drawer-content">
    <Header id={props.id} {...props.meta} />
    {props.shouldDisplaySummary ? (
      <Tabs className="grow">
        <TabList>
          <Tab data-testid={`student-assessment-results-drawer-tab-summary-${props.id}`}>
            Summary
          </Tab>
          <Tab data-testid={`student-assessment-results-drawer-tab-assessment-${props.id}`}>
            Assessment
          </Tab>
        </TabList>
        <TabPanel className="grow flex flex-col">
          <TabSummary {...props} />
        </TabPanel>
        <TabPanel className="grow flex flex-col">
          <TabAssessment {...props} />
        </TabPanel>
      </Tabs>
    ) : (
      <TabAssessment {...props} />
    )}
    <div className="flex justify-center">
      <DrawerClose
        className="button-primary w-full max-w-[380px] text-sm"
        data-testid="back-to-progress-button"
      >
        Back to Progress
      </DrawerClose>
    </div>
  </div>
);

export default StudentAssessmentResultsDrawer;
