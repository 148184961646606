import { useQuery } from "@tanstack/react-query";

import { get } from "../../api";
import { useTable } from "../common/Table";

export const useStudentsQuery = (all) => {
  const { sortBy: sort, sortDirection: direction } = useTable();

  return useQuery({
    queryKey: ["students", sort, direction, all],
    queryFn: async ({ signal }) => {
      const { data } = await get("/dashboard", {
        params: {
          all,
          direction,
          sort
        },
        signal,
      });

      return {
        students: data.students || [],
      };
    },
    staleTime: Infinity,
  });
};
