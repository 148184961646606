import React, { useMemo, useState } from "react";

import { PagyProvider } from "../Pagy";
import TableContext from "./context";

const TableProvider = ({ children }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const value = useMemo(() => ({
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  }), [
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  ]);

  return (
    <TableContext.Provider value={value}>
      <PagyProvider>
        {children}
      </PagyProvider>
    </TableContext.Provider>
  );
};

export default TableProvider;
