import React from "react";

import {
  StudentAssessmentContinueLessonButton,
  useCompleteAssessmentMutation,
  useStudentAssessmentContext,
  useStudentAssessmentQuery,
} from "../StudentAssessment";

const CompleteConditionalAssessmentForm = () => {
  const completeAssessmentMutation = useCompleteAssessmentMutation();
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { openCelebratoryModal, setOpen, targetGoal } = useStudentAssessmentContext();
  const { correctWordsCount } = studentAssessmentQuery.data || {};
  const onSubmit = (event) => {
    completeAssessmentMutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
        if (correctWordsCount >= targetGoal) {
          openCelebratoryModal();
        }
      },
    });
    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <StudentAssessmentContinueLessonButton disabled={completeAssessmentMutation.isLoading} />
    </form>
  );
};

export default CompleteConditionalAssessmentForm;
