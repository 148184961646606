import React, { useCallback, useMemo } from "react";

const RADIUS = 50;

const CircularProgressBar = ({ barWidth, className, maxValue, minValue, value }) => {
  const strokeWidth = barWidth * 2;
  const getDashStyle = useCallback((dashRatio) => {
    const diameter = Math.PI * 2 * (RADIUS - strokeWidth / 2);
    const gapLength = (1 - dashRatio) * diameter;

    return {
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${gapLength}px`,
    };
  }, [strokeWidth]);
  const pathRatio = useMemo(() => {
    const boundedValue = Math.min(Math.max(value, minValue), maxValue);

    return (boundedValue - minValue) / (maxValue - minValue);
  }, [maxValue, minValue, value]);
  const outerStrokePath = useMemo(() => `
    M ${RADIUS},${RADIUS}
    m 0,-${RADIUS}
    a ${RADIUS},${RADIUS} 0 1 1 0,${2 * RADIUS}
    a ${RADIUS},${RADIUS} 0 1 1 0,-${2 * RADIUS}
  `, []);
  const innerStrokePath = useMemo(() => `
    M ${RADIUS},${RADIUS}
    m 0,-${RADIUS - strokeWidth}
    a ${RADIUS - 2 * strokeWidth},${RADIUS - 2 * strokeWidth} 0 1 1 0,${2 * (RADIUS - strokeWidth)}
    a ${RADIUS - 2 * strokeWidth},${RADIUS - 2 * strokeWidth} 0 1 1 0,-${2 * (RADIUS - strokeWidth)}
  `, [strokeWidth]);
  const barPath = useMemo(() => `
    M ${RADIUS},${RADIUS}
    m 0,-${RADIUS - strokeWidth/2}
    a ${RADIUS - strokeWidth},${RADIUS - strokeWidth} 0 1 1 0,${2 * RADIUS - strokeWidth}
    a ${RADIUS - strokeWidth},${RADIUS - strokeWidth} 0 1 1 0,-${2 * RADIUS - strokeWidth}
  `, [strokeWidth]);

  return (
    <svg className={className} viewBox={`0 0 ${2 * RADIUS} ${2 * RADIUS}`}>
      <path
        className="stroke stroke-gray-200"
        style={getDashStyle(1)}
        d={outerStrokePath}
        strokeWidth={2}
        fillOpacity={0}
      />
      <path
        className="stroke stroke-gray-200"
        style={getDashStyle(1)}
        d={innerStrokePath}
        strokeWidth={2}
        fillOpacity={0}
      />
      <path
        style={getDashStyle(pathRatio * 1)}
        d={barPath}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fillOpacity={0}
        strokeLinejoin="bevel"
      />
    </svg>
  );
}

CircularProgressBar.defaultProps = {
  barWidth: 8,
  minValue: 0,
  maxValue: 1,
}

export default CircularProgressBar;
