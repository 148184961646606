import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import ConfirmationModalForMutation from "../common/ConfirmationModalForMutation";
import { useSubmitLessonMutation } from "./queries";
import ArrowPathSolid from "assets/icons/arrow-path-solid.svg";

const ReteachLessonModal = ({ isBaseline, lessonId, status, studentProtocolId }) => {
  const submitLessonMutation = useSubmitLessonMutation({ isBaseline, lessonId, studentProtocolId });

  return (
    <ConfirmationModalForMutation
      key="confirmation-modal"
      icon={
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
          <ArrowPathSolid className="w-6 h-6 text-yellow-600" />
        </div>
      }
      message="Does this Lesson need to be retaught?"
      mutation={submitLessonMutation}
      mutateParams="reteach_needed"
      submitButtonLabel="Reteach"
      title="Reteach Lesson"
    >
      <button
        className={twMerge(
          cx("button-secondary rounded-r-none", {
            "disabled:bg-yellow-100 disabled:text-yellow-800 disabled:ring-yellow-300 enabled:hover:bg-yellow-100":
              status === "reteach_needed",
          })
        )}
        data-testid="reteach-lesson-modal-trigger-button"
        disabled={status === "reteach_needed"}
      >
        Reteach
      </button>
    </ConfirmationModalForMutation>
  );
};

export default ReteachLessonModal;
