import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const GRAPH_COLORS = ["bg-brand-300", "bg-blue-300", "bg-black"];

export default function Item({ title, progress, max, index }) {
  const width = useMemo(() => {
    return `${Math.min(100, (progress / max) * 100)}%`;
  }, [progress, max]);

  return (
    <div className="flex flex-row items-center relative mb-1 w-full" key={`progressItem-${title}`}>
      <div className="w-[110px] mr-2">{title}</div>
      <div className="h-2 relative grow">
        <div className="bg-gray-100 rounded-full absolute top-0 bottom-0 left-0 right-0"></div>
        <div
          className={twMerge(
            "rounded-full absolute top-0 bottom-0",
            GRAPH_COLORS[index % GRAPH_COLORS.length]
          )}
          style={{ width }}
        ></div>
      </div>
    </div>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
