import { useContext } from "react";

import { StudentAssessmentContext } from "./";

export default () => {
  const context = useContext(StudentAssessmentContext);

  if (context === null) {
    throw new Error("StudentAssessment components must be wrapped in <StudentAssessment />");
  }

  return context;
};
