import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  /**
   * NOTE: For the sake of simplicity, this controller is relatively app specific. In the
   * future, it may be worth making a more generic, reusable version. To do so, all of
   * the targets, URLs, query parameters, etc. would need to be dynamically passed in to
   * instances of the controller either through the Values API or as additional HTML data
   * attributes.
   */
  static targets = [
    "schoolSelect",
    "schoolCalendarSelect",
    "schoolCalendarClassroomSelect",
    "schoolProgramTermSelect",
    "programTermTutoringBlocksSelect"
  ]

  populateSchools(event) {
    get("/admin/select_options/schools", {
      query: {
        school_system_id: event.target.selectedOptions[0].value,
        target: this.schoolSelectTarget.id
      },
      responseKind: "turbo-stream"
    })

    this.resetSelectOptions(event)
  }

  populateSchoolCalendars(event) {
    get("/admin/select_options/school_calendars", {
      query: {
        school_id: event.target.selectedOptions[0].value,
        target: this.schoolCalendarSelectTarget.id
      },
      responseKind: "turbo-stream"
    })

    this.resetSelectOptions(event)
  }

  populateSchoolCalendarClassrooms(event) {
    get("/admin/select_options/classrooms", {
      query: {
        school_calendar_id: event.target.selectedOptions[0].value,
        target: this.schoolCalendarClassroomSelectTarget.id
      },
      responseKind: "turbo-stream"
    })
  }

  populateSchoolProgramTerms(event) {
    get("/admin/select_options/program_terms", {
      query: {
        school_id: event.target.selectedOptions[0].value,
        target: this.schoolProgramTermSelectTarget.id
      },
      responseKind: "turbo-stream"
    })
  }

  populateProgramTermTutoringBlocks(event) {
    get("/admin/select_options/tutoring_blocks", {
      query: {
        program_term_id: event.target.selectedOptions[0].value,
        target: this.programTermTutoringBlocksSelectTarget.id
      },
      responseKind: "turbo-stream"
    })
  }

  resetSelectOptions(event) {
    const selectOptionFieldsToResetDataset =
      event.target.closest("[data-select-option-fields-to-reset]")?.dataset

    if (selectOptionFieldsToResetDataset) {
      const selectOptionFieldsToReset =
        JSON.parse(selectOptionFieldsToResetDataset.selectOptionFieldsToReset) || []

      // Reset the select fields. Setting the length to 1 means that only the
      // prompt value is left as an available select field option.
      selectOptionFieldsToReset.forEach(target => this.targets.find(target).length = 1)
    }
  }
}
