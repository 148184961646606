import cx from "classnames";
import { cloneElement } from "react";

import { useExpandableContext } from "./context";

export default ({ children, ...props }) => {
  const { setExpanded, ...rest } = useExpandableContext();
  const onClick = () => {
    setExpanded(prevState => !prevState);
  };
  const childrenWithProps = children({ setExpanded, ...rest });

  return cloneElement(childrenWithProps, {
    ...props,
    className: cx("cursor-pointer", childrenWithProps.props.className),
    onClick,
  });
};
