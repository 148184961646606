import { useMutation } from "@tanstack/react-query";
import isFunction from "lodash/isFunction";
import { useContext } from "react";

import { patch } from "../../api";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import {
  useSetStudentAssessmentQueryOnSuccess,
  useStudentAssessmentContext,
} from "../StudentAssessment";

export const useUpdateWordCorrectMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async ({ correct, round, readingChallengeWordId }) => {
      const response = await patch(paths.updateWordCorrect, {
        correct,
        reading_challenge_word_id: readingChallengeWordId,
        round,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useUpdateWordEndMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();
  const { onUpdateWordEndCallback } = useStudentAssessmentContext();

  return useMutation({
    mutationFn: async ({ correct, ended, round, readingChallengeWordId }) => {
      const response = await patch(paths.updateWordEnd, {
        correct,
        ended,
        reading_challenge_word_id: readingChallengeWordId,
        round,
      });

      return response.data;
    },
    onSuccess: (data) => {
      onSuccess(data);
      if (isFunction(onUpdateWordEndCallback)) {
        onUpdateWordEndCallback(data);
      }
    },
  });
};

export const useIncrementWordIncorrectMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async (readingChallengeWordId) => {
      const response = await patch(paths.incrementWordIncorrect, {
        reading_challenge_word_id: readingChallengeWordId,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useDecrementWordIncorrectMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async (readingChallengeWordId) => {
      const response = await patch(paths.decrementWordIncorrect, {
        reading_challenge_word_id: readingChallengeWordId,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useNextRoundMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async () => {
      const response = await patch(paths.updateCurrentRound);

      return response.data;
    },
    onSuccess,
  });
};
