import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "backdrop", "menu", "closeButton"]

  show() {
    enter(this.backdropTarget)
    enter(this.closeButtonTarget)
    enter(this.menuTarget)
    this.containerTarget.classList.remove("hidden")
  }

  hide() {
    Promise.all([
      leave(this.backdropTarget),
      leave(this.closeButtonTarget),
      leave(this.menuTarget)
    ]).then(() => {
      this.containerTarget.classList.add("hidden")
    })
  }
}
