import React from "react";

import { cancellationReason, tutorStudentStatus } from "../../constants";

export default ({ reason, status }) => {
  return status === tutorStudentStatus.CANCELLED ?
    <div className="bg-white border border-zinc-200 flex items-center rounded-full text-[9px] sm:text-[10px] text-zinc-600 font-medium uppercase whitespace-nowrap">
      <div className={`${cancellationReason.TIME_OFF ? "rounded-full" : "rounded-l-full"} bg-zinc-200 px-1 sm:px-1.5 py-0.5`}>
        {reason === cancellationReason.LAST_MINUTE ? "Cancellation" : reason === cancellationReason.TIME_OFF ? "Time off" : "No program"}
      </div>
        {reason !== cancellationReason.TIME_OFF ?
          <div className="px-1 sm:px-1.5 py-0.5">
            {reason === cancellationReason.LAST_MINUTE ? "Paid" : "Unpaid"}
          </div>
        : null }
    </div>
  : null;
};
