import { autoUpdate, offset, shift, useFloating } from "@floating-ui/react-dom";
import cx from "classnames";
import { formatDuration } from "date-fns";
import range from "lodash/range";
import React, { useEffect, useRef } from "react";
import RangeSlider from "react-range-slider-input";

import { TUTOR_MAX_HOURS_PER_DAY, TUTOR_MIN_HOURS_PER_DAY } from "../../constants";

export default ({ maxHoursPerDay, onChange }) => {
  const wrapperRef = useRef();
  const { refs, floatingStyles, update } = useFloating({
    elements: {
      reference: document.querySelector(".range-slider__thumb[data-upper]"),
    },
    middleware: [
      offset(6),
      shift({ boundary: wrapperRef.current }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const onInput = ([, value]) => {
    onChange(value);
  };

  useEffect(() => {
    update();
  }, [maxHoursPerDay, update]);

  return (
    <div className="pt-6 md:pt-8 lg:pt-10 pb-14 md:pb-10 flex flex-col md:flex-row md:items-center" ref={wrapperRef}>
      <div className="flex-1 lg:grow lg:max-w-[500px] mb-6 md:mb-0">
        <h2 className="text-base md:text-lg mb-3 lg:mb-2">Maximum Tutoring Hours Per Day</h2>
        <p className="text-xs md:text-sm text-slate-600">Set the slider to the maximum number of hours you want to tutor per day. Tutors must work at least 1 hour/day. We&apos;ll use your availability plus your maximum desired hours to create your tutoring schedule.</p>
      </div>
      <div className="flex-1 md:pl-10 lg:pl-20">
        <div className="flex flex-col">
          <div className="flex justify-between px-[1px]">
            {[0, ...range(TUTOR_MIN_HOURS_PER_DAY, TUTOR_MAX_HOURS_PER_DAY), TUTOR_MAX_HOURS_PER_DAY].map(hour => (
              <p className="text-xs text-center text-gray-400 w-[9px]" key={hour}>{hour}</p>
            ))}
          </div>
          <div className="flex justify-between items-end mb-[3px] px-[1px]">
            {[0, 0.5, ...range(TUTOR_MIN_HOURS_PER_DAY, TUTOR_MAX_HOURS_PER_DAY, 0.5), TUTOR_MAX_HOURS_PER_DAY].map(hour => (
              <div
                className={cx("w-[9px] relative", {
                  "h-[8px]": hour % 1 === 0,
                  "h-[6px]": hour % 1 === 0.5,
                })}
                key={hour}
              >
                <div className="absolute top-0 bottom-0 w-[1px] bg-gray-300 left-1/2 -translate-x-1/2" />
              </div>
            ))}
          </div>
        </div>
        <RangeSlider
          className={cx(
            "[&>div:nth-of-type(1)]:w-0",
            "before:content-[''] before:bg-brand-500 before:rounded-l-full",
            "before:absolute before:w-[calc(100%/6+2px)] before:left-[1px] before:top-[1px] before:bottom-[1px]"
          )}
          max={TUTOR_MAX_HOURS_PER_DAY}
          min={0}
          onInput={onInput}
          rangeSlideDisabled
          step={0.5}
          thumbsDisabled={[true, false]}
          value={[TUTOR_MIN_HOURS_PER_DAY, maxHoursPerDay]}
        />
      </div>
      <div className="bg-white rounded-md shadow-lg px-5 py-2.5 text-xs font-semibold text-gray-500" ref={refs.setFloating} style={floatingStyles}>
        {formatDuration({
          hours: parseInt(maxHoursPerDay, 10),
          minutes: (maxHoursPerDay % 1) * 60
        })}
      </div>
    </div>
  );
}
