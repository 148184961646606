import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = ["content", "drawer"]

  show() {
    this.drawerTarget.classList.remove("pointer-events-none", "opacity-0");
    this.drawerTarget.classList.add("pointer-events-auto", "opacity-100");
    this.contentTarget.classList.remove("translate-x-full");
    this.contentTarget.classList.add("translate-x-0");
    document.body.classList.add("overflow-hidden")
    document.addEventListener("keydown", this.keydownHandler.bind(this))
  }

  hide() {
    this.drawerTarget.classList.remove("pointer-events-auto", "opacity-100");
    this.drawerTarget.classList.add("pointer-events-none", "opacity-0");
    this.contentTarget.classList.remove("translate-x-0");
    this.contentTarget.classList.add("translate-x-full");
    document.body.classList.remove("overflow-hidden")
    document.removeEventListener("keydown", this.keydownHandler)
  }

  keydownHandler(event) {
    if (event.key === "Escape") {
      this.hide();
    }
  }
}
