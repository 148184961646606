import React, { useMemo, useState } from "react";

import { ExpandableContext } from "./context";

const ExpandableProvider = ({ children, open: controlledExpanded, onOpenChange: setControlledExpanded }) => {
  const [uncontrolledExpanded, setUncontrolledExpanded] = useState(false);
  const expanded = controlledExpanded ?? uncontrolledExpanded;
  const setExpanded = setControlledExpanded ?? setUncontrolledExpanded;
  const value = useMemo(() => ({
    expanded, setExpanded
  }), [expanded, setExpanded]);

  return (
    <ExpandableContext.Provider value={value}>
      {children}
    </ExpandableContext.Provider>
  );
}

export default ExpandableProvider;
