import cx from "classnames";
import { format } from "date-fns";
import React from "react";

import { MERIDIEM_TIME_FORMAT_ZERO_PADDED, SHORT_DAY_OF_WEEK_MONTH_AND_DAY, tutorStudentStatus } from "../../constants";
import CancellationPill from "./CancellationPill";
import InvitedTimeBlockForm from "./InvitedTimeBlockForm";
import AssignmentBadge from "./AssignmentBadge";
import SubSessionRequestCancel from "./SubSessionRequestCancel";

const { CANCELLED, INVITED, SUB_REQUESTED } = tutorStudentStatus;

export default ({ assignmentType, endTime, gradeLevel, cancellationReason, school, startTime, status, title, tutorStudentUuid }) => (
  <div className="min-w-[320px] w-full max-w-full md:max-w-[360px]" data-testid="time-block-details">
    <div className="px-5 pt-3 pb-5">
      <div className="border-b pb-4">
        <div className="flex items-center mb-2">
          <h4
            className={cx("font-medium text-zinc-700 truncate", { "line-through": status === CANCELLED })}
            data-heap-redact-text
          >
            {title}
          </h4>
          <AssignmentBadge assignmentType={assignmentType} />
        </div>
        <ul className="flex items-center text-xs text-zinc-500">
          <li className="flex items-center mr-5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-3.5 h-3.5 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{format(startTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)} - {format(endTime, MERIDIEM_TIME_FORMAT_ZERO_PADDED)}</span>
          </li>
          <li className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5 mr-1">
              <path fillRule="evenodd" d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z" clipRule="evenodd" />
            </svg>
            <span>{format(startTime, SHORT_DAY_OF_WEEK_MONTH_AND_DAY)}</span>
          </li>
        </ul>
      </div>
      {status === CANCELLED ?
        <div className="flex pt-4">
          <CancellationPill reason={cancellationReason} status={status} />
        </div>
      :
        <dl className="text-xs text-zinc-500 pt-4">
          <dt className="font-semibold mb-1">School</dt>
          <dd className="mb-3" data-heap-redact-text>{school}</dd>
          <dt className="font-semibold mb-1">Grade level</dt>
          <dd className="mb-1" data-heap-redact-text>{gradeLevel}</dd>
        </dl>
      }
    </div>
    {status === INVITED ?
      <div className="rounded-b-lg bg-zinc-50 border-t p-3 space-x-2">
        <InvitedTimeBlockForm studentName={title} tutorStudentUuid={tutorStudentUuid} />
      </div>
    : null}
    {status === SUB_REQUESTED ?
      <div className="rounded-b-lg bg-zinc-50 border-t p-3 space-x-2">
        <SubSessionRequestCancel startTime={startTime} tutorStudentUuid={tutorStudentUuid} />
      </div>
    : null}
  </div>
);
