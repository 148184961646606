import uniqueId from "lodash/uniqueId"
import get from "lodash/get";
import React, { useState } from "react";

import { TUTOR_AVAILABILITY_START_HOUR_EASTERN, TUTOR_AVAILABILITY_END_HOUR_EASTERN } from "../../constants";
import AddNewAvailabilityControls from "./AddNewAvailabilityControls";
import FormAvailabilityControls from "./FormAvailabilityControls";
import { timeToMinutesCount } from "../../utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";

export default ({ availabilities, highDemandIntervals, setAvailabilities, timeZoneOffset }) => {
  const availabilitiesCount = availabilities.filter(availability => !availability._destroy).length;
  const [shouldShowAddNewAvailabilityControls, setShouldShowAddNewAvailabilityControls] = useState(false);
  const onOpenAddAvailabilityForm = () => {
    setShouldShowAddNewAvailabilityControls(true);
  };
  const onAddAvailability = (startTime, endTime) => {
    setAvailabilities(prevAvailabilities => {
      return [
        ...prevAvailabilities, {
          uuid: uniqueId("localtime"),
          end_time: endTime,
          start_time: startTime,
        }];
    });
    setShouldShowAddNewAvailabilityControls(false);
  };
  const onRemoveAvailability = (uuid) => () => {
    setAvailabilities(prevAvailabilities => prevAvailabilities.map(availability => {
      return availability.uuid === uuid ? { ...availability, _destroy: true } : availability;
    }));
  };
  const onChangeStartTime = (uuid) => ({ value }) => {
    setAvailabilities(prevAvailabilities => {
      return prevAvailabilities.map(availability => {
        return (availability.uuid === uuid) ? { ...availability, start_time: value } : availability;
      })
    });
  };
  const onChangeEndTime = (uuid) => ({ value }) => {
    setAvailabilities(prevAvailabilities => {
      return prevAvailabilities.map(availability => {
        return (availability.uuid === uuid) ? { ...availability, end_time: value } : availability;
      })
    });
  };

  return (
    <div className="flex-1 lg:pl-20">
      <div className="border-t pt-8 lg:pt-0 lg:border lg:rounded-lg">
        <div className="lg:border-b lg:px-6 lg:py-4 flex items-center justify-between">
          <h3 className="text-sm md:text-base font-semibold text-gray-700">Monday-Friday Availability</h3>
          <div className="flex items-center lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-yellow-300 w-3.5 h-3.5 mr-0.5">
              <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
            </svg>
            <p className="flex items-center text-zinc-500 text-xs">
              <em>- High demand times</em>
              <Tooltip>
                <TooltipTrigger>
                  <button
                    className="flex items-center justify-center w-4 h-4 ml-1 text-zinc-500/80 hover:text-zinc-500"
                    type="button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-full h-full">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                    </svg>
                  </button>
                </TooltipTrigger>
                <TooltipContent>You have a higher chance of being scheduled during these times</TooltipContent>
              </Tooltip>
            </p>
          </div>
        </div>
        <div className="lg:px-6 pt-4 pb-2 md:pb-5">
          <p className="text-xs md:text-sm text-slate-600">Enter the times you are available to tutor consistently every day Monday-Friday. Then, scroll down to set the maximum number of hours you&apos;d like to work per day.</p>
          <ul className="pt-4">
            {availabilities.map((availability, i) => {
              const nextStartTime = get(availabilities[i + 1], "start_time");
              const prevEndTime = get(availabilities[i - 1], "end_time");
              const maxEndTimeInMinutes = nextStartTime
                ? timeToMinutesCount(nextStartTime)
                : timeToMinutesCount(`${TUTOR_AVAILABILITY_END_HOUR_EASTERN + timeZoneOffset}:00`);
              const minStartTimeInMinutes = prevEndTime
                ? timeToMinutesCount(prevEndTime)
                : timeToMinutesCount(`${TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset}:00`);

              return (
                <li className="flex items-center" key={availability.uuid}>
                  <FormAvailabilityControls
                    endDate={availability.end_time}
                    highDemandIntervals={highDemandIntervals}
                    maxEndTimeInMinutes={maxEndTimeInMinutes}
                    minStartTimeInMinutes={minStartTimeInMinutes}
                    onChangeEndTime={onChangeEndTime(availability.uuid)}
                    onChangeStartTime={onChangeStartTime(availability.uuid)}
                    onOpenAddAvailabilityForm={onOpenAddAvailabilityForm}
                    onRemoveAvailability={onRemoveAvailability(availability.uuid)}
                    shouldShowAddButton={!shouldShowAddNewAvailabilityControls && availabilitiesCount - 1 === i}
                    startDate={availability.start_time}
                  />
                </li>
              )}
            )}
            {shouldShowAddNewAvailabilityControls || availabilitiesCount === 0 ?
              <li className="flex items-center">
                <AddNewAvailabilityControls
                  availabilities={availabilities}
                  highDemandIntervals={highDemandIntervals}
                  onAddAvailability={onAddAvailability}
                  timeZoneOffset={timeZoneOffset}
                />
              </li>
            : null}
          </ul>
        </div>
      </div>
    </div>
  )
}
