import React, { createContext, useMemo, useState } from "react";

import DateRangePicker from "../common/DateRangePicker";
import TimeOffList from "./TimeOffList";

export const FilterByDateContext = createContext({});

const TutorTimeOff = ({ fullName, timeZoneName }) => {
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const value = useMemo(() => ({
    filterEndDate,
    filterStartDate,
  }), [filterEndDate, filterStartDate]);

  return (
    <FilterByDateContext.Provider value={value}>
      <div className="max-w-7xl mx-auto w-full px-4 md:px-6 xl:px-8 py-6">
        <div className="mb-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                Time off requests
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Time offs requested by {fullName}
              </p>
            </div>
            <p className="flex items-centerZ text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
              <em>All times are {timeZoneName}</em>
            </p>
          </div>
        </div>
        <DateRangePicker
          className="pb-6"
          data-testid="tutor-time-off-filter"
          endDate={filterEndDate}
          onChangeEndDate={setFilterEndDate}
          onChangeStartDate={setFilterStartDate}
          showClearButton
          startDate={filterStartDate}
        />
        <TimeOffList />
      </div>
    </FilterByDateContext.Provider>
  );
}

export default TutorTimeOff;
