import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useRef } from "react";

import AssessmentResults from "./AssessmentResults";

const StudentAssessmentsResults = ({
  assessmentId,
  studentAssessmentable,
  studentId,
  isBaseline,
}) => {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: Infinity,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient.current}>
      <AssessmentResults
        assessmentId={assessmentId}
        studentAssessmentable={studentAssessmentable}
        studentId={studentId}
        isBaseline={isBaseline}
      />
    </QueryClientProvider>
  );
};

export default StudentAssessmentsResults;
