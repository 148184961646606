import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "modalBackground", "actionButton"]
  static values = {
    openOnPageLoad: { type: Boolean, default: false },
    disableEscKeyExit: { type: Boolean, default: false },
    disableClickAwayExit: { type: Boolean, default: false },
    actionConfirmed: { type: Boolean, default: false },
  }

  connect() {
    document.addEventListener("keydown", this.keydownHandler.bind(this))
    if (this.openOnPageLoadValue) {
      this.open();
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.keydownHandler.bind(this))
  }

  keydownHandler(event) {
    // Close when the escape key is hit or when the user
    if (event.key === "Escape" && !this.disableEscKeyExitValue) {
      this.close()
    }
  }

  closeBackground(e) {
    if (!this.disableClickAwayExitValue && e.target === this.modalBackgroundTarget) {
      this.close()
    }
  }

  open() {
    this.modalTarget.classList.remove("hidden")
  }

  close() {
    this.modalTarget.classList.add("hidden")
  }

  // It may make more sense to create a separate ConfirmationModalController
  // and include these functions within that controller. Bonus points if we
  // can inherit from this controller to resuse its base functionality and
  // reduce code duplication.
  confirm(event) {
    if (!this.actionConfirmedValue) {
      event.preventDefault();
      this.open()
    }
  }

  confirmAction() {
    this.close()
    this.actionConfirmedValue = true
    this.actionButtonTarget.click()
  }
}
