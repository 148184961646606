import React, { useEffect, useState, useMemo } from "react";
import Xmark from "assets/icons/x-mark-mini.svg";
import CalendarOutline from "assets/icons/calendar-outline.svg";
import DocumentTextMini from "assets/icons/document-text-mini.svg";
import {
  useCurrentContentQuery,
  useCurrentContentDismissTodayMutation,
} from "../CurrentContent/queries";
import { differenceInBusinessDays, differenceInDays, parseISO } from "date-fns";

export default function ReshearsalBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const { data } = useCurrentContentQuery();
  const toggleDismiss = useCurrentContentDismissTodayMutation();

  const { programEnrollmentStartDate, dressRehearsalScriptLink, rehearsalBannerDismissedAt } =
    data || {};

  const today = useMemo(() => {
    return parseISO(new Date().toISOString());
  }, []);

  const isWithinRehearsalWindow = useMemo(() => {
    if (programEnrollmentStartDate) {
      const programEnrollmentStartDateISO = parseISO(programEnrollmentStartDate);
      const diff = differenceInBusinessDays(today, programEnrollmentStartDateISO);

      return diff <= 5 && diff >= 0;
    }
  }, [programEnrollmentStartDate, today]);

  useEffect(() => {
    setShowBanner(false);

    if (isWithinRehearsalWindow) {
      if (rehearsalBannerDismissedAt) {
        const rehearsalBannerDismissedAtISO = parseISO(rehearsalBannerDismissedAt);
        const diff = differenceInDays(today, rehearsalBannerDismissedAtISO);

        if (diff !== 0) {
          setShowBanner(true);
        }
      } else {
        setShowBanner(true);
      }
    }
  }, [rehearsalBannerDismissedAt, isWithinRehearsalWindow, today]);

  const onDismiss = (dismiss) => {
    toggleDismiss.mutate(
      { dismiss },
      {
        onSuccess: ({ rehearsalBannerDismissedAt }) => {
          setShowBanner(!!rehearsalBannerDismissedAt);
        },
      }
    );
  };

  if (!isWithinRehearsalWindow) return false;

  if (showBanner) {
    return (
      <div
        role="banner"
        data-testid="dress-rehearsal-banner"
        className="relative bg-brand-50 border-brand-500 border-[1px] rounded-lg lg:px-16 px-12 pt-9 lg:pb-16 pb-9 mb-1"
      >
        <button
          className="absolute top-[15px] right-[18px] text-zinc-700 text-xs flex items-center"
          data-testid="dismiss-dress-rehearsal"
          onClick={() => onDismiss(true)}
          type="button"
          title="Dismiss"
        >
          <Xmark height="15" width="15" className="mr-[2px]" />
          Close
        </button>

        <div className="absolute left-[16px] top-[32px] flex items-center justify-center rounded-2xl w-8 h-8 text-slate-950 bg-slate-200">
          {/* NOTE: when you replace this icon with the UI Toolkit SVGs, use the date_check icon */}
          <CalendarOutline height="24" width="24" />
        </div>

        <header className="mb-4">
          <h2 className="text-zinc-700 font-medium mb-4">Dress Rehearsal Week Schedule Reminder</h2>
          <a
            data-testid="rehearsal-script-link"
            className="flex items-center text-brand-600 text-sm font-semibold"
            href={dressRehearsalScriptLink}
            rel="noreferrer"
            target="_blank"
          >
            <DocumentTextMini width="20" height="20" className="mr-1" />
            Dress Rehearsal Week Script
          </a>
        </header>

        <p className="text-zinc-700 mb-8">
          Welcome to dress rehearsal week! Remember to stay on track for the week!
        </p>

        <ul className="flex xl:flex-row flex-col xl:gap-12 gap-8 justify-evenly">
          <li>
            <div className="bg-fuchsia-500 text-sm font-bold text-white rounded-2xl w-[52px] h-[24px] text-center leading-[22px] mb-3">
              Day 1
            </div>
            <h3 className="font-medium text-sm mb-3 text-zinc-700">Build Rapport</h3>
            <ul className="pl-4 list-disc text-sm text-zinc-700">
              <li className="mb-1">Welcome student</li>
              <li>Set expectations</li>
            </ul>
          </li>

          <li>
            <div className="bg-fuchsia-500 text-sm font-bold text-white rounded-2xl w-[52px] h-[24px] text-center leading-[22px] mb-3">
              Day 2
            </div>
            <h3 className="font-medium text-sm mb-3 text-zinc-700">Baseline Assessment #1</h3>
            <ul className="pl-4 list-disc text-sm text-zinc-700">
              <li className="mb-1">Review expectations</li>
              <li>Administer IDI</li>
            </ul>
          </li>

          <li>
            <div className="bg-fuchsia-500 text-sm font-bold text-white rounded-2xl w-[52px] h-[24px] text-center leading-[22px] mb-3">
              Day 3
            </div>
            <h3 className="font-medium text-sm mb-3 text-zinc-700">Baseline Assessment #2</h3>
            <ul className="pl-4 list-disc text-sm text-zinc-700">
              <li className="mb-1">Review expectations</li>
              <li>Administer HFW</li>
            </ul>
          </li>

          <li>
            <div className="bg-fuchsia-500 text-sm font-bold text-white rounded-2xl w-[52px] h-[24px] text-center leading-[22px] mb-3">
              Day 4
            </div>
            <h3 className="font-medium text-sm mb-3 text-zinc-700">Baseline Assessment #3</h3>
            <ul className="pl-4 list-disc text-sm text-zinc-700">
              <li className="mb-1">Review expectations</li>
              <li>Administer BOP Screener</li>
            </ul>
          </li>

          <li>
            <div className="bg-fuchsia-500 text-sm font-bold text-white rounded-2xl w-[52px] h-[24px] text-center leading-[22px] mb-3">
              Day 5
            </div>
            <h3 className="font-medium text-sm mb-3 text-zinc-700">Set Goals</h3>
            <ul className="pl-4 list-disc text-sm text-zinc-700">
              <li className="mb-1">Review expectations</li>
              <li className="mb-1">Celebrate</li>
              <li>Go over student’s journey</li>
            </ul>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div className="mb-1 text-right">
        <button
          className="text-brand-600 font-semibold text-sm"
          data-testid="reset-dress-rehearsal"
          onClick={() => onDismiss(false)}
          type="button"
          title="Reset"
        >
          Dress Rehearsal Week Schedule
        </button>
      </div>
    );
  }
}
