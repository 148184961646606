import React, { useState } from "react";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../Modal";

const ConfirmationModalForMutation = ({
  children,
  icon,
  onConfirm,
  message,
  mutation,
  mutateParams = null,
  submitButtonLabel,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = (event) => {
    mutation.mutate(mutateParams, {
      onSuccess: () => {
        if (onConfirm) onConfirm();

        setIsOpen(false);
        window.dispatchEvent(new Event("lesson:complete", { bubbles: true }));
      },
    });
    event.preventDefault();
  };

  return (
    <Modal open={isOpen} onOpenChange={setIsOpen}>
      <ModalTrigger>{children}</ModalTrigger>
      <ModalContent>
        <div className="sm:flex sm:flex-col sm:items-start">
          <div className="flex items-center flex-col sm:flex-row space-y-2 sm:space-y-0 space-x-4">
            {icon}
            <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-14 sm:text-left space-y-2 whitespace-normal text-sm text-gray-500 font-normal">
            {message}
          </div>
        </div>
        <form className="mt-5 flex items-center justify-end space-x-3" onSubmit={onSubmit}>
          <ModalClose
            className="button-secondary"
            data-testid="complete-lesson-modal-cancel-button"
          >
            Cancel
          </ModalClose>
          <button
            className="button-primary bg-green-600 ring-green-700 text-white enabled:hover:bg-green-700"
            disabled={mutation.isLoading}
            data-testid="complete-lesson-modal-submit-button"
            type="submit"
          >
            {submitButtonLabel}
          </button>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModalForMutation;
