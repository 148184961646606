import { Application } from "@hotwired/stimulus"
import Popover from "stimulus-popover"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// NOTE: Non-controller third-party libraries should be imported
// and registered here instead of `index.js` because otherwise they
// will be removed when running `stimulus:manifest:update` (i.e.
// when  generating new Stimulus controllers).
application.register("popover", Popover)

export { application }
