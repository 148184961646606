import React from "react";
import { useParams } from "react-router-dom";

import ReactRouterTabs from "../common/ReactRouterTabs";

const Navigation = () => {
  const { id: studentId } = useParams();
  const NAV_LINKS = [
    { to: `/students/${studentId}`, label: "Today", dataTestid: "student-today-tab" },
    {
      to: `/students/${studentId}/progress`,
      label: "Progress",
      dataTestid: "student-progress-tab",
    },
    { to: `/students/${studentId}/notes`, label: "Notes", dataTestid: "student-notes-tab" },
  ];

  return <ReactRouterTabs navLinks={NAV_LINKS} />;
};

export default Navigation;
