import { HOUR_MINUTE_ZERO_PADDED, MERIDIEM_TIME_FORMAT } from "../constants";
import { format, parse } from "date-fns";

/**
 * Converts a time string in 12-hour format with timezone to 24-hour format.
 *
 * @param {string} time - A time string in the format "h:mm a z", e.g. "2:30 PM Eastern Time".
 * @returns {string} The input time converted to 24-hour format, e.g. "14:30".
 */
export default function (time) {
    const meridiemTime = time.split(' ').slice(0, -2).join(' ');
    const date = parse(meridiemTime, MERIDIEM_TIME_FORMAT, new Date());
    const time24Hour = format(date, HOUR_MINUTE_ZERO_PADDED);

    return time24Hour;
}
