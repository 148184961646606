import React from "react";

import DailyTimeBlockList from "./DailyTimeBlockList";
import { DailyView } from "../common/Calendar";

export default ({ subBoardEnabled }) =>(
  <DailyView>
    {(date) => (
      <DailyTimeBlockList date={date} subBoardEnabled={subBoardEnabled} />
    )}
  </DailyView>
);
