import {
  addDays,
  addMonths,
  addWeeks,
  isFriday,
  isMonday,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks
} from "date-fns";
import React, { useContext } from "react";
import { twMerge } from "tailwind-merge";

import { CurrentDateContext, CurrentViewContext } from "../../../contexts/Calendar";
import {CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW} from "../../../constants";
import { useCalendarViewMode } from "../../../hooks/useCalendarViewMode";

const START_OF_WEEK_OPTS = {
  weekStartsOn: 1,
};

export default () => {
  const { currentView } = useContext(CurrentViewContext);
  const { setCurrentDate } = useContext(CurrentDateContext);
  const { isSubMode } = useCalendarViewMode();
  const onClickNextDate = () => {
    setCurrentDate(currentDate => {
      if (currentView === CALENDAR_WEEKLY_VIEW) {
        return startOfWeek(addWeeks(currentDate, 1), START_OF_WEEK_OPTS);
      } else if (currentView === CALENDAR_MONTLY_VIEW) {
        return startOfMonth(addMonths(currentDate, 1));
      }

      return addDays(currentDate, isFriday(currentDate) ? 3 : 1);
    });
  };
  const onClickPrevDate = () => {
    setCurrentDate(currentDate => {
      if (currentView === CALENDAR_WEEKLY_VIEW) {
        return startOfWeek(subWeeks(currentDate, 1), START_OF_WEEK_OPTS);
      } else if (currentView === CALENDAR_MONTLY_VIEW) {
        return startOfMonth(subMonths(currentDate, 1));
      }

      return subDays(currentDate, isMonday(currentDate) ? 3 : 1);
    });
  };
  const onClickToday = () => {
    setCurrentDate(new Date());
  }

  return (
    <div className="flex items-center">
      <button
        className={twMerge("button-secondary py-1.5 px-1.5 md:px-2 rounded-r-none", isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : "")}
        onClick={onClickPrevDate}
        type="button"
      >
        {/* Heroicon name: outline/arrow-small-left */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] md:w-[20px] h-[18px] md:h-[20px]">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
      </button>
      <button
        className={twMerge("button-secondary text-sm rounded-none py-1.5 px-4 md:px-2.5 -ml-[1px] hidden md:block", isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : "")}
        onClick={onClickToday}
        type="button"
      >
        Today
      </button>
      <button
        className={twMerge("button-secondary py-1.5 px-1.5 md:px-2 rounded-l-none -ml-[1px]", isSubMode ? "bg-zinc-800 text-white ring-zinc-600 hover:enabled:bg-zinc-700/30" : "")}
        onClick={onClickNextDate}
        type="button"
      >
        {/* Heroicon name: outline/arrow-small-right */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] md:w-[20px] h-[18px] md:h-[20px]">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
        </svg>
      </button>
    </div>
  );
};
