import React from "react";

import { Link } from "react-router-dom";
import LessonButton from "./LessonButton";
import LessonPlan from "../LessonPlan";
import { useParams } from "react-router-dom";
import AutoReteachFocusBanner from "../AutoReteachFocusBanner";

const LessonRow = ({ content, isBaseline }) => {
  const { id: studentId } = useParams();

  return (
    <>
      <tr
        className="grid auto-cols-auto gap-0.5 w-[calc(100vw-2rem)] md:table-row border-t border-gray-200"
        id="lesson"
      >
        <td className="whitespace-nowrap col-start-1 pt-4 md:py-4 md:pr-3 pl-0 lg:pl-3">{content.displayName}</td>
        <td className="whitespace-nowrap col-start-1 row-start-2 pb-4 md:py-4 md:pr-3 md:text-center">
          <Link to={content.contentURL} target="_blank" className="text-brand-600 hover:text-brand-900">
            Content
          </Link>
        </td>
        <td
          className="whitespace-nowrap col-start-2 row-start-1 pt-4 md:py-4 md:pr-3 md:text-center before:content-[attr(data-title)] before:font-medium before:text-zinc-500 before:truncate lg:before:hidden"
          data-title="Completions:"
        >
          {content.completions > 0 ? content.completions : "-"}
        </td>
        <td
          className="whitespace-nowrap col-start-2 row-start-2 pb-4 md:py-4 md:pr-3 md:text-center before:content-[attr(data-title)] before:font-medium before:text-zinc-500 before:truncate lg:before:hidden"
          id="1"
          data-title="HFW:"
        >
          {content.hfwCompletionCount > 0 ? content.hfwCompletionCount : "-"}
        </td>
        <td className="whitespace-nowrap col-start-3 row-span-2 flex items-center justify-end md:py-4 lg:pl-3 pr-0 lg:pr-3 md:text-right font-medium">
          <div className="flex justify-end">
            <LessonButton content={content} isBaseline={isBaseline} />
          </div>
        </td>
      </tr>
      {content.isCurrentContent && (
        <tr className="bg-opacity-75 last:border-none border-b border-zinc-200">
          <td id="current_content" className="bg-zinc-50 p-4 md:p-6 xl:p-10 space-y-4" colSpan="5">
            {content.displayReteachBanner ? (
              <AutoReteachFocusBanner studentId={studentId} studentLessonId={content.studentModelId} />
            ) : null}
            <LessonPlan studentId={studentId} />
          </td>
        </tr>
      )}
    </>
  );
};

export default LessonRow;
