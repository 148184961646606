import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, post } from "../../api";
import { useStudentQuery } from "../Student/queries";

export const useStudentHighFrequencyWordsDrillQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-hfw-drill", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/student_high_frequency_words`, {
        signal,
      });

      return response.data;
    },
    keepPreviousData: true,
    staleTime: Infinity,
  });
};

export const useMarkWordAsTaughtMutation = (studentHighFrequencyWordId) => {
  const queryClient = useQueryClient();
  const { id: studentId } = useParams();
  const { data: studentData } = useStudentQuery();

  return useMutation({
    mutationFn: async () => {
      const response = await post(`/students/${studentId}/student_high_frequency_word_teachings`, {
        student_high_frequency_word_teaching: {
          lesson_id: studentData?.lessonId,
          student_high_frequency_word_id: studentHighFrequencyWordId,
        },
      });

      return response.data;
    },
    onSuccess: ({ content, id, isNextWordPresent, uuid, ...rest }) => {
      queryClient.setQueryData(["student-hfw-drill", studentId], (prevData) => {
        return {
          ...prevData,
          isNextWordPresent,
          words: prevData.words.map((word) => {
            return word.uuid === uuid
              ? {
                  ...word,
                  content,
                  id,
                  ...rest,
                }
              : word;
          }),
        };
      });
      queryClient.setQueryData(["student-hfw-mastery-drawer", studentId], (prevData = {}) => {
        const prevTaughtWords = prevData.taughtWords || [];
        const prevNotYetTaughtWords = prevData.notYetTaughtWords || [];

        return {
          ...prevData,
          taughtWords: [...prevTaughtWords, { id, content, isCorrect: false }],
          notYetTaughtWords: prevNotYetTaughtWords.filter((word) => {
            return word.id !== id;
          }),
        };
      });
      queryClient.setQueryData(["student", studentId], (prevData = {}) => {
        return {
          ...prevData,
          taughtHFWCount: prevData.taughtHFWCount + 1,
        };
      });
    },
  });
};

export const useAddNewWordMutation = () => {
  const queryClient = useQueryClient();
  const { id: studentId } = useParams();

  return useMutation({
    mutationFn: async () => {
      const response = await get(`/students/${studentId}/student_high_frequency_words/next_word`);

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["student-hfw-drill", studentId], (prevData) => {
        return {
          ...prevData,
          words: [...prevData.words, data],
        };
      });
    },
  });
};
