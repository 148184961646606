import React, { useMemo } from "react";

import WordListItem from "./WordListItem";
import { useStudentAssessmentQuery } from "../StudentAssessment";
import { readingAssessmentItemType } from "../../constants";

const { NEW_LINE, PUNCTUATION, TITLE } = readingAssessmentItemType;

export default ({ disabled }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const items = useMemo(() => {
    return studentAssessmentQuery?.data?.readingAssessmentItems || [];
  }, [studentAssessmentQuery?.data?.readingAssessmentItems]);
  const paragraphs = useMemo(() => {
    return items.sort((a, b) => a.sequenceNumber - b.sequenceNumber).reduce((acc, item, i, array) => {
      const prevItem = array[i - 1];
      const nextItem = array[i + 1];

      if (item.assessmentItemType === NEW_LINE && (prevItem?.assessmentItemType === NEW_LINE ||
        prevItem?.assessmentItemType === TITLE && nextItem?.assessmentItemType !== NEW_LINE)) {
        return [...acc, []];
      }

      return [...acc.slice(0, -1), acc.pop().concat(item)];
    }, [[]]);
  }, [items]);

  return paragraphs.map((paragraph) => {
    const key = paragraph.flatMap(item => item.id);
    const content = paragraph.map((item, i) => {
      if (item.assessmentItemType === NEW_LINE) {
        return <br key={item.id} />;
      }
      if (item.assessmentItemType === PUNCTUATION) {
        return null;
      }
      if (item.assessmentItemType === TITLE) {
        return item.text;
      }

      const nextItem = paragraph[i + 1];
      const prevItem = i > 0 ? paragraph[i - 1] : null;
      const hasNextPunctuation = nextItem?.assessmentItemType === PUNCTUATION;
      const hasPrevPunctuation = prevItem?.assessmentItemType === PUNCTUATION;
      const afterPunctuation = hasNextPunctuation && nextItem?.punctuationLocation === "after" ? nextItem.text : "";
      const beforePunctuation = hasPrevPunctuation && prevItem?.punctuationLocation === "before" ? prevItem.text : "";
      const label = `${beforePunctuation}${item.text}${afterPunctuation}`;

      return <WordListItem disabled={disabled} id={item.id} key={item.id} label={label} />;
    });

    return paragraph.every(item => [TITLE, NEW_LINE].includes(item.assessmentItemType)) ? (
      <h2 className="mb-6 text-center font-semibold text-zinc-600 mt-10" key={key}>
        {content.filter(item => typeof item === "string").join(" ")}
      </h2>
    ) : (
      <p className="mb-6 font-normal" key={key}>{content}</p>
    )
  });
}
