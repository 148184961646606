import cx from "classnames";
import kebabCase from "lodash/kebabCase";
import React from "react";
import { twMerge } from "tailwind-merge";

export default ({ icon, isPrimary, label, value }) => {
  return (
    <li
      className={twMerge(cx("flex items-center text-zinc-500 px-2 space-x-2", {
        "text-lg font-medium border-b border-zinc-300 text-zinc-700 py-4": isPrimary
      }))}
    >
      <div
        className={twMerge(cx("w-5 h-5", {
          "w-7 h-7": isPrimary
        }))}
      >
        {icon}
      </div>
      <span data-testid={`results-item-${kebabCase(label)}-value`}>{label}: {value ?? "--"}</span>
    </li>
  );
}
