import cx from "classnames";
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isFirstDayOfMonth,
  isSameMonth,
  isToday,
  isWeekend,
  startOfMonth
} from "date-fns";
import first from "lodash/first";
import React, { useContext } from "react";

import { CurrentDateContext }  from "../../../contexts/Calendar";
import { DAY_OF_MONTH, DAY_WITH_SHORT_MONTH, SHORT_DAY_OF_WEEK } from "../../../constants";

export default ({ children }) => {
  const { currentDate } = useContext(CurrentDateContext);
  const weeks = eachWeekOfInterval({
    start: startOfMonth(currentDate),
    end: endOfMonth(currentDate),
  }, { weekStartsOn: 1 }).map(startOfWeek => {
    return eachDayOfInterval({
      start: startOfWeek,
      end: endOfWeek(startOfWeek),
    }).filter(day => !isWeekend((day)));
  }).filter(week => {
    return week.some(day => isSameMonth(currentDate, day));
  });

  return (
    <table className="w-full border-spacing-0 table-fixed -ml-[1px]">
      <thead>
      <tr>
        {first(weeks).map(day => (
          <th
            className="bg-white border-b border-x uppercase text-xs leading-none text-zinc-400 font-medium sticky top-[144px] md:top-[150px] lg:top-[89px] p-0 z-40"
            key={day.toISOString()}
          >
            <div className="border-b p-1.5 -mb-[1px]">
              {format(day, SHORT_DAY_OF_WEEK)}
            </div>
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {weeks.map((days, weekIndex) => (
        <tr key={days.toString()}>
          {days.map((day, dayIndex) => (
            <td
              className={cx(
                "border align-top h-[201px]",
                isSameMonth(currentDate, day)
                  ? isToday(day) ? "bg-sky-50/60" : "bg-white"
                  : "opacity-50 bg-zinc-50"
              )}
              key={day.toISOString()}
            >
              <div className={cx("p-1 text-sm", isToday(day) ? "text-sky-500" : "text-zinc-500")}>
                {format(
                  day,
                  isFirstDayOfMonth(day) || (weekIndex === 0 && dayIndex === 0) ? DAY_WITH_SHORT_MONTH : DAY_OF_MONTH)
                }
              </div>
              {children(day)}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
}
