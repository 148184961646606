import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static classes = ["defaultTab", "activeTab"]
  static targets = ["tabs", "content"]
  static values = {
    defaultTabIndex: { type: Number, default: 0 },
  }

  connect() {
    [...this.tabsTarget.children].forEach((element, tabIndex) => {
      element.classList.add(...(this.defaultTabIndexValue === tabIndex ? this.activeTabClasses : this.defaultTabClasses));
      element.addEventListener("click", this.switchToTab(tabIndex));
    });
    [...this.contentTarget.children].forEach((element, contentIndex) => {
      if (this.defaultTabIndexValue !== contentIndex) {
        element.classList.add("hidden");
      }
    });
  }

  disconnect() {
    [...this.tabsTarget.children].forEach((element, i) => {
      element.removeEventListener("click", this.switchToTab(i));
    });
  }

  switchToTab(selectedTabIndex) {
    return () => {
      [...this.tabsTarget.children].forEach((element, tabIndex) => {
        if (tabIndex === selectedTabIndex) {
          element.classList.remove(...this.defaultTabClasses);
          element.classList.add(...this.activeTabClasses);
        } else {
          element.classList.remove(...this.activeTabClasses);
          element.classList.add(...this.defaultTabClasses);
        }
      });
      [...this.contentTarget.children].forEach((element, contentIndex) => {
          element.classList[contentIndex === selectedTabIndex ? "remove" : "add"]("hidden");
      });
    }
  }
}
