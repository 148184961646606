import React, { useState } from "react";

import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { useCreateNoteMutation } from "./queries";

const CreateNoteModal = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const mutation = useCreateNoteMutation(value);

  const onSubmit = (event) => {
    mutation.mutate(null, {
      onSuccess: () => {
        setOpen(false);
        setValue("");
      },
    });

    event.preventDefault();
  };

  const onChangeValue = (event) => {
    setValue(event.target.value);
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger>
        <button
          className="button-secondary py-2 inline-flex items-center whitespace-nowrap"
          data-testid="create-note-modal-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
          Leave a note
        </button>
      </ModalTrigger>
      <ModalContent data-testid="create-note-modal">
        <ModalClose
          className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1"
          data-testid="celebratory-modal-close-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </ModalClose>
        <form onSubmit={onSubmit} className="flex flex-col">
          <h3 className="text-base font-medium leading-6 text-gray-900">Create A New Note</h3>
          <textarea
            autoFocus="autofocus"
            className="mt-3 focus:ring-brand-500 focus:border-brand-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            rows={8}
            value={value}
            data-testid="create-note-modal-textarea"
            onChange={onChangeValue}
          />
          <div className="mt-5 space-y-2 sm:space-y-0 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button className="button-primary sm:ml-3 w-full sm:w-auto" type="submit">
              Submit
            </button>
            <ModalClose>Cancel</ModalClose>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateNoteModal;
