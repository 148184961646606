import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, post } from "../../api";

export const useCurrentContentQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["current-content", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/current_content`, {
        signal,
      });

      return response.data;
    },
  });
};

export const useCurrentContentDismissTodayMutation = () => {
  const { id: studentId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dismiss }) => {
      const response = await post(`/students/${studentId}/dismiss_rehearsal_banner`, { dismiss });

      return response.data;
    },
    onSuccess: ({ rehearsalBannerDismissedAt }) => {
      queryClient.setQueryData(["current-content", studentId], (prevData = {}) => {
        return {
          ...prevData,
          rehearsalBannerDismissedAt,
        };
      });
    },
  });
};
