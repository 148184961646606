import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get } from "../../api";

export const useStudentHighFrequencyWordsMasteryDrawerQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-hfw-mastery-drawer", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/hfw_mastery`, {
        signal,
      });

      return response.data;
    },
    keepPreviousData: true,
  });
};
