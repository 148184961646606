import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import { twMerge } from "tailwind-merge";
import cx from "classnames";
import { isPast, parse } from "date-fns";
import { MERIDIEM_TIME_FORMAT } from "../../constants";

const now = new Date();

const StudentPicker = ({ students }) => {
  const [open, setOpen] = useState(false);
  const { id: studentId } = useParams();
  const value = parseInt(studentId, 10);
  const selectedStudent = students.find((student) => student.studentId === value);
  const ulRef = useRef(null);
  const selectedRef = useRef(null);

  useEffect(() => {
    const scrollToSelectedStudent = () => {
      if (open) {
        if (selectedRef.current && ulRef.current) {
          const ulRect = ulRef.current.getBoundingClientRect();
          const studentRect = selectedRef.current.getBoundingClientRect();
          const offset = studentRect.top - ulRect.top;

          ulRef.current.scrollTop = offset;
        } else {
          // If the ref is not yet assigned, try again after a short delay
          setTimeout(scrollToSelectedStudent, 100);
        }
      }
    };

    scrollToSelectedStudent();
  }, [value, open]);

  return (
    <Popover onOpenChange={setOpen} open={open} placement="bottom-start" toggle>
      <PopoverTrigger>
        <button
          type="button"
          data-testid="student-picker-button"
          className="flex items-center md:text-xl lg:text-2xl font-semibold text-gray-900 flex-grow whitespace-nowrap truncate"
        >
          {selectedStudent.displayName}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className={cx("ml-2 w-4 h-4 transition-transform", {
              "-rotate-180": open,
            })}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
          {selectedStudent.hasMultipleProgramEnrollments ? (
            <span className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 ml-2">
              Accelerator Pack
            </span>
          ) : null}
        </button>
      </PopoverTrigger>
      <PopoverContent hideArrow hideCloseButton>
        <ul
          ref={ulRef}
          data-testid="student-picker-dropdown"
          className="py-1 min-w-[180px] max-w-[420px] max-h-[150px] flex flex-col overflow-auto"
          aria-labelledby="dropdownDefaultButton"
        >
          {students.map((student) => {
            return (
              <li key={student.studentId} ref={student.studentId === value ? selectedRef : null}>
                <Link
                  to={`/students/${student.studentId}`}
                  onClick={() => {
                    setOpen(false);
                  }}
                  className={twMerge(
                    cx(
                      "flex justify-between text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer w-full truncate",
                      {
                        "bg-brand-600 text-white hover:bg-brand-700 hover:text-white":
                          student.studentId === value,
                      }
                    )
                  )}
                >
                  <span
                    className={cx({
                      "text-gray-400": isPast(
                        parse(
                          student.tutoringBlock,
                          MERIDIEM_TIME_FORMAT,
                          new Date(now.getFullYear(), now.getMonth(), now.getDate())
                        )
                      ),
                    })}
                  >
                    {student.displayName}

                    {student.hasMultipleProgramEnrollments ? (
                      <span className="rounded-full px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 ml-2">
                        Accelerator Pack
                      </span>
                    ) : null}
                  </span>
                  <span className="text-gray-500 ml-6">{student.tutoringBlock}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default StudentPicker;
