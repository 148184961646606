import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { resourceType, StudentAssessmentContext } from "./";
import { RailsPathsProvider } from "../../contexts/RailsPathsContext";
import Wrapper from "./Wrapper";
import QATools from "./QATools";
import { SparkCookieProvider } from "../common/SparkCookie";
import { DEFAULT_REACT_QUERY_STALE_TIME } from "../../constants";

const StudentAssessmentProvider = ({
  isCollapsible,
  onCompleteAssessment,
  onUpdateWordEndCallback,
  open: openFromProps,
  ...props
}) => {
  const [open, setOpen] = useState(isCollapsible ? openFromProps : true);

  const value = useMemo(() => {
    return {
      isCollapsible,
      onCompleteAssessment,
      onUpdateWordEndCallback,
      open,
      setOpen,
      ...props,
    };
  }, [isCollapsible, open, onCompleteAssessment, onUpdateWordEndCallback, props, setOpen]);

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          enabled: false,
          staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
        },
      },
    })
  );

  const onComplete = useCallback(() => {
    setOpen(!isCollapsible);
  }, [isCollapsible]);

  useEffect(() => {
    setOpen(openFromProps);
  }, [openFromProps]);

  useEffect(() => {
    window.addEventListener("lesson:complete", onComplete);

    return () => {
      window.removeEventListener("lesson:complete", onComplete);
    };
  }, [onComplete]);

  return (
    <QueryClientProvider client={queryClient.current}>
      <RailsPathsProvider paths={props.paths}>
        <SparkCookieProvider>
          <StudentAssessmentContext.Provider value={value}>
            <Wrapper>{props.children}</Wrapper>
          </StudentAssessmentContext.Provider>
        </SparkCookieProvider>
        <QATools />
      </RailsPathsProvider>
    </QueryClientProvider>
  );
};

StudentAssessmentProvider.defaultProps = {
  isCollapsible: true,
  open: false,
  resetModalTitle: "Reset Assessment",
  resetModalContent:
    "All the assessment data you marked will be erased and reset for this lesson. Are you sure you want to reset?",
  resource: resourceType.WORD,
};

export default StudentAssessmentProvider;
