import React from "react";

import { DrawerClose } from "./index";

const DrawerHeader = ({ children, closeButtonDataTestid }) => (
  <div className="bg-white sticky top-0 px-4 md:px-6 lg:pl-12 lg:pr-6 py-4 md:py-6 lg:pb-6 lg:pt-8 border-b flex items-center justify-between z-10">
    {children}
    <DrawerClose
      className="text-zinc-500 rounded-full hover:bg-zinc-100 hover:text-zinc-700 p-1"
      data-testid={closeButtonDataTestid}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-6 h-6"
      >
        <path
          fillRule="evenodd"
          d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
          clipRule="evenodd"
        />
      </svg>
    </DrawerClose>
  </div>
);

export default DrawerHeader;
