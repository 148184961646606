import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hfw"
export default class extends Controller {
  static targets = [
    "addNewButton",
    "addNewButtonFrame",
    "list",
    "taughtWordsCount",
    "totalWordsCount",
    "word"
  ]
  static values = ["needsTeaching"]

  connect() {
    document.addEventListener("turbo:frame-render", this.onMarkWordAsTaught.bind(this));
    this.mutationObserver = new MutationObserver(this.updateWordsCount.bind(this))
    this.mutationObserver.observe(this.listTarget, { childList: true, subtree: true });
  }

  disconnect() {
    document.removeEventListener("turbo:frame-render", this.onMarkWordAsTaught);
    this.mutationObserver.disconnect();
  }

  onMarkWordAsTaught(event) {
    if (event.target.id.startsWith("student_high_frequency_word")) {
      this.updateAddNewButton();
    }
  }

  updateAddNewButton() {
    if (this.hasAddNewButtonTarget) {
      this.addNewButtonTarget.disabled = this.taughtWordsCount !== this.totalWordsCount;
    }
  }

  updateWordsCount() {
    this.taughtWordsCountTarget.textContent = this.taughtWordsCount;
    this.totalWordsCountTarget.textContent = this.totalWordsCount;
  }

  get taughtWordsCount() {
    return this.wordTargets.filter(word => word.dataset.needsTeaching === "false").length;
  }

  get totalWordsCount() {
    return this.wordTargets.length;
  }
}
