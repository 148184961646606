import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["schoolSystemSelect", "schoolSystemNcesID"]
  static values = {
    schoolSystems: { type: Array, default: [] }
  }

  connect() {
    this.updateSchoolSystemNcesID(this.schoolSystemSelectTarget.value)
  }

  populateSchoolSystemNcesID(event) { 
    this.updateSchoolSystemNcesID(event.target.selectedOptions[0].value)
  }

  updateSchoolSystemNcesID(selectedSchoolId) {
    const selectedSchoolSystem = this.schoolSystemsValue.find((e) => {
      // We have to cast the object's id to a String for comparison because
      // values from select fields are provided as Strings
      return `${e.id}` === selectedSchoolId
    })
    this.schoolSystemNcesIDTarget.innerHTML = selectedSchoolSystem.nces_id
  }
}
