import cx from "classnames";
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  CALENDAR_DAILY_VIEW,
  CALENDAR_NORMAL_MODE,
  CALENDAR_SUB_MODE,
  CALENDAR_WEEKLY_VIEW
} from "../../constants";
import { CurrentViewContext } from "../../contexts/Calendar";
import SessionSuggestions from "./SessionSuggestions";
import { ViewSwitcher } from "../common/Calendar";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import DynamicDropdown from "../common/DynamicDropdown";

const SHOW_SUB_OPPORTUNITIES_VALUE = "show-sub-opportunities";

export default ({ subBoardEnabled }) => {
  const {
    showSubSessionRequests,
    setCurrentView,
    setShowSubSessionRequests,
  } = useContext(CurrentViewContext);
  const {
    isCalendarMode,
    isSubMode,
    setCalendarMode,
    setSubMode,
  } = useCalendarViewMode();
  const onChangeViewMode = (value) => {
    if (value === CALENDAR_NORMAL_MODE) {
      setCalendarMode();
      setShowSubSessionRequests(false);
    }
    if (value === CALENDAR_SUB_MODE) {
      setSubMode();
      setCurrentView(CALENDAR_WEEKLY_VIEW);
      setShowSubSessionRequests(false);
    }
    if (value === SHOW_SUB_OPPORTUNITIES_VALUE) {
      setCalendarMode();
      setShowSubSessionRequests(true);
    }
  };
  const viewModeValue = useMemo(() => {
    if (isSubMode) {
      return CALENDAR_SUB_MODE;
    } else {
      if (showSubSessionRequests) {
        return SHOW_SUB_OPPORTUNITIES_VALUE;
      }

      return CALENDAR_NORMAL_MODE;
    }
  }, [isSubMode, showSubSessionRequests]);

  return (
    <div className="flex items-center h-[32px] w-full justify-between">
      {subBoardEnabled ? (
        <DynamicDropdown
          hideSelectedOption
          isDarkMode={isSubMode}
          onChange={onChangeViewMode}
          options={[
            {
              label: "Calendar View",
              value: CALENDAR_NORMAL_MODE,
            },
            {
              label: "Time Off / Sub Requests",
              value: CALENDAR_SUB_MODE,
            },
            {
              label: "Open Sub Opportunities",
              value: SHOW_SUB_OPPORTUNITIES_VALUE,
            }
          ]}
          placement="bottom-end"
          value={viewModeValue}
        />
      ) : null}
      <div className={cx(
          "flex items-center h-[32px] w-auto justify-between", {
            "w-full sm:w-auto": !subBoardEnabled,
          })}>
        <ViewSwitcher
          views={[ CALENDAR_WEEKLY_VIEW, CALENDAR_DAILY_VIEW ]}
          onChange={() => {
            if (isSubMode) {
              setCalendarMode();
            }
          }}
        />
        <div className="flex items-center h-[32px] justify-between">
          <SessionSuggestions />
          <Link
            className={cx(
              "flex items-center rounded-md bg-white px-2 text-sm font-medium  ml-1 lg:ml-2 h-full", {
                "bg-white text-gray-700 hover:bg-gray-100": isCalendarMode,
                "bg-zinc-800 text-white hover:bg-zinc-700/30": isSubMode,
            })}
            to="/tutor_availabilities"
            title="Schedule preferences"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 lg:w-6 h-5 lg:h-6" data-testid="tutor-availabilities-icon">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  )
}
