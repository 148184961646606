import React, { createContext, useState } from "react";

export const SelectedTimeBlockContext = createContext({});
export const SubBoardSelectionsContext = createContext({});

export const TutorCalendarProvider = ({ children }) => {
  const [selectedTimeBlock, setSelectedTimeBlock] = useState(null);
  const [selectedDates, setSelectedDates] = useState(new Set([]));
  const [selectedTimeBlocks, setSelectedTimeBlocks] = useState(new Set([]));

  return (
    <SubBoardSelectionsContext.Provider value={{ selectedDates, setSelectedDates, selectedTimeBlocks, setSelectedTimeBlocks }}>
      <SelectedTimeBlockContext.Provider value={{ selectedTimeBlock, setSelectedTimeBlock }}>
        {children}
      </SelectedTimeBlockContext.Provider>
    </SubBoardSelectionsContext.Provider>
  );
};
