import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";

import { patch } from "../../api";
import { tutorStudentStatus } from "../../constants";
import { CurrentDateContext } from "../../contexts/Calendar";

export default ({ studentName, tutorStudentUuid }) => {
  const { currentDate } = useContext(CurrentDateContext);
  const queryClient = useQueryClient();
  const [isProcessing, setIsProcessing] = useState(false);
  const mutationFn = useCallback(
    (status) => () => {
      return patch(`/tutor_students/${tutorStudentUuid}.json`, {
        tutor_student: { status },
      });
    },
    [tutorStudentUuid]
  );
  const acceptMutation = useMutation({
    mutationFn: mutationFn(tutorStudentStatus.ACCEPTED),
    onMutate: () => {
      setIsProcessing(true);
    },
    onError: () => {
      setIsProcessing(false);
      toast("There was a problem accepting tutoring session. Please try again.", {
        autoClose: 3200,
        isLoading: false,
        type: "error",
      });
    },
    onSuccess: () => {
      setIsProcessing(false);
      toast(
        <>
          Tutoring session with <strong data-heap-redact-text>{studentName}</strong> accepted.
        </>,
        {
          autoClose: 3200,
          isLoading: false,
        }
      );
      queryClient.invalidateQueries({ queryKey: ["suggestions"] });
      queryClient.invalidateQueries({ queryKey: ["events", currentDate] });
    },
  });
  const declineMutation = useMutation({
    mutationFn: mutationFn(tutorStudentStatus.REJECTED),
    onMutate: () => {
      setIsProcessing(true);
    },
    onError: () => {
      setIsProcessing(false);
      toast("There was a problem declining tutoring session. Please try again.", {
        autoClose: 3200,
        isLoading: false,
        type: "error",
      });
    },
    onSuccess: () => {
      setIsProcessing(false);
      toast(
        <>
          Tutoring session with <strong data-heap-redact-text>{studentName}</strong> declined.
        </>,
        {
          autoClose: 3200,
          isLoading: false,
        }
      );
      queryClient.invalidateQueries({ queryKey: ["suggestions"] });
      queryClient.invalidateQueries({ queryKey: ["events", currentDate] });
    },
  });

  return [
    <button
      className="button-primary"
      disabled={isProcessing}
      key="accept"
      onClick={acceptMutation.mutate}
    >
      Accept
    </button>,
    <button
      className="button-secondary"
      disabled={isProcessing}
      key="decline"
      onClick={declineMutation.mutate}
    >
      Decline
    </button>,
  ];
};
