import cx from "classnames";
import { size } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import SessionSuggestionsList from "./SessionSuggestionsList";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useSuggestionsQuery } from "./queries";

const OPEN_LIST_URL_QUERY_PARAM_KEY = "suggestions";

export default () => {
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const suggestionsQuery = useSuggestionsQuery();
  const { data: suggestions } = suggestionsQuery || { data: [] };

  useEffect(() => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search);

    if (params.has(OPEN_LIST_URL_QUERY_PARAM_KEY)) {
      setIsOpen(true);
      params.delete(OPEN_LIST_URL_QUERY_PARAM_KEY);
      url.search = params.toString();
      navigate(url, { replace: true });
    }
  }, [navigate]);

  return (
    <Popover onOpenChange={setIsOpen} open={isOpen} placement="bottom">
      <PopoverTrigger>
        <button
          className={cx(
            "flex items-center rounded-md px-2 text-sm font-medium ml-2 lg:ml-4 h-full relative",
            "group", {
              "bg-white text-gray-700 hover:bg-gray-100": isCalendarMode,
              "bg-zinc-800 text-white hover:bg-zinc-700/30": isSubMode,
            }
          )}
          data-testid="session-suggestions"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 lg:w-6 h-5 lg:h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
          {size(suggestions) > 0 ?
            <span className={cx(
              "bg-sky-500 rounded-full border-[2px] border-white group-hover:border-gray-100",
              "h-[14px] w-[14px] absolute right-[8px] top-[1px]"
            )} />
          : null}
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <SessionSuggestionsList />
      </PopoverContent>
    </Popover>
  )
}
