import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import kebabCase from "lodash/kebabCase";
import noop from "lodash/noop";
import words from "lodash/words";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import useStudentAssessmentContext from "./useStudentAssessmentContext";
import { readingAssessmentStatusType } from "../../constants";
import { useStudentAssessmentQuery } from "./queries";
import { resourceType } from "./";

const { IN_PROGRESS } = readingAssessmentStatusType;

const ReadingStatsItem = ({ emphasized, icon, isEditable, label, mutation, unitOfMeasurement: unitOfMeasurementFromProps, value: valueFromProps }) => {
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { status } = studentAssessmentQuery.data || {};
  const { resource } = useStudentAssessmentContext()
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(valueFromProps ?? "");
  const unitOfMeasurement = unitOfMeasurementFromProps ?? `${resource === resourceType.WORD ? "Words" : "Letters"} Correct Per Minute`;
  const save = () => {
    mutation.mutate(value, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };
  const onClickEdit = () => {
    setIsEditing(true);
  };
  const onChangeValue = (event) => {
    setValue(event.target.value);
  };
  const onBlurInput = () => {
    if (isEmpty(value)) {
      inputRef.current.focus();
    } else {
      save();
    }
  };
  const onSubmitValue = (event) => {
    if (isEmpty(value)) {
      inputRef.current.focus();
    } else {
      save();
    }
    event.preventDefault();
  };
  const onDocumentKeyUp = useCallback((event) => {
    if (event.key === "Escape") {
      setIsEditing(false);
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
      inputRef.current.select();
      document.addEventListener("keyup", onDocumentKeyUp);
    } else {
      document.removeEventListener("keyup", onDocumentKeyUp);
    }
  }, [isEditing, onDocumentKeyUp]);
  useEffect(() => {
    setValue(isNil(valueFromProps) ? "" : valueFromProps.toString());
  }, [valueFromProps]);

  return (
    <li className={twMerge(cx("flex-1 bg-gray-200 rounded-lg px-2 md:px-4 pt-2.5 md:pt-3.5 pb-2 md:pb-2.5 max-w-[180px] space-y-1 flex flex-col justify-between relative", {
      "bg-brand-100": status === IN_PROGRESS,
      "border border-gray-500": emphasized
    }))}>
      <div className="text-[10px] md:text-xs text-zinc-500 pr-6">{label}</div>
      {isEditing ? (
        <form className="flex items-center w-full relative -left-[5px] -top-[3px]" onSubmit={onSubmitValue}>
          <input
            className="rounded-md text-base/none md:text-2xl/none bg-white/30 border-white/50 shrink grow-0 basis-full w-0 p-1"
            min={0}
            onBlur={onBlurInput}
            onChange={onChangeValue}
            ref={inputRef}
            type="number"
            value={value}
          />
          {/* The submit happens when the input above is blurred, so the button below has more of a visual purpose. */ }
          <button className="button-secondary py-1.5 px-2.5 ml-4" type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
              <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
            </svg>
          </button>
        </form>
      ) : (
        <div className="flex items-center space-x-1.5">
          <div className="text-base md:text-2xl" data-testid={`stats-item-${kebabCase(label)}-value`}>
            {isEmpty(value) ? "-" : value}{" "}
            <abbr className="uppercase text-[10px]" title={unitOfMeasurement}>
              {words(unitOfMeasurement).map(word => word.charAt(0)).join("").toUpperCase()}
            </abbr>
          </div>
          {isEditable ?
            <button className="text-zinc-500 hover:text-zinc-600" onClick={onClickEdit} type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
              </svg>
            </button>
          : null}
        </div>
      )}
      <div className="absolute top-1 right-2.5 w-6 md:w-7 h-6 md:h-7 text-zinc-500 flex items-center justify-center">
        {icon}
      </div>
    </li>
  );
}

ReadingStatsItem.defaultProps = {
  mutation: {
    mutate: noop,
  },
  unitOfMeasurement: null,
};

export default ReadingStatsItem;
