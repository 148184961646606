import { Controller } from "@hotwired/stimulus"
import { template } from "lodash"
import { utcToZonedTime, format } from "date-fns-tz"

export default class extends Controller {
  static classes = ["visible"]
  static targets = [
    "hiddenFormField",
    "tutoringBlockTable",
    "tutoringBlockList",
    "tutoringBlockListItemTemplate",
    "tutoringBlockFormTemplate"
  ]

  static values = {
    tutoringBlocks: { type: Array, default: [] },
  }

  connect() {
    this.refreshTutoringBlockList(this.tutoringBlocksValue)    
  }

  addTutoringBlock() {
    // Pulling the values out of the Start Time and End Time Selectors

    const newTutoringBlockStartTimeHourElement =
      document.querySelector("#tutoring_block_start_time_picker__4i")
    const newTutoringBlockStartTimeMinuteElement =
      document.querySelector("#tutoring_block_start_time_picker__5i")
    const newTutoringBlockEndTimeHourElement =
      document.querySelector("#tutoring_block_end_time_picker__4i")
    const newTutoringBlockEndTimeMinuteElement =
      document.querySelector("#tutoring_block_end_time_picker__5i")

    const allTutoringBlockTimesSelected = newTutoringBlockStartTimeHourElement.selectedIndex != 0 &&
      newTutoringBlockStartTimeMinuteElement.selectedIndex != 0 &&
      newTutoringBlockEndTimeHourElement.selectedIndex != 0 &&
      newTutoringBlockEndTimeMinuteElement.selectedIndex != 0

    if(allTutoringBlockTimesSelected) {
      const newTutoringBlockStartTimeHour = newTutoringBlockStartTimeHourElement.value
      const newTutoringBlockStartTimeMinute = newTutoringBlockStartTimeMinuteElement.value
      const newTutoringBlockEndTimeHour = newTutoringBlockEndTimeHourElement.value
      const newTutoringBlockEndTimeMinute = newTutoringBlockEndTimeMinuteElement.value

      // Creating a new object to be used to update the Tutoring Blocks table
      const newRecordId = new Date().getTime()
      // We have to use the default date that Rails' uses when generating a time that otherwise doesn't
      // have a date assinged to it (i.e. start_time and end_time). The date rails uses is 2000-01-01.
      const start_time = `2000-01-01T${newTutoringBlockStartTimeHour}:${newTutoringBlockStartTimeMinute}:00.000Z`
      const end_time = `2000-01-01T${newTutoringBlockEndTimeHour}:${newTutoringBlockEndTimeMinute}:00.000Z`
      const newTutoringBlockObject = {
        id: newRecordId,
        start_time,
        end_time
      }

      const tutoringBlocksCopy = structuredClone(this.tutoringBlocksValue)
      tutoringBlocksCopy.push(newTutoringBlockObject)

      // Creating a copy of the nested Tutoring Block form and populating it with the
      // values pulled out of the Start Time and End Time Selectors
      const content = this.tutoringBlockFormTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newRecordId)
      this.tutoringBlockFormTemplateTarget.insertAdjacentHTML("beforebegin", content)

      document.querySelector(`#program_term_tutoring_blocks_attributes_${newRecordId}_start_time`).value = start_time
      document.querySelector(`#program_term_tutoring_blocks_attributes_${newRecordId}_end_time`).value = end_time

      // Reset the tutoring block form

      newTutoringBlockStartTimeHourElement.selectedIndex = 0
      newTutoringBlockStartTimeMinuteElement.selectedIndex = 0
      newTutoringBlockEndTimeHourElement.selectedIndex = 0
      newTutoringBlockEndTimeMinuteElement.selectedIndex = 0

      // Repainting the Tutoring Blocks table

      this.refreshTutoringBlockList(tutoringBlocksCopy)
    }
  }

  removeTutoringBlock(event) {
    event.preventDefault()

    const tutoringBlockToRemoveDataset = event.target.closest("[data-tutoring-block-id]").dataset
    const tutoringBlockToRemoveId = tutoringBlockToRemoveDataset.tutoringBlockId
    const tutoringBlockPersisted = tutoringBlockToRemoveDataset.tutoringBlockPersisted

    // Note the "boolean" value from the dataset provied as a string
    if (tutoringBlockPersisted === "true") {
      document.querySelector(`[data-tutoring-block-to-remove-id="${tutoringBlockToRemoveId}"]`).value = true
    } else if ( document.querySelector(`#program_term_tutoring_blocks_attributes_${tutoringBlockToRemoveId}_start_time`) != null && document.querySelector(`#program_term_tutoring_blocks_attributes_${tutoringBlockToRemoveId}_end_time`) != null ) {
      document.querySelector(`#program_term_tutoring_blocks_attributes_${tutoringBlockToRemoveId}_start_time`).remove()
      document.querySelector(`#program_term_tutoring_blocks_attributes_${tutoringBlockToRemoveId}_end_time`).remove()
    }

    const remainingTutoringBlocks =
      this.tutoringBlocksValue.filter(tutoringBlock => (tutoringBlock.id != tutoringBlockToRemoveId && tutoringBlock.id != null ))

    this.refreshTutoringBlockList(remainingTutoringBlocks)
  }

  refreshTutoringBlockList(updatedTutoringBlocks) {
    this.tutoringBlocksValue = updatedTutoringBlocks.sort((a, b) => {
      // We just want to sort by the start time. The actual date and time zone don't matter
      // as long as their the same
      return (new Date(a.start_time) - new Date(b.start_time))
    })

    // Repaint the list with the updated tutoring block values
    this.tutoringBlockListTarget.innerHTML = ''

    const listItemTemplate = template(this.tutoringBlockListItemTemplateTarget.innerHTML)

    this.tutoringBlocksValue.forEach((tutoringBlock, index) => {
      // We don't really care about the date portion of the start and end times but JavaScript
      // doesn't have a concept of a standalone Time object. Instead, we place our tutoring block
      // start and end times into a date object using the same date and timezone. This allows us
      // to present (and later store) the information we need. Essentially, we only end up storing
      let zonedStartTime = utcToZonedTime(new Date(tutoringBlock.start_time), 'UTC')
      let zonedEndTime = utcToZonedTime(new Date(tutoringBlock.end_time), 'UTC')

      let templateData = {
        tutoringBlockId: tutoringBlock.id,
        tutoringBlockName: `Block ${index + 1}`,
        formattedTutoringBlockStartTime: format(zonedStartTime, 'h:mm a'),
        formattedTutoringBlockEndTime: format(zonedEndTime, 'h:mm a'),
        tutoringBlockPersisted: ( tutoringBlock.created_at !== undefined && tutoringBlock.id !== null && tutoringBlock.id.length !== 0) ? true : false
      }

      this.tutoringBlockListTarget.innerHTML += listItemTemplate(templateData)
    })

    if (this.tutoringBlocksValue.length > 0) {
      this.tutoringBlockTableTarget.classList.remove(this.visibleClass)
    } else {
      this.tutoringBlockTableTarget.classList.add(this.visibleClass)
    }
  }
}
