import React from "react";
import { useParams } from "react-router-dom";

import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import StudentHighFrequencyWordsMasteryDrawer from "../StudentHighFrequencyWordsMasteryDrawer";
import { useStudentQuery } from "./queries";
import CheckCircleMini from "assets/icons/check-circle-mini.svg";
import EllipsisHorizontalCircleMini from "assets/icons/ellipsis-horizontal-circle-mini.svg";
import XCircleMini from "assets/icons/x-circle-mini.svg";

const HFWMasteryField = () => {
  const { id: studentId } = useParams();
  const studentQuery = useStudentQuery();
  const {
    masteredHFWCount,
    notYetTaughtHFWCount,
    taughtHFWCount,
    totalHFWCount,
  } = studentQuery.data || {};
  const masteredWordsPercentage = masteredHFWCount * 100.0 / totalHFWCount;
  const taughtWordsPercentage = taughtHFWCount * 100.0 / totalHFWCount;

  return totalHFWCount > 0 ? [
    <Tooltip key="tooltip">
      <TooltipTrigger>
        <div className="max-w-[180px]">
          <div className="bg-zinc-200 rounded-full h-1 relative mb-1 cursor-pointer w-full">
            <span
              className="h-full rounded-full bg-brand-300 absolute"
              style={{ width: `${masteredWordsPercentage + taughtWordsPercentage}%` }}
            />
            <span
              className="h-full rounded-full bg-brand-600 w-1/3 absolute"
              style={{ width: `${masteredWordsPercentage}%` }}
            />
          </div>
          <span>
            {masteredHFWCount}/{totalHFWCount} words
          </span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <ul className="space-y-3.5 pb-1">
          <li className="text-xs text-zinc-600 flex items-center">
            <CheckCircleMini className="w-4 h-4 mr-1 text-brand-600" />
            Mastered: {masteredHFWCount}
          </li>
          <li className="text-xs text-zinc-600 flex items-center">
            <EllipsisHorizontalCircleMini className="w-4 h-4 mr-1 text-brand-300" />
            Taught: {taughtHFWCount}
          </li>
          <li className="text-xs text-zinc-600 flex items-center">
            <XCircleMini className="w-4 h-4 mr-1 text-zinc-500" />
            Not Yet: {notYetTaughtHFWCount}
          </li>
        </ul>
      </TooltipContent>
    </Tooltip>,
    <Drawer key="drawer">
      <DrawerTrigger>
        <button
          className="text-brand-500 text-xs font-medium hover:underline"
          type="button"
        >
          Details
        </button>
      </DrawerTrigger>
      <DrawerContent>
        <StudentHighFrequencyWordsMasteryDrawer studentId={studentId} />
      </DrawerContent>
    </Drawer>
  ] : null;
};

export default HFWMasteryField;
