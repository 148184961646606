import cx from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { useTable } from "./";

const TableHeadCell = ({ className, colSpan, id, isSortable, label }) => {
  const {
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
  } = useTable();
  const onSort = () => {
    setSortBy(id);
    setSortDirection(prevState => {
      if (sortBy === id) {
        return prevState === "asc" ? "desc" : "asc";
      }

      return "asc";
    });
  };

  return (
    <th
      className={twMerge(
        "bg-gray-50 py-3.5 px-4 text-left text-sm font-semibold text-gray-700 whitespace-nowrap sm:pl-6 first:rounded-tl-lg last:rounded-tr-lg",
        className
      )}
      colSpan={colSpan}
      key={id}
      scope="col"
    >
      {isSortable ? (
        <button className="flex items-center space-x-1" onClick={onSort} type="button">
          <span>{label}</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={twMerge(cx("text-gray-400 w-5 h-5 transition-transform invisible", {
            "rotate-180": sortDirection === "desc",
            "visible": sortBy === id,
          }))}>
            <path fillRule="evenodd" d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z" clipRule="evenodd" />
          </svg>
        </button>
      ) : label}
    </th>
  );
};

TableHeadCell.defaultProps = {
  isSortable: false,
};

export default TableHeadCell;
