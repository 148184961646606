import { Controller } from "@hotwired/stimulus"

const expandEvent = new Event("expansion:expand", {bubbles:true});

export default class extends Controller {
  static targets = ["arrow", "stickyHeader", "subMenu"]
  static values = {
    expanded: { type: Boolean, default: false },
    initialLoad: { type: Boolean, default: false },
  }
  static classes = ["expandedArrow", "collapsedArrow", "stickyHeader", "subMenuToggle"]

  connect() {
    // Kind of annoying, but we use initialLoadValue to prevent the
    // expansion menu from flickering between Turbo page reloads
    if (this.initialLoadValue) {
      if (this.expandedValue) {
        this.expand()
      }
      this.initialLoadValue = false
    }
  }

  toggle() {
    if (this.expandedValue) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    if (this.hasArrowTarget) {
      this.arrowTarget.classList.add(...this.expandedArrowClasses)
      this.arrowTarget.classList.remove(...this.collapsedArrowClasses)
    }
    this.subMenuTargets.forEach(target => {
      target.dispatchEvent(expandEvent);
      target.classList.remove(...this.subMenuToggleClasses);
    });
    this.stickyHeaderTargets.forEach(target => {
      target.classList.add(...this.stickyHeaderClasses);
    });
    this.expandedValue = true
  }

  collapse() {
    if (this.hasArrowTarget) {
      this.arrowTarget.classList.add(...this.collapsedArrowClasses)
      this.arrowTarget.classList.remove(...this.expandedArrowClasses)
    }
    this.subMenuTargets.forEach(target => {
      target.classList.add(...this.subMenuToggleClasses);
    });
    this.stickyHeaderTargets.forEach(target => {
      target.classList.remove(...this.stickyHeaderClasses);
    });
    this.expandedValue = false
  }
}
