import range from "lodash/range";
import uniq from "lodash/uniq";
import React from "react";
import Select from "react-select";

import FormSelectOption from "./FormSelectOption";
import { formatTimeToMeridiem, minutesCountToTime, timeToMinutesCount } from "../../utils";

export default ({
  endDate,
  highDemandIntervals,
  maxEndTimeInMinutes,
  minStartTimeInMinutes,
  onChangeEndTime,
  onChangeStartTime,
  onOpenAddAvailabilityForm,
  onRemoveAvailability,
  startDate,
  shouldShowAddButton,
}) => {
  const endTimeInMinutes = timeToMinutesCount(endDate);
  const startTimeInMinutes = timeToMinutesCount(startDate);
  const startTimeIntervalsInMinutes = range(minStartTimeInMinutes, endTimeInMinutes, 15);
  const endTimeIntervalsInMinutes = range(startTimeInMinutes + 15, maxEndTimeInMinutes + 15, 15);
  const startTimeOptions = uniq([
    ...startTimeIntervalsInMinutes,
    timeToMinutesCount(startDate),
  ]).sort((a, b) => a - b).map(interval => {
    const time = minutesCountToTime(interval);

    return {
      label: formatTimeToMeridiem(time),
      value: time,
    }
  });
  const endTimeOptions = uniq([
    ...endTimeIntervalsInMinutes,
     timeToMinutesCount(endDate),
  ]).sort((a, b) => a - b).map(interval => {
    const time = minutesCountToTime(interval);

    return {
      label: formatTimeToMeridiem(time),
      value: time,
    }
  });

  return (
    <div className="flex items-center mb-3">
      <Select
        className="w-[124px] md:w-[134px] mr-2 md:mr-4 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isSearchable={false}
        onChange={onChangeStartTime}
        options={startTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 })
        }}
        value={{
          label: formatTimeToMeridiem(startDate),
          value: startDate,
        }}
      />
      <span className="text-gray-500 text-sm md:text-base">to</span>
      <Select
        className="w-[124px] md:w-[134px] ml-2 md:ml-4 mr-2 md:mr-3 text-sm md:text-base"
        components={{ Option: FormSelectOption }}
        highDemandIntervals={highDemandIntervals}
        isSearchable={false}
        onChange={onChangeEndTime}
        options={endTimeOptions}
        styles={{
          menu: (baseStyles) => ({ ...baseStyles, zIndex: 5 })
        }}
        value={{
          label: formatTimeToMeridiem(endDate),
          value: endDate,
        }}
      />
      <button className="mr-1 md:mr-2 p-1 text-gray-500" onClick={onRemoveAvailability} type="button">
        {/* Heroicon name: outline/trash */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor"
             className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
        </svg>
      </button>
      {shouldShowAddButton ?
        <button className="p-1 text-gray-500" onClick={onOpenAddAvailabilityForm} type="button">
          {/* Heroicon name: outline/plus */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor"
               className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
          </svg>
        </button>
        : null}
    </div>
  )
}
