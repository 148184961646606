import React, { Children, cloneElement, createContext, useContext, useId, useState } from "react";
import { twMerge } from "tailwind-merge";

const TAB_ROLE = "tab";
const TAB_LIST_ROLE = "tablist";
const TAB_PANEL_ROLE = "tabpabel";

const TabsContext = createContext(null);

export const Tabs = ({ children, className }) => {
  const id = useId();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  let panelIndex = 0;

  return (
    <TabsContext.Provider value={{ id, selectedTabIndex, setSelectedTabIndex }}>
      <div className={twMerge("flex flex-col", className)}>
        {Children.map(children, (child, index) => [
          ...(child.type.role === TAB_LIST_ROLE ? [cloneElement(child, { index })] : []),
          ...(child.type.role === TAB_PANEL_ROLE
            ? [cloneElement(child, { index: panelIndex++ })]
            : []),
        ])}
      </div>
    </TabsContext.Provider>
  );
};

export const Tab = ({ children, className, "data-testid": dataTestid, index }) => {
  const { id, selectedTabIndex, setSelectedTabIndex } = useContext(TabsContext);
  const onClick = () => {
    setSelectedTabIndex(index);
  };

  return (
    <button
      aria-controls={`panel-${id}-${index}`}
      aria-selected={selectedTabIndex === index}
      className={twMerge(
        "border-b-2 font-semibold text-sm leading-none px-3.5 py-3.5 -mb-[1px]",
        selectedTabIndex === index
          ? "border-brand-600 text-brand-600"
          : "border-transparent text-zinc-500 hover:border-gray-300",
        className
      )}
      data-testid={dataTestid}
      id={`tab-${id}-${index}`}
      onClick={onClick}
      role={TAB_ROLE}
      tabIndex={0}
      type="button"
    >
      {children}
    </button>
  );
};

export const TabList = ({ children, className }) => (
  <div className={twMerge("flex border-b", className)} role={TAB_LIST_ROLE}>
    {Children.map(children, (child, index) => {
      return child.type.role === TAB_ROLE ? cloneElement(child, { index }) : child;
    })}
  </div>
);

export const TabPanel = ({ children, "data-testid": dataTestid, className, index }) => {
  const { id, selectedTabIndex } = useContext(TabsContext);

  return index === selectedTabIndex ? (
    <div
      aria-labelledby={`tab-${id}-${index}`}
      data-testid={dataTestid}
      className={twMerge(className)}
      id={`panel-${id}-${index}`}
      role={TAB_PANEL_ROLE}
      tabIndex={0}
    >
      {children}
    </div>
  ) : null;
};

Tab.role = TAB_ROLE;
TabList.role = TAB_LIST_ROLE;
TabPanel.role = TAB_PANEL_ROLE;
