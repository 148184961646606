import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "addButton",
    "formTemplate",
    "nonInstructionalDayList"
  ]

  addNonInstructionalDay(event) {
    event.preventDefault()

    const newRecordId = new Date().getTime()
    const nonInstructionalDayForm =
      this.formTemplateTarget.innerHTML.replace(/NEW_RECORD/g, newRecordId)

    this.nonInstructionalDayListTarget.insertAdjacentHTML("beforebegin", nonInstructionalDayForm)
  }

  removeNonInstructionalDay(event) {
    event.preventDefault()
    
    const nonInstructionalDayFields = event.target.closest(".non-instructional-day-fields")

    if (nonInstructionalDayFields.dataset.newRecord == "true") {
      nonInstructionalDayFields.remove()
    } else {
      nonInstructionalDayFields.querySelector("input[name*='_destroy']").value = 1
      nonInstructionalDayFields.style.display = "none"
    }    
  }
}
