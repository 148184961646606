import React from "react";

import QuestionListItem from "./QuestionListItem";

export default ({ questions, taskId, checkboxes=true }) => (
  <ol className="list-decimal pl-4 text-sm font-medium divide-y divide-gray-200">
    {questions.map(question => (
      <li key={question.id}>
        <QuestionListItem taskId={taskId} checkboxes={checkboxes} {...question} />
      </li>
    ))}
  </ol>
);
