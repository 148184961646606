import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prevent-empty-submit"
export default class extends Controller {
  static targets = ["submitButton"];

  updateSubmitButton(event) {
    this.submitButtonTarget.disabled = event.target.value.trim().length <= 0;
  }
}
