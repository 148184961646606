import { format, intervalToDuration } from "date-fns";
import pluralize from "pluralize";
import React from "react";

import { ModalClose } from "../common/Modal";
import { MONTH_DAY_YEAR_DATE_FORMAT } from "../../constants";
import { useTutorTimeOffDetailQuery } from "./queries";

const TimeOffItemModal = ({
  createdAt,
  currentDayCountForTimeOff,
  currentYearLabel,
  endDate,
  firstName,
  lastName,
  maxAllowedDayCountForTimeOff,
  reason,
  startDate,
  uuid
}) => {
  const query = useTutorTimeOffDetailQuery({uuid});
  const duration = intervalToDuration({
    start: 0,
    end: query.data ? query.data.impactedDurationInMinutes * 60 * 1000 : 0,
  });

  return (
    <div className="w-full">
      <ModalClose
        className="ring-0 p-1 enabled:shadow-none absolute top-2 right-2"
        data-testid="tutor-time-off-request-info-modal-close-button"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </ModalClose>
      <div className="flex items-center border-b border-gray-200 text-gray-800 space-x-2.5 pb-5">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
        </svg>
        <div>
          <h1 className="text-lg/tight font-semibold">{firstName} {lastName} Time Off Request</h1>
          <div className="space-y-2">
            <span className="text-lg font-semibold text-gray-700">Requested on </span>
            <span className="text-lg text-gray-700">{format(createdAt, MONTH_DAY_YEAR_DATE_FORMAT)}</span>
          </div>
        </div>
      </div>
      <ul className="divide-y divide-gray-200">
        <li className="flex justify-between py-5">
          <div className="space-y-2">
            <h2 className="text-sm text-gray-500">When</h2>
            <dl className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-y-3 gap-x-10">
              <dt className="text-lg font-semibold text-gray-700">Start date</dt>
              <dd className="text-base text-gray-700">
                {format(startDate, MONTH_DAY_YEAR_DATE_FORMAT)}
              </dd>
              <dt className="text-lg font-semibold text-gray-700">End date</dt>
              <dd className="text-base text-gray-700">
                {format(endDate, MONTH_DAY_YEAR_DATE_FORMAT)}
              </dd>
            </dl>
          </div>
          {(query.data?.impactedSessionCount && query.data?.impactedDurationInMinutes && query.data?.impactedStudentCount) ? (
            <div className="border border-gray-200 rounded basis-1/3 shrink-0 p-2 self-start flex flex-col">
              <span className="text-xs/none text-gray-400">Total of</span>
              <ul className="flex items-center justify-center space-x-4 py-4">
                <li className="text-xs text-gray-400">
                  <span className="text-2xl font-bold text-gray-600">{query.data?.impactedSessionCount}</span> {pluralize("session", query.data?.impactedSessionCount, false)}
                </li>
                <li className="text-xs text-gray-400">
                  {duration.hours > 0 ? (
                    <>
                      <span className="text-2xl font-bold text-gray-600">{duration.hours}</span>h{" "}
                    </>
                  ) : null}
                 <span className="text-2xl font-bold text-gray-600">{duration.minutes}</span>m
                </li>
              </ul>
              <ul className="flex items-center justify-center space-x-4 pb-4">
                <li className="text-xs text-gray-400">
                  <span className="text-2xl font-bold text-gray-600">{query.data?.impactedStudentCount}</span> {pluralize("student", query.data?.impactedStudentCount, false)}
                </li>
              </ul>
            </div>
          ) : (
            <div className="border border-gray-200 rounded basis-1/3 shrink-0 p-2 self-start flex flex-col">
              <span className="text-xs/none text-gray-400">Total of</span>
              <ul className="flex items-center justify-center space-x-4 py-4 min-h-[112px]">
                <li className="text-xs/none text-gray-400">
                  Loading...
                </li>
              </ul>
            </div>
          )}
        </li>
        <li className="flex justify-between py-5">
          <div className="space-y-2">
            <h2 className="text-sm text-gray-500">Notes</h2>
            <p className="text-base text-gray-700">{reason ?? "-"}</p>
          </div>
          {(currentDayCountForTimeOff && maxAllowedDayCountForTimeOff) ?
            <div className="border border-gray-200 rounded basis-1/3 shrink-0 p-2 self-start flex flex-col">
              <div className="text-xs/none text-gray-400 flex items-center justify-between">
                <span>Days used</span>
                <span>{currentYearLabel}</span>
              </div>
              <div className="flex items-end justify-center text-xs text-gray-400 py-4">
                <ul className="flex items-center justify-center space-x-4 py-4">
                  <li className="text-xs text-gray-400">
                    <span className="text-2xl font-bold text-gray-600">{currentDayCountForTimeOff}</span> {pluralize("day", currentDayCountForTimeOff, false)}
                  </li>
                </ul>
              </div>
            </div>
          : null}
        </li>
      </ul>
    </div>
  );
}

export default TimeOffItemModal;
