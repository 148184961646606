import { get, patch } from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";

import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import useStudentAssessmentContext from "./useStudentAssessmentContext";

export const useSetStudentAssessmentQueryOnSuccess = () => {
  const queryClient = useQueryClient();
  const { queryKey } = useStudentAssessmentContext();

  return useCallback(
    (data) => {
      queryClient.setQueryData(queryKey, data);
    },
    [queryClient, queryKey]
  );
};

export const useStudentAssessmentQuery = ({ enabled = false, select = null } = {}) => {
  const paths = useContext(RailsPathsContext);
  const { queryKey } = useStudentAssessmentContext();

  return useQuery({
    enabled,
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await get(paths.index, { signal });

      return response.data;
    },
    select,
  });
};

export const useStartAssessmentMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async () => {
      const response = await patch(paths.startAssessment);

      return response.data;
    },
    onSuccess,
  });
};

export const useCompleteAssessmentMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async (nextGoal) => {
      const response = await patch(paths.completeAssessment, {
        next_goal: nextGoal,
      });

      return response.data;
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export const useResetAssessmentMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async () => {
      const response = await patch(paths.resetAssessment);

      return response.data;
    },
    onSuccess,
  });
};

export const useUpdateSecondsRemainingMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async (secondsRemaining) => {
      const response = await patch(paths.updateSecondsRemaining, {
        seconds_remaining: secondsRemaining,
      });

      return response.data;
    },
    onSuccess,
  });
};

export const useUpdateGoalMutation = () => {
  const paths = useContext(RailsPathsContext);
  const onSuccess = useSetStudentAssessmentQueryOnSuccess();

  return useMutation({
    mutationFn: async (goal) => {
      const response = await patch(paths.updateGoal, { goal });

      return response.data;
    },
    onSuccess,
  });
};
