import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, patch, post } from "../../api";

export const useNextLessonCardQuery = () => {
  const { id: studentId, studentAssessmentId } = useParams();

  return useQuery({
    queryKey: ["next-lesson-card", studentId, studentAssessmentId],
    queryFn: async () => {
      const response = await get(
        `/students/${studentId}/student_assessments/${studentAssessmentId}/next_card`
      );

      return response.data;
    },
    keepPreviousData: true,
    staleTime: Infinity,
  });
};

export const useContinueAssessmentMutation = () => {
  const { id: studentId, studentAssessmentId } = useParams();

  return useMutation({
    mutationFn: async (path) => {
      const response = await patch(path);

      return response.data;
    },
    onSuccess: (data) => {
      window.__SPARK_TUTOR_APP_QUERY_CLIENT.setQueryData(
        ["next-lesson-card", studentId, studentAssessmentId],
        data
      );
    },
  });
};

export const useSubmitAssessmentMutation = () => {
  const { id: studentId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (studentAssessmentSectionId) => {
      const response = await post(`/students/${studentId}/student_assessment_progressions`, {
        student_assessment_progression: {
          student_assessment_section_id: studentAssessmentSectionId,
        },
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["student", studentId] });
      queryClient.invalidateQueries({ queryKey: ["protocol-list", studentId] });
    },
  });
};
