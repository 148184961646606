import isFunction from "lodash/isFunction";
import React from "react";

import Skeleton from "../common/Skeleton";
import { useStudentQuery } from "./queries";

const defaultSkeleton = (
  <Skeleton className="mt-1 py-1" height="20" width="140">
    <Skeleton.Rect x="0" y="0" width="140" height="14" rx="3" />
  </Skeleton>
);
const HeaderField = ({
  children,
  "data-testid": dataTestid,
  label,
  skeleton = defaultSkeleton,
}) => {
  const studentQuery = useStudentQuery();

  return (
    <div data-testid={dataTestid}>
      <dt className="font-medium text-gray-500" key="key">
        {label}
      </dt>
      <dd className="mt-1 text-gray-900" data-heap-redact-text key="value">
        {studentQuery.data
          ? isFunction(children)
            ? children(studentQuery.data)
            : children
          : skeleton}
      </dd>
    </div>
  );
};

export default HeaderField;
