import React, { forwardRef } from "react";

import { useFloatingContext } from "./context";

export default forwardRef(({ children, className, onClick: onClickCallback, ...props }, ref) => {
  const { setOpen } = useFloatingContext();
  const onClick = () => {
    setOpen(false);
    if (onClickCallback) {
      onClickCallback();
    }
  };

  return (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
});
