import React from "react";

import { PagyNavigation } from "../common/Pagy";
import CreateNoteModal from "./CreateNoteModal";
import DeleteNote from "./DeleteNote";
import { useNotesQuery } from "./queries";

const Notes = ({ allowDeletion }) => {
  const notesQuery = useNotesQuery();

  return notesQuery.isLoading ? (
    <div className="grow flex flex-col items-center justify-center bg-gray-50 rounded-lg py-10 px-4 mt-4">
      <p className="text-gray-500">Loading...</p>
    </div>
  ) : notesQuery.data.notes.length > 0 ? (
    <div className="w-full">
      <div className="ml-1">
        <ul className="divide-y divide-gray-200 px-4 md:px-0" data-testid="notes-list">
          {notesQuery.data.notes.map((note) => (
            <li key={note.id} className="py-5 md:px-4 lg:px-6 group relative">
              {allowDeletion &&
                <DeleteNote noteId={note.id} />
              }
              <p className="mb-2 text-base text-gray-900 whitespace-pre-wrap">
                {note.content}
              </p>
              <div className="text-sm text-gray-400 opacity-40 group-hover:opacity-100">
                by{" "}
                <span className="font-medium text-gray-500" data-heap-redact-text>
                  {note.firstName} {note.lastName}
                </span>{" "}
                on{" "}
                <span className="font-medium text-gray-500">
                  {note.date}
                </span>{" "}
                at{" "}
                <span className="font-medium text-gray-500">
                  {note.time}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {(notesQuery.data?.pagy?.pages || 0) > 1 ? <PagyNavigation query={notesQuery} /> : null}
    </div>
  ) : (
    <div className="grow flex flex-col items-center justify-center flex-1 bg-gray-50 rounded-lg py-10 px-4">
      <h5 className="font-medium mb-4 text-gray-600">There are no notes yet.</h5>
      <CreateNoteModal studentId={notesQuery.data.studentId} />
    </div>
  );
};

export default Notes;
