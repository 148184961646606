import React from "react";

const Header = ({ fullName, timeZoneName }) => (
  <div className="flex justify-between">
    <div>
      <h1 className="text-xl md:text-2xl font-semibold text-zinc-900">Students</h1>
      <p className="mt-2 text-xs md:text-sm text-zinc-700">
        Students assigned to <span data-heap-redact-text>{fullName}</span>
      </p>
    </div>
    <div className="md:mt-4 flex flex-col-reverse md:flex-row items-end md:items-center md:space-x-3">
      <p className="text-zinc-500 text-xs whitespace-nowrap	pl-1">
        <em>All times are {timeZoneName}</em>
      </p>
    </div>
  </div>
);

export default Header;
