import { parse } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { get, patch } from "../../api";
import { DEFAULT_DATE_FORMAT } from "../../constants";
import { useCurrentPagyPage } from "../common/Pagy";
import { useTable } from "../common/Table";
import { FilterByDateContext } from "./";

export const useTutorTimeOffQuery = () => {
  const { sortBy: sort, sortDirection: direction } = useTable();
  const {
    filterEndDate: end,
    filterStartDate: start,
  } = useContext(FilterByDateContext);
  const page = useCurrentPagyPage();

  return useQuery({
    queryKey: ["tutor-time-off", page, sort, direction, start, end],
    queryFn: async ({ signal }) => {
      const response = await get("/time_off", {
        params: {
          direction,
          end: isEmpty(end) ? undefined : end,
          page,
          sort,
          start: isEmpty(start) ? undefined : start,
        },
        signal,
      });
      const tutorTimeOff = response.data.tutorTimeOff.map(timeOff => ({
        ...timeOff,
        endDate: parse(timeOff.endDate, DEFAULT_DATE_FORMAT, new Date()),
        startDate: parse(timeOff.startDate, DEFAULT_DATE_FORMAT, new Date()),
        createdAt: parse(timeOff.createdAt, DEFAULT_DATE_FORMAT, new Date()),
      }));

      return {
        tutorTimeOff,
        pagy: response.data.pagy,
      };
    },
    keepPreviousData: true,
  });
};

export const useTutorTimeOffDetailQuery = ({ uuid }) => {
  return useQuery({
    queryKey: ["tutor-time-off-detail", uuid],
    queryFn: async () => {
      const response = await get(`/time_off/${uuid}/details`);
      const timeOff = response.data;

      return timeOff;
    },
    keepPreviousData: true,
  });
};

export const useCancelTimeOffMutation = ({ uri }) => {
  const queryClient = useQueryClient();
  const { sortBy: sort, sortDirection: direction } = useTable();
  const {
    filterEndDate: end,
    filterStartDate: start,
  } = useContext(FilterByDateContext);
  const page = useCurrentPagyPage();

  return useMutation({
    mutationFn: async () => await patch(uri),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["tutor-time-off", page, sort, direction, start, end],
        (prevData) => {
          return {
            ...prevData,
            tutorTimeOff: prevData.tutorTimeOff.map(tutorTimeOff => {
              return tutorTimeOff.uuid === data.uuid ? ({
                ...tutorTimeOff,
                ...data,
                endDate: parse(data.endDate, DEFAULT_DATE_FORMAT, new Date()),
                startDate: parse(data.startDate, DEFAULT_DATE_FORMAT, new Date()),
                createdAt: parse(data.createdAt, DEFAULT_DATE_FORMAT, new Date()),
              }) : tutorTimeOff;
            }),
          };
        }
      );
    },
  });
};
