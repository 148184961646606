import React from "react";
import { DATE_WITH_TIME } from "../../constants";
import { format, parseISO } from "date-fns";

const ResetHistoryItem = ({ idx, reason, whodunnit, createdAt }) => (
  <p className="px-12 py-2 text-gray-500 whitespace-normal break-words opacity-60 group-hover:opacity-100">
    <span className="text-gray-400">
      Assessment reset {idx} -&nbsp;
    </span>
    <span className="text-gray-800">
      {reason}
    </span>
    <span className="text-gray-400">
      &nbsp;- by {whodunnit} - {format(parseISO(createdAt), DATE_WITH_TIME)}
    </span>
  </p>
);

export default ResetHistoryItem;
