import cx from "classnames";
import times from "lodash/times";
import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";

import { PagyNavigation } from "../Pagy";
import Skeleton from "../Skeleton";
import { useTable } from "./";
import TableHeadCell from "./TableHeadCell";

const TableWrapper = ({
  children,
  columns,
  "data-testid": dataTestid,
  defaultSortKey,
  defaultSortDirection,
  loadingRowCount = 8,
  query,
}) => {
  const { setSortBy, setSortDirection } = useTable();
  const isLoading = !query.data && !query.isError;

  useEffect(() => {
    setSortBy(defaultSortKey);
  }, [defaultSortKey, setSortBy]);
  useEffect(() => {
    setSortDirection(defaultSortDirection);
  }, [defaultSortDirection, setSortDirection]);

  return [
    <table
      aria-busy={isLoading}
      className="min-w-full xl:divide-y xl:divide-gray-300 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg relative"
      data-testid={dataTestid}
      key="table"
    >
      <thead className="bg-zinc-50 hidden xl:table-header-group">
        <tr>
          {columns.map((column) => (
            <TableHeadCell key={column.id} {...column} />
          ))}
        </tr>
      </thead>
      <tbody
        aria-valuetext={isLoading ? "Loading..." : null}
        className={twMerge(
          cx("divide-y divide-gray-200 relative", {
            "after:content-[''] after:absolute after:top-0 after:-bottom-[4px] after:-left-[4px] after:-right-[4px] after:rounded-[inherit] after:bg-gradient-to-b after:from-white/0 after:to-white":
              isLoading,
          })
        )}
        role={isLoading ? "progressbar" : null}
      >
        {!query.data ? (
          query.isError ? (
            <tr>
              <td
                className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg"
                colSpan={columns.length}
              >
                <p className="flex items-center justify-center grow text-red-600 min-h-[120px]">
                  Error: {query.error.message}
                </p>
              </td>
            </tr>
          ) : (
            times(loadingRowCount).map((rowIndex) => (
              <tr key={rowIndex}>
                {times(columns.length).map((columnIndex) => (
                  <td
                    className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg max-w-0"
                    key={columnIndex}
                  >
                    <Skeleton className="py-1" width="100%" height="20">
                      <Skeleton.Rect x="0" y="0" rx="3" ry="3" width="66%" height="100%" />
                    </Skeleton>
                  </td>
                ))}
              </tr>
            ))
          )
        ) : (
          children(query.data)
        )}
      </tbody>
    </table>,
    <PagyNavigation key="pagination" query={query} />,
  ];
};

TableWrapper.defaultProps = {
  defaultSortDirection: "asc",
};

export default TableWrapper;
