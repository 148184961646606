import React, { useCallback, useContext, useRef, useState } from "react";

import { useClaimSubSessionRequestMutation } from "./queries";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import { toast } from "react-toastify";

const SubSessionRequestClaim = ({ programEnrollmentId, startTime }) => {
  const { setSelectedTimeBlock } = useContext(SelectedTimeBlockContext);
  const toastIdRef = useRef();
  const [isProcessing, setIsProcessing] = useState(false);
  const mutation = useClaimSubSessionRequestMutation({
    programEnrollmentId,
    startTime,
  });
  const onClick = useCallback(() => {
    toastIdRef.current = toast.loading("Claiming session...");
    setIsProcessing(true);
    mutation.mutate(null, {
      onSuccess: (response) => {
        const errors = response.data.errors;
        if(errors.length === 0) {
          toast.update(toastIdRef.current, {
            autoClose: 1600,
            isLoading: false,
            render: "Session claimed.",
            type: "success",
          });
        } else {
          toast.update(toastIdRef.current, {
            autoClose: 3200,
            isLoading: false,
            render: errors.join(" "),
            type: "error",
          });
        }
      },
      onError: () => {
        toast.update(toastIdRef.current, {
          autoClose: 3200,
          isLoading: false,
          render: "There was a problem claiming the session.",
          type: "error",
        });
      },
      onSettled: () => {
        setIsProcessing(false);
        setSelectedTimeBlock(null);
      }
    });
  }, [mutation, setSelectedTimeBlock]);

  return (
    <button
      className="button-primary"
      data-testid={`tutor-calendar-sub-session-request-claim-for-program-enrollment-${programEnrollmentId}`}
      onClick={onClick}
      type="submit"
      disabled={isProcessing}
    >
      Claim
    </button>
  );
};

export default SubSessionRequestClaim;
