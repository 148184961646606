import { format } from "date-fns";
import React, { useContext } from "react";

import {
  Popover,
  PopoverContent,
  PopoverPositionReference,
  PopoverTrigger
} from "../common/Popover";
import SubSessionRequestsList from "./SubSessionRequestsList";
import { SelectedTimeBlockContext } from "../../contexts/TutorCalendar";
import { MERIDIEM_TIME_FORMAT } from "../../constants";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";

const DailySubSessionRequestItem = ({ endTime, sessions, startTime }) => {
  const { isSubMode } = useCalendarViewMode();
  const {
    selectedTimeBlock,
    setSelectedTimeBlock,
  } = useContext(SelectedTimeBlockContext);
  const onOpenChange = (open) => {
    if (!isSubMode) {
      setSelectedTimeBlock(open ? startTime.toString() : null);
    }
  };

  return (
    <Popover
      onOpenChange={onOpenChange}
      open={selectedTimeBlock === startTime.toString()}
      placement="right"
    >
      <PopoverTrigger discardPositionReference>
        <div
          className="bg-orange-100 border-orange-300 hover:bg-orange-200/60 flex items-center justify-between rounded-full py-1 pl-2.5 pr-1 border cursor-pointer relative"
          data-testid={`tutor-calendar-daily-view-sub-session-requests-for-${format(startTime, MERIDIEM_TIME_FORMAT)}`}
        >
          <PopoverPositionReference>
            <div className="flex items-center min-w-0">
              <h3 className="mr-2.5 text-[11px] md:text-[13px] truncate">
                <span className="inline-block w-[110px] md:w-[134px] text-yellow-800/70 font-light mr-2.5 text-right">
                  {format(startTime, MERIDIEM_TIME_FORMAT)} - {format(endTime, MERIDIEM_TIME_FORMAT)}
                </span>
                <span className="text-[12px] md:text-[14px] text-yellow-800 font-medium">
                  Sub Opportunity
                </span>
              </h3>
              <div className="bg-yellow-300 flex items-center justify-center rounded-full w-[16px] h-[16px] mr-1 text-xs font-semibold text-yellow-700">
                {sessions.length}
              </div>
            </div>
          </PopoverPositionReference>
          {selectedTimeBlock === startTime.toString() ?
            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-full bg-sky-500/10 outline outline-sky-500/50 outline-offset-1 pointer-events-none" />
          : null}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <SubSessionRequestsList sessions={sessions} />
      </PopoverContent>
    </Popover>
  );
};

export default DailySubSessionRequestItem;
