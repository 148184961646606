import React from "react";

import { resourceType, useStudentAssessmentContext } from "./";

export default ({ onChange, value }) => {
  const { resource } = useStudentAssessmentContext()

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-4 pb-6 space-y-3 mb-8">
      <h5 className="flex items-center text-lg font-medium text-zinc-700 space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 33 33" className="w-7 h-7">
          <path d="M27.0962 9.50004H24.6148L18.5268 15.5854C18.7365 16.1198 18.7558 16.7101 18.5814 17.257C18.4071 17.8039 18.0497 18.2741 17.5694 18.5886C17.0892 18.903 16.5153 19.0425 15.9442 18.9835C15.3732 18.9246 14.8399 18.6708 14.434 18.2649C14.0281 17.859 13.7743 17.3257 13.7154 16.7546C13.6564 16.1836 13.7959 15.6097 14.1103 15.1294C14.4247 14.6492 14.895 14.2918 15.4419 14.1174C15.9888 13.9431 16.5791 13.9624 17.1135 14.172L23.2002 8.08537V5.60404C23.2003 5.27201 23.3322 4.9536 23.5668 4.71871L25.8735 2.41204C25.9161 2.36895 25.9697 2.33835 26.0284 2.32354C26.0872 2.30873 26.1489 2.31029 26.2068 2.32804C26.3255 2.36271 26.4135 2.46137 26.4375 2.58271L27.0508 5.65071L30.1175 6.26404C30.2375 6.28804 30.3362 6.37604 30.3708 6.49471C30.3883 6.55235 30.3899 6.61366 30.3753 6.67212C30.3608 6.73058 30.3307 6.784 30.2882 6.82671L27.9802 9.13337C27.8641 9.24959 27.7264 9.34179 27.5747 9.40471C27.423 9.46763 27.2604 9.50002 27.0962 9.50004Z" fill="#344054" />
          <path d="M3.7002 16.5C3.7002 19.8152 5.01716 22.9946 7.36136 25.3388C9.70557 27.683 12.885 29 16.2002 29C17.8417 29 19.4672 28.6767 20.9837 28.0485C22.5003 27.4203 23.8783 26.4996 25.039 25.3388C26.1998 24.1781 27.1205 22.8001 27.7487 21.2836C28.3769 19.767 28.7002 18.1415 28.7002 16.5C28.7002 15.3027 28.5322 14.1453 28.2189 13.0507C28.1566 12.7993 28.1942 12.5336 28.3239 12.3095C28.4535 12.0853 28.6651 11.9202 28.914 11.8489C29.1629 11.7775 29.4298 11.8055 29.6585 11.927C29.8872 12.0484 30.0599 12.2538 30.1402 12.5C30.5055 13.772 30.7002 15.1133 30.7002 16.5C30.7002 24.508 24.2082 31 16.2002 31C8.1922 31 1.7002 24.508 1.7002 16.5C1.7002 8.49201 8.1922 2.00001 16.2002 2.00001C17.5535 1.99867 18.8989 2.18667 20.2002 2.55867C20.3271 2.59427 20.4457 2.65459 20.5492 2.73617C20.6527 2.81775 20.739 2.91898 20.8032 3.03404C20.8675 3.14911 20.9084 3.27573 20.9235 3.40664C20.9386 3.53755 20.9277 3.67016 20.8915 3.79685C20.8552 3.92353 20.7942 4.0418 20.7121 4.14485C20.6299 4.24789 20.5282 4.33369 20.4128 4.3973C20.2974 4.46091 20.1705 4.50108 20.0396 4.5155C19.9086 4.52993 19.776 4.51832 19.6495 4.48134C18.5278 4.16098 17.3668 3.99897 16.2002 4.00001C12.885 4.00001 9.70557 5.31697 7.36136 7.66117C5.01716 10.0054 3.7002 13.1848 3.7002 16.5Z" fill="#344054" />
          <path d="M9.70001 16.5C9.70374 17.3831 9.88741 18.2562 10.2398 19.0659C10.5922 19.8757 11.106 20.6051 11.7497 21.2097C12.3934 21.8142 13.1536 22.2813 13.9838 22.5822C14.8141 22.8832 15.6969 23.0118 16.5785 22.9602C17.4601 22.9086 18.3219 22.6778 19.1114 22.282C19.9008 21.8862 20.6013 21.3336 21.17 20.6581C21.7388 19.9825 22.1639 19.1981 22.4194 18.3527C22.6749 17.5074 22.7554 16.6189 22.656 15.7414C22.628 15.5636 22.6486 15.3817 22.7156 15.2147C22.7826 15.0477 22.8935 14.902 23.0366 14.7929C23.1797 14.6839 23.3496 14.6156 23.5284 14.5952C23.7071 14.5749 23.8881 14.6033 24.052 14.6774C24.3867 14.8267 24.612 15.1454 24.6413 15.5107C24.8484 17.2644 24.5039 19.039 23.6559 20.5878C22.8078 22.1367 21.4983 23.3829 19.9093 24.1532C18.3203 24.9235 16.5309 25.1796 14.7896 24.8859C13.0483 24.5923 11.4418 23.7635 10.1933 22.5147C8.94521 21.2673 8.11618 19.6623 7.82125 17.9225C7.52631 16.1827 7.78009 14.3942 8.54738 12.8051C9.31467 11.2161 10.5574 9.90512 12.1033 9.05414C13.6492 8.20316 15.4216 7.85433 17.1747 8.05603C17.3072 8.06832 17.4359 8.10695 17.5534 8.16965C17.6708 8.23234 17.7745 8.31784 17.8584 8.42114C17.9423 8.52444 18.0048 8.64345 18.0421 8.7712C18.0795 8.89895 18.0909 9.03287 18.0758 9.16511C18.0608 9.29735 18.0194 9.42524 17.9543 9.5413C17.8891 9.65736 17.8014 9.75924 17.6964 9.84097C17.5914 9.92271 17.4711 9.98265 17.3425 10.0173C17.214 10.0519 17.0799 10.0606 16.948 10.0427C16.0374 9.93741 15.1148 10.0259 14.2408 10.3023C13.3667 10.5788 12.5611 11.0369 11.8766 11.6467C11.1922 12.2565 10.6444 13.0042 10.2693 13.8406C9.89415 14.677 9.70015 15.5833 9.70001 16.5Z" fill="#344054" />
        </svg>
        <label htmlFor="nextGoal">Set Next Session Goal</label>
      </h5>
      <div className="flex items-end space-x-1.5 pl-9">
        <input
          className="rounded-md border border-zinc-300 text-sm w-20"
          onChange={onChange}
          type="number"
          value={value}
        />
        <abbr className="uppercase text-[10px]" title={`${resource === resourceType.WORD ? "Words" : "Letters"} Correct Per Minute`}>
          {resource === resourceType.WORD ? "WCPM" : "LCPM"}
        </abbr>
      </div>
    </div>
  );
}
