import cx from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { RIPPLING_URL, SHORT_MONTH_AND_DAY } from "../../constants";
import { Modal, ModalClose, ModalContent, ModalTrigger } from "../common/Modal";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import TimeOffItemModal from "./TimeOffItemModal";
import {
  APPROVED,
  CANCELLED,
  DENIED,
  PENDING
} from "../../constants/TutorTimeOffStatus";
import { useCancelTimeOffMutation } from "./queries";

const statusToLabelMap = {
  [APPROVED]: "Approved",
  [DENIED]: "Denied",
  [PENDING]: "Pending",
  [CANCELLED]: "Cancelled",
};
const TimeOffItem = ({
  cancelUri,
  createdAt,
  currentDayCountForTimeOff,
  currentYearLabel,
  endDate,
  firstName,
  lastName,
  maxAllowedDayCountForTimeOff,
  reason,
  startDate,
  status,
  updateUri,
  uuid,
}) => {
  const mutation = useCancelTimeOffMutation({ uri: cancelUri });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCancel = (event) => {
    mutation.mutate(null, {
      onSuccess: () => {
        toast("Time off request cancelled.", {
          autoClose: 3200,
          isLoading: false,
        });
      },
      onError: () => {
        toast("There was a problem denying time off. Please try again.", {
          autoClose: 3200,
          isLoading: false,
          type: "error",
        });
      },
    });
    event.preventDefault();
  };

  return (
    <tr className="group">
      <td
        className={twMerge(cx("bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-b-lg", {
          "bg-gray-50": status === CANCELLED,
        }))}
      >
        <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
          <Tooltip hide={isModalOpen}>
            <TooltipTrigger>
              <ModalTrigger>
                <button
                  className={twMerge(cx("button-as-link flex items-center space-x-1.5", {
                    "opacity-80": status === CANCELLED,
                  }))}
                  data-testid={`time-off-request-${uuid}-reason-tooltip`}
                >
                  <span>
                    {format(startDate, SHORT_MONTH_AND_DAY)} - {format(endDate, SHORT_MONTH_AND_DAY)}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.75 8a.75.75 0 0 0 0 1.5h.75v1.75a.75.75 0 0 0 1.5 0v-2.5A.75.75 0 0 0 8.25 8h-1.5Z" clipRule="evenodd" />
                  </svg>
                </button>
              </ModalTrigger>
            </TooltipTrigger>
            {reason ? (
              <TooltipContent className="max-w-[280px] text-left">
                <div className="space-y-1.5">
                  <h6 className="font-semibold">{firstName} {lastName} Time Off Notes</h6>
                  <p>{reason}</p>
                </div>
              </TooltipContent>
            ) : null}
          </Tooltip>
          <ModalContent className="w-full md:max-w-[700px] flex items-center justify-center">
            <TimeOffItemModal
              createdAt={createdAt}
              currentDayCountForTimeOff={currentDayCountForTimeOff}
              currentYearLabel={currentYearLabel}
              endDate={endDate}
              firstName={firstName}
              lastName={lastName}
              maxAllowedDayCountForTimeOff={maxAllowedDayCountForTimeOff}
              reason={reason}
              startDate={startDate}
              status={status}
              uri={updateUri}
              uuid={uuid}
            />
          </ModalContent>
        </Modal>
      </td>
      <td
        className={twMerge(cx("bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 text-center", {
          "bg-gray-50 text-gray-500": status === CANCELLED,
        }))}
      >
        <span
          className={twMerge(cx("text-gray-500/80 font-medium text-xs bg-white border border-gray-200 rounded-full py-1 px-2", {
            "bg-gray-200/70 border-gray-300": status === CANCELLED,
            "bg-green-100 border-green-200 text-green-600": status === APPROVED,
            "bg-red-100 border-red-200 text-red-600": status === DENIED,
          }))}
        >
          {statusToLabelMap[status]}
        </span>
      </td>
      <td
        className={twMerge(cx("py-0 pl-3 pr-4 space-x-4 text-right min-w-[100px]", {
          "bg-gray-50 text-gray-500": status === CANCELLED,
        }))}
      >
        {status === PENDING ? (
          <Modal>
            <ModalTrigger>
              <button
                className="button-secondary"
                data-testid={`time-off-request-${uuid}-cancel-button`}
                type="button"
              >
                Cancel
              </button>
            </ModalTrigger>
            <ModalContent data-testid={`time-off-request-${uuid}-cancel-confirmation-modal`}>
              <form className="divide-y divide-gray-200" onSubmit={onCancel}>
                <div className="pb-5 flex items-center">
                  <svg viewBox="0 0 26 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="w-7 h-7 text-yellow-500 mr-4">
                    <path d="M8.99998 7.00016H17V21.0002H8.99998V7.00016Z" fill="black"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.75123 2.25391C11.195 -0.246094 14.805 -0.246094 16.2475 2.25391L25.4412 18.1889C26.8837 20.6889 25.0787 23.8139 22.1925 23.8139H3.80623C0.919979 23.8139 -0.883771 20.6889 0.558729 18.1889L9.74998 2.25391H9.75123ZM13 8.81266C13.2486 8.81266 13.4871 8.91143 13.6629 9.08724C13.8387 9.26306 13.9375 9.50152 13.9375 9.75016V14.4377C13.9375 14.6863 13.8387 14.9248 13.6629 15.1006C13.4871 15.2764 13.2486 15.3752 13 15.3752C12.7513 15.3752 12.5129 15.2764 12.3371 15.1006C12.1613 14.9248 12.0625 14.6863 12.0625 14.4377V9.75016C12.0625 9.50152 12.1613 9.26306 12.3371 9.08724C12.5129 8.91143 12.7513 8.81266 13 8.81266ZM13 19.1252C13.2486 19.1252 13.4871 19.0264 13.6629 18.8506C13.8387 18.6748 13.9375 18.4363 13.9375 18.1877C13.9375 17.939 13.8387 17.7006 13.6629 17.5247C13.4871 17.3489 13.2486 17.2502 13 17.2502C12.7513 17.2502 12.5129 17.3489 12.3371 17.5247C12.1613 17.7006 12.0625 17.939 12.0625 18.1877C12.0625 18.4363 12.1613 18.6748 12.3371 18.8506C12.5129 19.0264 12.7513 19.1252 13 19.1252Z" fill="currentColor"/>
                  </svg>
                  <h3 className="font-semibold text-lg text-zinc-700">Cancel time off request</h3>
                </div>
                <div className="text-sm text-zinc-700 py-5">
                  <p>Are you sure you want to cancel the time off request for <strong>{format(startDate, SHORT_MONTH_AND_DAY)} - {format(endDate, SHORT_MONTH_AND_DAY)}</strong>?</p>
                </div>
                <div className="flex justify-end pt-5 space-x-3">
                  <ModalClose>Go back</ModalClose>
                  <button
                    className="button-primary"
                    data-testid={`time-off-request-${uuid}-cancel-confirmation-modal-confirmation-button`}
                    type="submit"
                  >
                    Cancel time off request
                  </button>
                </div>
              </form>
            </ModalContent>
          </Modal>
        ) : (status === APPROVED ?
          <a
            className="button-primary inline-block whitespace-nowrap"
            data-testid={`time-off-request-${uuid}-rippling-button`}
            href={RIPPLING_URL}
            target="_blank"
            rel="noreferrer"
          >
            Update In Rippling
          </a> : null
        )}
      </td>
    </tr>
  );
};

export default TimeOffItem;
