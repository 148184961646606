import cx from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const TutorStudentListItemLink = ({
  children,
  className: classNameFromProps,
  "data-testid": dataTestid,
  "data-title": dataTitle,
  disabled,
  studentId,
}) => {
  const className = [
    "text-zinc-900 text-xs xl:text-sm block truncate w-full xl:min-w-0",
    "before:content-[attr(data-title)] before:font-medium before:text-zinc-500 xl:before:hidden before:mr-0.5",
  ];

  return disabled ? (
    <div
      className={twMerge(cx(className, classNameFromProps))}
      data-testid={dataTestid}
      data-title={dataTitle}
    >
      {children}
    </div>
  ) : (
    <Link
      className={twMerge(cx(className, { "group-hover:bg-gray-50": !disabled }, classNameFromProps))}
      data-testid={dataTestid}
      data-title={dataTitle}
      to={`/students/${studentId}`}
    >
      {children}
    </Link>
  );
};

export default TutorStudentListItemLink;
