import { useMergeRefs } from "@floating-ui/react";
import { cloneElement, forwardRef } from "react";

import { useFloatingContext } from "./context";

export default forwardRef(({ children, ...props }, propRef) => {
  const context = useFloatingContext();
  const ref = useMergeRefs([context.refs.setReference, propRef, children.ref]);

  return cloneElement(children, context.getReferenceProps({
    ref, ...props, ...children.props
  }));
});
