import React, { useMemo, useId } from "react";
import PropTypes from "prop-types";

function Rect({ className, height, width, rx, x, y }) {
  return <rect className={className} x={x} y={y} width={width} height={height} rx={rx} />;
}

Rect.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string.isRequired,
  rx: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  x: PropTypes.string,
  y: PropTypes.string,
};

function Skeleton({
  backgroundColor = "#f5f6f7",
  children,
  className,
  foregroundColor = "#e8e8e8",
  height,
  width,
}) {
  const id = useId();
  const gradientId = useMemo(() => `${id}-gradient`, [id]);
  const clipId = useMemo(() => `${id}-clip`, [id]);

  return (
    <svg data-testid="Skeleton" className={className} role="img" width={width} height={height}>
      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(#${clipId})`}
        style={{ fill: `url(#${gradientId})` }}
      />
      <defs>
        <clipPath id={clipId}>{children}</clipPath>
        <linearGradient id={gradientId} gradientTransform="translate(-2 0)">
          <stop offset="0%" stopColor={backgroundColor} stopOpacity={1} />
          <stop offset="50%" stopColor={foregroundColor} stopOpacity={1} />
          <stop offset="100%" stopColor={backgroundColor} stopOpacity={1} />
          <animateTransform
            attributeName="gradientTransform"
            type="translate"
            values="-2 0; 0 0; 2 0"
            dur="1.2s"
            repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

Skeleton.Rect = Rect;

export default Skeleton;

Skeleton.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  foregroundColor: PropTypes.string,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
