import noop from "lodash/noop";
import React, { useCallback, useEffect } from "react";

import { useStudentAssessmentQuery, useUpdateSecondsRemainingMutation } from "./queries";
import CountdownTimer from "./CountdownTimer";
import StickyBanner from "./StickyBanner";
import PickEndItemPrompt from "./PickEndItemPrompt";
import CompleteAssessmentForm from "./CompleteAssessmentForm";
import ReadingStats from "./ReadingStats";
import { readingAssessmentStatusType } from "../../constants";
import useCountdownTimer from "../../hooks/useCountdownTimer";
import BellAlertSolid from "assets/icons/bell-alert-solid.svg";
import ClappingHands from "assets/icons/clapping-hands.svg";
import DocumentTextMini from "assets/icons/document-text-mini.svg";

const { COMPLETED, CREATED, IN_PROGRESS } = readingAssessmentStatusType;
const defaultComponents = {
  CompleteAssessmentForm,
  BeforeCountdownTimer: noop,
  Results: noop,
  UtteranceList: noop,
};

const TimedAssessmentContent = ({
  components,
  hideReadingStats = false,
  instructions,
  submitResultsButtonLabel,
}) => {
  const { BeforeCountdownTimer, CompleteAssessmentForm, Results, UtteranceList } = {
    ...defaultComponents,
    ...components,
  };
  const studentAssessmentQuery = useStudentAssessmentQuery();
  const { copyright, contentURL, assessmentScriptURL, hasEndWord, secondsRemaining, status } =
    studentAssessmentQuery.data || {};
  const { mutate: mutateSecondsRemaining } = useUpdateSecondsRemainingMutation();
  const countdownTimer = useCountdownTimer(secondsRemaining * 1000);
  const updateSecondsRemaining = useCallback(
    (secondsRemaining) => {
      mutateSecondsRemaining(secondsRemaining);
    },
    [mutateSecondsRemaining]
  );
  const preventBrowserNavigation = useCallback(
    (event) => {
      updateSecondsRemaining(countdownTimer.remaining);
      event.preventDefault();
      event.returnValue = "stop";

      return "stop";
    },
    [countdownTimer.remaining, updateSecondsRemaining]
  );
  const preventTurboNavigation = useCallback(
    (event) => {
      if (confirm("Do you really want to leave?")) {
        updateSecondsRemaining(countdownTimer.remaining);
      } else {
        event.preventDefault();
      }
    },
    [countdownTimer.remaining, updateSecondsRemaining]
  );

  useEffect(() => {
    if (secondsRemaining > 0 && status === IN_PROGRESS && countdownTimer.isCompleted) {
      updateSecondsRemaining(0);
    }
  }, [countdownTimer.isCompleted, secondsRemaining, status, updateSecondsRemaining]);
  useEffect(() => {
    if (countdownTimer.isInProgress) {
      window.addEventListener("beforeunload", preventBrowserNavigation);
      window.addEventListener("turbo:before-visit", preventTurboNavigation);
    } else {
      window.removeEventListener("beforeunload", preventBrowserNavigation);
      window.removeEventListener("turbo:before-visit", preventTurboNavigation);
    }

    return () => {
      window.removeEventListener("beforeunload", preventBrowserNavigation);
      window.removeEventListener("turbo:before-visit", preventTurboNavigation);
    };
  }, [countdownTimer.isInProgress, preventBrowserNavigation, preventTurboNavigation]);

  if (!studentAssessmentQuery.data) {
    if (studentAssessmentQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {studentAssessmentQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <>
      {instructions.length > 0 ? (
        <ol className="text-zinc-500 text-sm/relaxed list-decimal list-inside mb-6">
          {instructions.map((instruction) => (
            <li key={instruction}>{instruction}</li>
          ))}
        </ol>
      ) : null}
      {contentURL || assessmentScriptURL ? (
        <ul className="flex text-sm font-medium mb-6 space-x-4">
          {contentURL ? (
            <li>
              <a
                className="flex items-center text-zinc-600 hover:text-zinc-800 hover:underline"
                href={contentURL}
                target="_blank"
                rel="noreferrer"
              >
                <DocumentTextMini className="w-5 h-5 mr-1" />
                Content
              </a>
            </li>
          ) : null}
          {assessmentScriptURL ? (
            <li>
              <a
                className="flex items-center text-zinc-600 hover:text-zinc-800 hover:underline"
                href={assessmentScriptURL}
                target="_blank"
                rel="noreferrer"
              >
                <DocumentTextMini className="w-5 h-5 mr-1" />
                Script
              </a>
            </li>
          ) : null}
        </ul>
      ) : null}
      <div>
        {secondsRemaining === 0 && !hasEndWord
          ? [
              <StickyBanner key="banner">
                <div className="bg-white border-b border-zinc-200 flex items-center pl-4 p-2 -mx-4 sm:-ml-10 md:-ml-[6.5rem] sm:-mr-10 justify-between">
                  <div className="flex items-center space-x-2">
                    <BellAlertSolid className="w-8 h-8 flex-none text-yellow-500" />
                    <h1 className="text-1xl md:text-2xl text-zinc-500 font-bold">Time’s Up!</h1>
                  </div>
                  <button
                    className="button-secondary disabled:bg-yellow-50 disabled:ring-yellow-400 w-[200px] relative z-1 text-center text-3xl disabled:text-zinc-500 font-medium h-[44px] flex items-center justify-center -mx-2.5 xl:mx-0"
                    disabled
                    type="button"
                  >
                    00:00
                  </button>
                </div>
              </StickyBanner>,
              <PickEndItemPrompt BeforeCountdownTimer={BeforeCountdownTimer} key="prompt">
                <UtteranceList />
              </PickEndItemPrompt>,
            ]
          : [
              !hideReadingStats ? <ReadingStats key="stats" /> : null,
              [CREATED, IN_PROGRESS].includes(status) && !hasEndWord ? (
                <StickyBanner key="banner">
                  <div className="bg-white border-b border-zinc-200 flex items-center p-2 -mx-4 sm:-ml-10 md:-ml-[6.5rem] sm:-mr-10 justify-end">
                    <div className="basis-[350px] flex justify-end">
                      <CountdownTimer
                        className="flex-row-reverse grow"
                        countdownTimer={countdownTimer}
                      />
                    </div>
                  </div>
                </StickyBanner>
              ) : null,
              <div
                className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0"
                key="content"
              >
                <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 xl:border-l border-zinc-200 sticky">
                  {status === COMPLETED ? (
                    <div className="xl:px-5 pt-6 xl:pb-8 flex-col">
                      <BeforeCountdownTimer />
                      <Results hideReadingStats={hideReadingStats} />
                    </div>
                  ) : hasEndWord ? (
                    <div className="bg-green-50 border border-green-500 rounded-lg p-4 pb-8 flex-col relative">
                      <Results hideReadingStats={hideReadingStats} />
                      <CompleteAssessmentForm
                        hideReadingStats={hideReadingStats}
                        submitButtonLabel={submitResultsButtonLabel}
                      />
                      <ClappingHands className="w-16 h-16 absolute right-5 top-32" />
                    </div>
                  ) : (
                    [
                      <BeforeCountdownTimer key="before-timer" />,
                      <CountdownTimer countdownTimer={countdownTimer} key="timer" />,
                    ]
                  )}
                </div>
                <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[36rem] relative">
                  <UtteranceList
                    disabled={(!hasEndWord && countdownTimer.isPaused) || status === COMPLETED}
                  />
                  {copyright ? (
                    <p className="text-zinc-500 text-sm/relaxed text-center mb-6">{copyright}</p>
                  ) : null}
                </div>
              </div>,
            ]}
      </div>
    </>
  );
};

TimedAssessmentContent.defaultProps = {
  components: defaultComponents,
  instructions: [],
};

export default TimedAssessmentContent;
