import cx from "classnames";
import { format, isToday, parse } from "date-fns";
import React, { useContext, useEffect, useMemo } from "react";
import range from "lodash/range";
import { twMerge } from "tailwind-merge";

import WeeklyTimeBlockList from "./WeeklyTimeBlockList";
import { WeeklyView } from "../common/Calendar/";
import {
  MERIDIEM_HOUR_FORMAT,
  TUTOR_AVAILABILITY_END_HOUR_EASTERN,
  TUTOR_AVAILABILITY_START_HOUR_EASTERN,
} from "../../constants";
import { SubBoardSelectionsContext } from "../../contexts/TutorCalendar";
import { useCalendarViewMode } from "../../hooks/useCalendarViewMode";
import { useSubBoardSelector } from "../../hooks/useSubBoardSelector";
import { useTutorTimeOffs } from "../../hooks/useTutorTimeOff";
import TutorTimeOffNote from "./TutorTimeOffNote";

export default ({ subBoardEnabled, timeZoneOffset }) => {
  const { selectedDates, setSelectedDates, setSelectedTimeBlocks } = useContext(SubBoardSelectionsContext);
  const { isCalendarMode, isSubMode } = useCalendarViewMode();
  const { dateIsSelected, selectDate, deselectDate, deleteSelectedTimeBlocksForDate } = useSubBoardSelector();
  const { getTimeOffStatus } = useTutorTimeOffs();
  const hoursRange = useMemo(() => {
    return range(
      TUTOR_AVAILABILITY_START_HOUR_EASTERN + timeZoneOffset,
      TUTOR_AVAILABILITY_END_HOUR_EASTERN + timeZoneOffset
    );
  }, [timeZoneOffset]);
  const onDateSelect = (date) => {
    if (isSubMode && getTimeOffStatus(date) === null) {
      if (dateIsSelected(date)) {
        deselectDate(date, () => deleteSelectedTimeBlocksForDate(date));
      } else {
        selectDate(date);
      }
    }
  };

  useEffect(() => {
    if (!isSubMode) {
      setSelectedDates(new Set([]));
      setSelectedTimeBlocks(new Set([]));
    }
  }, [isSubMode, setSelectedDates, setSelectedTimeBlocks]);

  return (
    <WeeklyView
      components={{
        AdjacentTableHeader: TutorTimeOffNote
      }}
      isDarkMode={isSubMode}
      onDateSelect={onDateSelect}
      thClassNames="top-[144px] md:top-[168px] lg:top-[109px] xl:top-[91px]"
      selectedDates={selectedDates}
    >
      {(dateRange) => hoursRange.map(hour => (
        <tr key={hour} className={isSubMode ? "bg-zinc-800" : "bg-white"}>
          <td
            className={cx("text-right text-xs p-0 align-top hidden md:table-cell", isSubMode ? "text-white/80" : "text-zinc-500")}
            scope="row"
          >
            <div className={cx("border-t -mt-[1px] px-2 py-1.5 sticky top-[153.5px] md:top-[175.5px] lg:top-[138.5px]", isSubMode ? "border-zinc-600" : "")}>
              {format(parse(hour, "H", new Date()), MERIDIEM_HOUR_FORMAT)}
            </div>
          </td>
          {dateRange.map(date => (
            <td
              className={twMerge(cx("align-top border-r border-l p-0", {
                "bg-sky-50 -ml-[1px]": isToday(date) && isCalendarMode,
                "bg-slate-700 -ml-[1px]": isToday(date) && isSubMode,
                "border-zinc-600": isSubMode,
                "bg-brand-900": dateIsSelected(date),
                "bg-zinc-300": getTimeOffStatus(date) && !isSubMode,
                "bg-zinc-500": getTimeOffStatus(date) && isSubMode,
              }))}
              key={date.toISOString()}
            >
              <WeeklyTimeBlockList
                date={date}
                hour={hour}
                subBoardEnabled={subBoardEnabled}
              />
            </td>
          ))}
        </tr>
      ))}
    </WeeklyView>
  );
}
