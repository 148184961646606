import {
  eachMinuteOfInterval,
  endOfDay,
  format,
  isWithinInterval,
  set,
  setHours,
  startOfDay,
  getHours,
  getMinutes,
  getSeconds
} from "date-fns";
import React, { useContext, useCallback, useMemo, useEffect } from "react";
import useMeasure from "react-use-measure";

import WeeklySubSessionRequestItem from "./WeeklySubSessionRequestItem";
import WeeklyTimeBlockItem from "./WeeklyTimeBlockItem";
import {
  HOUR_MINUTE_TWELVE,
  TUTOR_CALENDAR_TIME_BLOCK_DURATION_MINUTES,
  TUTOR_CALENDAR_WEEKLY_VIEW_TIME_BLOCK_MIN_HEIGHT_PX
} from "../../constants";
import { SubBoardSelectionsContext } from "../../contexts/TutorCalendar";
import { useSubBoardSelector } from "../../hooks/useSubBoardSelector";
import { useSubSessionRequestsQuery, useTimeBlocksQuery } from "./queries";
import { CurrentViewContext } from "../../contexts/Calendar";

export default ({ date, hour, subBoardEnabled }) => {
  const timeBlocksQuery = useTimeBlocksQuery();
  const subRequestsQuery = useSubSessionRequestsQuery();
  const { showSubSessionRequests } = useContext(CurrentViewContext);
  const now = new Date();
  const [measureRef, bounds] = useMeasure();
  const {
    acceptedTimeBlocksForDate,
    dateIsSelected,
    selectDate,
  } = useSubBoardSelector();
  const { calendarEvents: timeBlocks = [] } = timeBlocksQuery.data || {
    calendarEvents: [],
  };
  const { data: subRequests = [] } = subRequestsQuery;
  const { selectedTimeBlocks } = useContext(SubBoardSelectionsContext);
  const interpolateDate = useCallback((d) => {
    return d.getMinutes() * bounds.height / 60;
  }, [bounds.height]);
  const dateIsWithinCurrentHour = useCallback((d) => {
    return isWithinInterval(d, {
      start: setHours(startOfDay(date), hour),
      end: setHours(endOfDay(date), hour),
    });
  }, [date, hour]);
  const acceptedTimeBlocksForCurrentDate = useMemo(() => {
    return acceptedTimeBlocksForDate(date);
  }, [acceptedTimeBlocksForDate, date]);
  const allTimeBlocksForDateSelected = useMemo(() => {
    return acceptedTimeBlocksForCurrentDate.length > 0
      && acceptedTimeBlocksForCurrentDate.every(block => {
        return selectedTimeBlocks.has(block);
      });
  }, [selectedTimeBlocks, acceptedTimeBlocksForCurrentDate]);
  const minHeight = `${60 / TUTOR_CALENDAR_TIME_BLOCK_DURATION_MINUTES * TUTOR_CALENDAR_WEEKLY_VIEW_TIME_BLOCK_MIN_HEIGHT_PX}px`;
  const timeSlots = eachMinuteOfInterval({
    start: setHours(startOfDay(date), hour),
    end: setHours(endOfDay(date), hour),
  }, { step: TUTOR_CALENDAR_TIME_BLOCK_DURATION_MINUTES });
  const dateIsWithinSlot = useCallback((d, slot) => {
    return isWithinInterval(d, {
      start: set(startOfDay(date), {
        hours: getHours(slot),
        minutes: getMinutes(slot),
      }),
      end: set(endOfDay(date), {
        hours: getHours(slot),
        minutes: getMinutes(slot),
        seconds: getSeconds(slot),
      }),
    });
  }, [date]);
  const timeBlocksForTimeSlot = useCallback(slot => {
    return timeBlocks.filter(block => dateIsWithinSlot(block.startTime, slot));
  }, [dateIsWithinSlot, timeBlocks]);
  const subSessionRequestsForTimeSlot = useCallback(slot => {
    return subRequests.filter(sub => dateIsWithinSlot(sub.startTime, slot));
  }, [dateIsWithinSlot, subRequests]);

  useEffect(() => {
    if (allTimeBlocksForDateSelected) {
      selectDate(date, true);
    }
  }, [allTimeBlocksForDateSelected, selectDate, date]);

  return (
    <div className="h-full relative">
      <ul className="h-full" ref={measureRef} style={{ minHeight }}>
        {timeSlots.map(slot => {
          const timeBlocks = timeBlocksForTimeSlot(slot);
          const subSessionRequests = subSessionRequestsForTimeSlot(slot);
          const flexBasis = `${100 / (timeBlocks.length + (subSessionRequests.length > 0 ? 1 : 0)) }%`;

          return (timeBlocks.length + subSessionRequests.length) > 0 ? (
            <li
              className="absolute top-0 w-full min-h-[30px] will-change-transform"
              key={slot}
              style={{
                transform: `translate3d(0, ${interpolateDate(slot)}px, 0)`,
                height: `${TUTOR_CALENDAR_WEEKLY_VIEW_TIME_BLOCK_MIN_HEIGHT_PX}px`,
              }}
            >
              <ul className="flex w-full h-full max-w-full">
                {timeBlocks.map(block => (
                  <li
                    className="grow shrink truncate p-[1.5px]"
                    key={block.id}
                    style={{ flexBasis }}
                  >
                    <WeeklyTimeBlockItem
                      assignmentType={block.assignmentType}
                      cancellationReason={block.cancellationReason}
                      columnSelected={dateIsSelected(date)}
                      hideTimestamp={timeBlocks.length + (subSessionRequests.length > 0 ? 1 : 0) > 1}
                      endTime={block.endTime}
                      gradeLevel={block.grade}
                      id={block.id}
                      school={block.school}
                      startTime={block.startTime}
                      status={block.status}
                      title={block.title}
                      tutorStudentUuid={block.tutorStudentUuid}
                    />
                  </li>
                ))}
                {subSessionRequests.length > 0 && subBoardEnabled && showSubSessionRequests ? (
                  <li
                    className="grow shrink truncate px-[1px] py-[1px]"
                    style={{ flexBasis }}
                  >
                    <WeeklySubSessionRequestItem
                      hideTimestamp={timeBlocks.length + (subSessionRequests.length > 0 ? 1 : 0) > 1}
                      timeSlot={slot}
                      sessions={subSessionRequests}
                    />
                  </li>
                ) : null}
              </ul>
            </li>
          ) : null;
        })}
      </ul>
      {dateIsWithinCurrentHour(now) ? (
        <span
          className="absolute top-0 -left-[4px] right-0 h-[1.5px] bg-sky-500 pointer-events-none z-10"
          style={{ transform: `translate3d(0, ${interpolateDate(now)}px, 0)` }}
        >
          <span className="absolute right-full text-white text-[10px] font-medium bg-sky-500 rounded-full -translate-y-1/2 mt-[0.75px] leading-none py-[3px] px-[5px]">
            {format(now, HOUR_MINUTE_TWELVE)}
          </span>
        </span>
      ) : null}
    </div>
  );
};
