import Cookies from "js-cookie";
import isFunction from "lodash/isFunction";
import React, { createContext, useCallback, useEffect, useState } from "react";

export const NAMESPACE = "spark";
export const SparkCookieContext = createContext(null);
export const SparkCookieProvider = ({ children }) => {
  const [cookies, setCookies] = useState(() => {
    const documentCookies = Cookies.get();

    return Object.keys(documentCookies).filter(key => {
      return key.startsWith(NAMESPACE);
    }).reduce((acc, key) => {
      acc[key] = documentCookies[key];
      return acc;
    }, {});
  });
  const getCookie = useCallback((key) => {
    return cookies[`${NAMESPACE}.${key}`];
  }, [cookies]);
  const setCookie = useCallback((key, valueOrCb) => {
    const namespacedKey = `${NAMESPACE}.${key}`;

    setCookies((prevState) => {
      const value = isFunction(valueOrCb)
        ? valueOrCb(prevState[namespacedKey])
        : valueOrCb;

      return { ...prevState, [namespacedKey]: value }
    });
  }, []);

  useEffect(() => {
    Object.keys(cookies).forEach(key => {
      Cookies.set(key, cookies[key]);
    });
  }, [cookies]);

  return (
    <SparkCookieContext.Provider value={{ getCookie, setCookie }}>
      {children}
    </SparkCookieContext.Provider>
  );
};

export { default as useSparkCookie } from "./useSparkCookie";
