import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "ball", "checkboxField"]
  static values = {
    checked: { type: Boolean, default: false },
  }
  static classes = [
    "checkedBall",
    "uncheckedBall",
    "checkedToggle",
    "uncheckedToggle"
  ]

  connect() {
    if (this.checkedValue) {
      this.check()
    }
  }

  toggle() {
    if (this.checkedValue) {
      this.uncheck()
    } else {
      this.check()
    }
  }

  check() {
    this.ballTarget.classList.remove(...this.uncheckedBallClasses)
    this.ballTarget.classList.add(...this.checkedBallClasses)
    this.toggleTarget.classList.remove(...this.uncheckedToggleClasses)
    this.toggleTarget.classList.add(...this.checkedToggleClasses)
    this.checkedValue = true
    this.checkboxFieldTarget.value = true
  }

  uncheck() {
    this.ballTarget.classList.remove(...this.checkedBallClasses)
    this.ballTarget.classList.add(...this.uncheckedBallClasses)
    this.toggleTarget.classList.remove(...this.checkedToggleClasses)
    this.toggleTarget.classList.add(...this.uncheckedToggleClasses)
    this.checkedValue = false
    this.checkboxFieldTarget.value = false
  }
}
