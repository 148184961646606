import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="typeahead"
export default class extends Controller {
  static targets = ["item"];
  static classes = ["hidden"];

  search(event) {
    [...this.itemTargets].forEach(item => {
      const found = item.dataset.value.toLowerCase().includes(event.target.value);

      item.classList[found ? "remove" : "add"](this.hiddenClass);
    });
  }
}
