import * as Turbo from "@hotwired/turbo";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get, post } from "../../api";

export const useProtocolListQuery = () => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["protocol-list", studentId],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/student_protocols`, {
        signal,
      });

      return response.data;
    },
  });
};

export const useStudentLessonQuery = ({ isBaseline, isOpen, studentProtocolId }) => {
  const { id: studentId } = useParams();

  return useQuery({
    queryKey: ["student-lesson-list", studentId, studentProtocolId, isBaseline, isOpen],
    queryFn: async ({ signal }) => {
      const response = await get(`/students/${studentId}/protocol_contents`, {
        params: {
          student_protocol_id: studentProtocolId,
          is_baseline: isBaseline,
        },
        signal,
      });

      return response.data;
    },
  });
};

export const useSubmitLessonMutation = ({ studentProtocolId, lessonId, isBaseline }) => {
  const queryClient = useQueryClient();
  const { id: studentId } = useParams();

  return useMutation({
    mutationFn: (status) => {
      return post(`/students/${studentId}/student_lessons`, {
        student_protocol_id: studentProtocolId,
        lesson_id: lessonId,
        status: status,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ["student-lesson-list", studentId, studentProtocolId, isBaseline, true],
        (prevData = []) => {
          return prevData.map((lesson) => {
            return lesson.id === lessonId ? { ...lesson, status: data.status } : lesson;
          });
        }
      );
      // NOTE: Uncomment here when we are ready to automatically refresh for the tutor
      //
      // queryClient.invalidateQueries({ queryKey: ["current-content", studentId] });
      // queryClient.invalidateQueries({ queryKey: ["student", studentId] });
      // queryClient.invalidateQueries({ queryKey: ["lesson-plan", studentId] });
    },
  });
};

export const useStartAssessmentMutation = ({
  assessmentId,
  studentAssessmentableId,
  studentAssessmentableType,
  studentInstructionalPlanId,
}) => {
  const { id: studentId } = useParams();

  return useMutation({
    mutationFn: async () => {
      const response = await post(`/students/${studentId}/student_assessments`, {
        student_assessment: {
          assessment_id: assessmentId,
          student_assessmentable_id: studentAssessmentableId,
          student_assessmentable_type: studentAssessmentableType,
          student_instructional_plan_id: studentInstructionalPlanId,
        },
      });

      return response.data;
    },
    onSuccess: ({ studentAssessmentId }) => {
      Turbo.visit(`/students/${studentId}/student_assessments/${studentAssessmentId}/edit`);
    },
  });
};
