import React from "react";

import { Modal, ModalClose, ModalContent } from "./Modal";

const CelebratoryModal = ({ "data-testid": dataTestid, open, onOpenChange, title, text }) => (
  <Modal open={open} onOpenChange={onOpenChange}>
    <ModalContent data-testid={dataTestid}>
      <ModalClose className="ring-0 enabled:shadow-none absolute right-2 top-2 p-1" data-testid="celebratory-modal-close-button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </ModalClose>
      <div className="flex flex-col items-center py-6">
        <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-20 h-20 mb-6">
          <path d="M6.56646 38.568C6.89317 38.2246 7.28447 37.9491 7.71791 37.7574C8.15134 37.5656 8.61838 37.4613 9.09223 37.4504C9.56607 37.4396 10.0374 37.5224 10.4791 37.6942C10.9209 37.866 11.3244 38.1233 11.6665 38.4513L6.66646 33.6347C5.95344 32.8605 5.56264 31.8434 5.57376 30.7909C5.58488 29.7385 5.99708 28.7299 6.7263 27.9709C7.45551 27.2119 8.4468 26.7597 9.49797 26.7065C10.5491 26.6534 11.581 27.0032 12.3831 27.6847L12.5831 27.8847C11.7916 27.0668 11.3478 25.9741 11.3449 24.8359C11.342 23.6977 11.7802 22.6027 12.5676 21.7808C13.3549 20.959 14.4301 20.4742 15.5674 20.4283C16.7046 20.3824 17.8154 20.7789 18.6665 21.5347L21.5331 24.2847C20.8622 23.5068 20.5049 22.507 20.5309 21.4801C20.5569 20.4532 20.9645 19.4727 21.674 18.7299C22.3835 17.9871 23.3443 17.5351 24.3689 17.462C25.3936 17.3889 26.4087 17.7 27.2165 18.3347L42.0998 32.618C40.2498 29.8847 40.8665 25.118 43.7665 23.5847C44.3624 23.2789 45.0515 23.2086 45.6969 23.3877C46.3423 23.5668 46.8967 23.9822 47.2498 24.5513L54.4998 33.9013C57.4652 38.7288 58.7466 44.4032 58.1435 50.0366C57.5403 55.6699 55.0867 60.9444 51.1665 65.0347C49.7647 66.4958 48.0889 67.6665 46.2346 68.4799C44.3804 69.2933 42.384 69.7334 40.3597 69.7752C38.3353 69.817 36.3225 69.4596 34.4363 68.7234C32.5501 67.9873 30.8273 66.8867 29.3665 65.4847L6.66646 43.668C6.3242 43.3402 6.05005 42.948 5.85971 42.514C5.66938 42.0799 5.56662 41.6126 5.55733 41.1388C5.54804 40.6649 5.6324 40.1939 5.80557 39.7528C5.97874 39.3116 6.23732 38.909 6.56646 38.568Z" fill="#FFB59E" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M49.5829 39.7845L42.0996 32.6012" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.5674 27.8844L27.584 42.3011" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.6665 38.4509L21.8832 48.2509" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21.5171 24.2842L33.6838 35.9508" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path opacity="0.15" d="M24.1665 75.8336C24.1665 76.1619 24.6623 76.487 25.6255 76.7903C26.5887 77.0936 28.0005 77.3692 29.7803 77.6014C31.5601 77.8335 33.673 78.0177 35.9984 78.1433C38.3238 78.269 40.8162 78.3336 43.3332 78.3336C45.8502 78.3336 48.3425 78.269 50.6679 78.1433C52.9933 78.0177 55.1063 77.8335 56.8861 77.6014C58.6658 77.3692 60.0776 77.0936 61.0409 76.7903C62.0041 76.487 62.4998 76.1619 62.4998 75.8336C62.4998 75.5053 62.0041 75.1802 61.0409 74.8769C60.0776 74.5736 58.6658 74.298 56.8861 74.0658C55.1063 73.8337 52.9933 73.6496 50.6679 73.5239C48.3425 73.3983 45.8502 73.3336 43.3332 73.3336C40.8162 73.3336 38.3238 73.3983 35.9984 73.5239C33.673 73.6496 31.5601 73.8337 29.7803 74.0658C28.0005 74.298 26.5887 74.5736 25.6255 74.8769C24.6623 75.1802 24.1665 75.5053 24.1665 75.8336Z" fill="#45413C"/>
          <path d="M63.7002 22.7664L72.5669 19.9998C72.6623 19.9758 72.7511 19.9304 72.8265 19.8671C72.9018 19.8038 72.9619 19.7242 73.002 19.6343C73.0421 19.5445 73.0613 19.4466 73.0581 19.3483C73.0549 19.2499 73.0294 19.1535 72.9835 19.0664L70.7002 15.2164C70.6377 15.1398 70.5589 15.078 70.4695 15.0355C70.3801 14.9931 70.2825 14.9711 70.1835 14.9711C70.0846 14.9711 69.9869 14.9931 69.8976 15.0355C69.8082 15.078 69.7294 15.1398 69.6669 15.2164L63.1002 21.8831C63.0401 21.979 63.0092 22.0904 63.0113 22.2036C63.0134 22.3167 63.0484 22.4269 63.112 22.5205C63.1756 22.6141 63.2651 22.6873 63.3695 22.7309C63.474 22.7746 63.5889 22.7869 63.7002 22.7664Z" fill="#FFE500" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M58.7999 18.784L63.1332 10.584C63.1863 10.5002 63.2196 10.4053 63.2303 10.3066C63.2411 10.208 63.2291 10.1082 63.1953 10.0148C63.1615 9.92153 63.1068 9.83719 63.0354 9.76829C62.964 9.69939 62.8777 9.64776 62.7832 9.61737L58.4332 8.5007C58.3358 8.48268 58.2355 8.48719 58.14 8.51387C58.0446 8.54055 57.9565 8.5887 57.8825 8.65463C57.8086 8.72057 57.7507 8.80255 57.7132 8.89431C57.6758 8.98606 57.6598 9.08516 57.6665 9.18403V18.5007C57.6646 18.636 57.7084 18.7679 57.7909 18.8751C57.8734 18.9824 57.9897 19.0586 58.121 19.0914C58.2522 19.1242 58.3907 19.1117 58.5139 19.0559C58.6372 19.0001 58.7379 18.9043 58.7999 18.784Z" fill="#FFE500" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M27.0168 12.6347L31.6668 4.55138C31.7171 4.46785 31.7484 4.37431 31.7587 4.27735C31.7689 4.18039 31.7577 4.08236 31.7259 3.99019C31.6941 3.89802 31.6425 3.81394 31.5747 3.74388C31.5069 3.67382 31.4245 3.61949 31.3335 3.58471L27.0168 2.35138C26.921 2.33128 26.822 2.33307 26.727 2.35661C26.632 2.38014 26.5435 2.42482 26.4682 2.48731C26.3929 2.5498 26.3327 2.62848 26.2921 2.71749C26.2514 2.8065 26.2314 2.90354 26.2335 3.00138L25.9668 12.3347C25.9764 12.453 26.0219 12.5655 26.0972 12.6572C26.1724 12.749 26.2739 12.8156 26.3879 12.8482C26.502 12.8808 26.6234 12.8778 26.7357 12.8397C26.8481 12.8015 26.9461 12.73 27.0168 12.6347Z" fill="#FFE500" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M20.7164 13.084L18.3331 4.13403C18.3125 4.03791 18.2704 3.94771 18.2099 3.87021C18.1494 3.79272 18.0722 3.72995 17.9839 3.68663C17.8957 3.64332 17.7988 3.62057 17.7005 3.62012C17.6022 3.61967 17.5051 3.64152 17.4164 3.68403L13.4164 5.83403C13.3344 5.89011 13.2659 5.96383 13.216 6.04978C13.1662 6.13573 13.1361 6.23175 13.1281 6.33081C13.1201 6.42986 13.1344 6.52945 13.1699 6.62229C13.2053 6.71512 13.2611 6.79886 13.3331 6.86736L19.6998 13.6674C19.7917 13.7687 19.9155 13.8355 20.0506 13.8569C20.1857 13.8782 20.3241 13.8527 20.4427 13.7846C20.5614 13.7165 20.6532 13.6099 20.703 13.4825C20.7528 13.3551 20.7575 13.2145 20.7164 13.084Z" fill="#FFE500" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M17.2999 42.1337C17.6278 41.7914 18.0199 41.5173 18.454 41.3269C18.888 41.1366 19.3553 41.0338 19.8292 41.0245C20.303 41.0153 20.774 41.0996 21.2152 41.2728C21.6563 41.446 22.059 41.7045 22.3999 42.0337L17.3999 37.217C16.9701 36.8518 16.6195 36.4024 16.3698 35.8966C16.1202 35.3909 15.9767 34.8393 15.9482 34.276C15.9196 33.7126 16.0067 33.1494 16.2041 32.621C16.4014 32.0926 16.7048 31.6101 17.0956 31.2034C17.4864 30.7966 17.9564 30.4742 18.4765 30.2559C18.9966 30.0376 19.5558 29.928 20.1199 29.934C20.6839 29.9399 21.2407 30.0613 21.7561 30.2905C22.2715 30.5198 22.7345 30.8521 23.1166 31.267L23.3166 31.4503C22.8741 31.0574 22.5154 30.5792 22.262 30.0444C22.0086 29.5096 21.8657 28.9291 21.8418 28.3378C21.8179 27.7465 21.9136 27.1564 22.1231 26.6029C22.3326 26.0494 22.6516 25.5438 23.061 25.1165C23.4704 24.6891 23.9618 24.3488 24.5058 24.1158C25.0498 23.8827 25.6352 23.7619 26.227 23.7603C26.8188 23.7588 27.4049 23.8767 27.95 24.107C28.4952 24.3372 28.9884 24.6751 29.3999 25.1003L32.2666 27.8503C31.4776 27.0945 31.0212 26.0561 30.9977 24.9637C30.9861 24.4228 31.0811 23.8849 31.2774 23.3808C31.4737 22.8766 31.7673 22.416 32.1416 22.0253C32.5159 21.6347 32.9634 21.3215 33.4587 21.1038C33.954 20.8861 34.4873 20.768 35.0282 20.7564C36.1206 20.733 37.1776 21.1445 37.9666 21.9003L52.8499 36.1837C50.9999 33.4503 51.6166 28.667 54.5166 27.1503C55.1098 26.8391 55.7988 26.7641 56.4451 26.9403C57.0914 27.1166 57.6469 27.531 57.9999 28.1003L65.2499 37.467C68.2169 42.2911 69.4992 47.9633 68.896 53.5945C68.2927 59.2257 65.838 64.4975 61.9166 68.5837C60.5149 70.0448 58.839 71.2155 56.9848 72.0289C55.1305 72.8423 53.1342 73.2824 51.1098 73.3242C49.0854 73.366 47.0727 73.0086 45.1864 72.2724C43.3002 71.5363 41.5775 70.4357 40.1166 69.0337L17.3999 47.2503C16.7117 46.5831 16.315 45.671 16.2963 44.7126C16.2775 43.7542 16.6383 42.8273 17.2999 42.1337Z" fill="#FFCEBF"/>
          <path d="M54.5161 31.9327C55.1093 31.6215 55.7983 31.5465 56.4446 31.7227C57.0908 31.899 57.6464 32.3134 57.9994 32.8827L65.2494 42.2494C67.3104 45.6127 68.5707 49.4048 68.9327 53.3327C69.4479 47.8115 68.1553 42.2723 65.2494 37.5494L57.9994 28.0994C57.6464 27.5301 57.0908 27.1156 56.4446 26.9394C55.7983 26.7631 55.1093 26.8382 54.5161 27.1494C52.0327 28.4494 51.1827 32.1494 52.1827 34.8494C52.5545 33.6123 53.3907 32.567 54.5161 31.9327Z" fill="#FFDCD1"/>
          <path d="M32.1329 26.8003C32.8892 26.016 33.9245 25.5619 35.0138 25.5369C36.1031 25.5119 37.1582 25.9181 37.9496 26.667L48.3329 36.667C49.1398 37.4507 50.1588 37.9805 51.2638 38.1908C52.3688 38.4012 53.5112 38.2828 54.5496 37.8503L37.8829 21.867C37.1432 21.1534 36.1633 20.7424 35.1359 20.7147C34.1084 20.687 33.1078 21.0446 32.3307 21.7173C31.5535 22.39 31.0562 23.329 30.9363 24.3498C30.8164 25.3706 31.0828 26.3993 31.6829 27.2337C31.8141 27.0709 31.9653 26.9253 32.1329 26.8003Z" fill="#FFDCD1"/>
          <path d="M17.2999 42.1337C17.6278 41.7914 18.0199 41.5173 18.454 41.3269C18.888 41.1366 19.3553 41.0338 19.8292 41.0245C20.303 41.0153 20.774 41.0996 21.2152 41.2728C21.6563 41.446 22.059 41.7045 22.3999 42.0337L17.3999 37.217C16.9701 36.8518 16.6195 36.4024 16.3698 35.8966C16.1202 35.3909 15.9767 34.8393 15.9482 34.276C15.9196 33.7126 16.0067 33.1494 16.2041 32.621C16.4014 32.0926 16.7048 31.6101 17.0956 31.2034C17.4864 30.7966 17.9564 30.4742 18.4765 30.2559C18.9966 30.0376 19.5558 29.928 20.1199 29.934C20.6839 29.9399 21.2407 30.0613 21.7561 30.2905C22.2715 30.5198 22.7345 30.8521 23.1166 31.267L23.3166 31.4503C22.8741 31.0574 22.5154 30.5792 22.262 30.0444C22.0086 29.5096 21.8657 28.9291 21.8418 28.3378C21.8179 27.7465 21.9136 27.1564 22.1231 26.6029C22.3326 26.0494 22.6516 25.5438 23.061 25.1165C23.4704 24.6891 23.9618 24.3488 24.5058 24.1158C25.0498 23.8827 25.6352 23.7619 26.227 23.7603C26.8188 23.7588 27.4049 23.8767 27.95 24.107C28.4952 24.3372 28.9884 24.6751 29.3999 25.1003L32.2666 27.8503C31.4776 27.0945 31.0212 26.0561 30.9977 24.9637C30.9861 24.4228 31.0811 23.8849 31.2774 23.3808C31.4737 22.8766 31.7673 22.416 32.1416 22.0253C32.5159 21.6347 32.9634 21.3215 33.4587 21.1038C33.954 20.8861 34.4873 20.768 35.0282 20.7564C36.1206 20.733 37.1776 21.1445 37.9666 21.9003L52.8499 36.1837C50.9999 33.4503 51.6166 28.667 54.5166 27.1503C55.1098 26.8391 55.7988 26.7641 56.4451 26.9403C57.0914 27.1166 57.6469 27.531 57.9999 28.1003L65.2499 37.467C68.2169 42.2911 69.4992 47.9633 68.896 53.5945C68.2927 59.2256 65.838 64.4975 61.9166 68.5837C60.5149 70.0448 58.839 71.2155 56.9848 72.0289C55.1305 72.8423 53.1342 73.2824 51.1098 73.3242C49.0854 73.366 47.0727 73.0086 45.1864 72.2724C43.3002 71.5363 41.5775 70.4357 40.1166 69.0337L17.3999 47.2503C16.7117 46.5831 16.315 45.671 16.2963 44.7126C16.2775 43.7542 16.6383 42.8273 17.2999 42.1337Z" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M60.3163 43.3519L52.833 36.1852" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M23.2998 31.4489L38.3331 45.8822" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M22.4004 42.0348L32.6171 51.8348" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M32.2505 27.8519L44.4172 39.5352" stroke="#45413C" strokeWidth="1.78656" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <h5 className="text-gray-700 font-semibold text-lg mb-3">{title}</h5>
        <p className="text-gray-500 text-center">{text}</p>
      </div>
    </ModalContent>
  </Modal>
);

export default CelebratoryModal;
