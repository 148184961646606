import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import StudentHighFrequencyWordsMasteryDrawer from "../StudentHighFrequencyWordsMasteryDrawer";

const REACT_QUERY_STALE_TIME = 20 * 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: REACT_QUERY_STALE_TIME,
    },
  },
});
/*
 * This might seem hacky, but we try to fix an issue where the High Frequency Words modal wasn't
 * being updated automatically when a word was marked as taught. The drawer is rendered in two
 * different places by two different js bundles (TutorApp and
 * StudentHighFrequencyWordsMasteryDrawerTrigger), so we need a way to tell <QueryClientProvider />
 * to use the same queryClient instance so changes are propagated correctly everywhere.
 */
window.__SPARK_TUTOR_APP_QUERY_CLIENT = window.__SPARK_TUTOR_APP_QUERY_CLIENT = queryClient;

const StudentHighFrequencyWordsMasteryDrawerTrigger = () => (
  <QueryClientProvider client={window.__SPARK_TUTOR_APP_QUERY_CLIENT}>
    <BrowserRouter>
      <Routes>
        <Route
          path="students/:id/*"
          element={
            <Drawer>
              <DrawerTrigger>
                <button
                  className="text-brand-500 text-xs font-medium hover:underline"
                  type="button"
                >
                  Details
                </button>
              </DrawerTrigger>
              <DrawerContent>
                <StudentHighFrequencyWordsMasteryDrawer />
              </DrawerContent>
            </Drawer>
          }
        />
      </Routes>
    </BrowserRouter>
  </QueryClientProvider>
);

export default StudentHighFrequencyWordsMasteryDrawerTrigger;
