import React from "react";
import { twMerge } from "tailwind-merge";
import cx from "classnames"

const StatusBadge = ({ status, isBaseline }) => {
  const statusText = (status) => {
    if (status === null) {
      if (isBaseline) {
        status = "needs_baseline"
      } else {
        status = "not_started"
      }
    }
    return status.replace(/_/g, ' ').replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  };

  return (
    <span 
      className={twMerge(cx(
        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium", {
          "bg-green-100 text-green-800": status === "completed" || status === "reteach_completed",
          "bg-yellow-100 text-yellow-800": status === "in_progress",
          "bg-blue-100 text-blue-800": !status,
          "bg-teal-100 text-teal-800": status === "reteach_needed",
          "bg-purple-100 text-purple-800": status === "tested_out_of",          
        }
      ))}
    >
      {statusText(status)}
    </span>
  );
}

export default StatusBadge;
