import React from "react";

import StudentFluencyAssessment from "../StudentFluencyAssessment";
import StudentDearAssessment from "../StudentDearAssessment";
import StudentPhonologicalAwarenessDrill from "../StudentPhonologicalAwarenessDrill";
import StudentOneMinuteChallenge from "../StudentOneMinuteChallenge";
import StudentHighFrequencyWordsDrill from "../StudentHighFrequencyWordsDrill";

const LessonCardRenderer = ({
  gradeLevels,
  id,
  isConditional,
  open,
  paDrillType,
  sequenceNumber,
  studentId,
  targetGoal,
  type,
}) => {
  if (type === "StudentDearAssessment") {
    return (
      <StudentDearAssessment
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_dear_assessment/${id}/complete_assessment`,
          index: `/students/${studentId}/student_dear_assessment/${id}`,
          resetAssessment: `/students/${studentId}/student_dear_assessment/${id}/reset_assessment`,
          startAssessment: `/students/${studentId}/student_dear_assessment/${id}/start_assessment`,
          updateGoal: `/students/${studentId}/student_dear_assessment/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_dear_assessment/${id}/update_seconds_remaining`,
          updateWord: `/students/${studentId}/student_dear_assessment/${id}/update_word`,
        }}
        type={type}
      />
    );
  }

  if (
    [
      "StudentLetterNameDrill",
      "StudentLetterSoundDrill",
      "StudentOneMinuteChallenge",
      "StudentNonsenseOneMinuteChallenge",
      "StudentConditionalLetterNameDrill",
    ].includes(type)
  ) {
    return (
      <StudentOneMinuteChallenge
        assessmentId={id}
        isConditional={isConditional}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_challenge/${id}/complete`,
          index: `/students/${studentId}/student_reading_challenge/${id}`,
          resetAssessment: `/students/${studentId}/student_reading_challenge/${id}/reset`,
          startAssessment: `/students/${studentId}/student_reading_challenge/${id}/start`,
          updateGoal: `/students/${studentId}/student_reading_challenge/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_reading_challenge/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_reading_challenge/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_reading_challenge/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_reading_challenge/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/decrement_word_incorrect`,
        }}
        sequenceNumber={sequenceNumber}
        type={type}
        targetGoal={targetGoal}
      />
    );
  }

  if (type === "StudentNonsenseWordFluency") {
    return (
      <StudentOneMinuteChallenge
        assessmentId={id}
        isConditional={isConditional}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/complete`,
          index: `/students/${studentId}/student_nonsense_word_fluency/${id}`,
          resetAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/reset`,
          startAssessment: `/students/${studentId}/student_nonsense_word_fluency/${id}/start`,
          updateGoal: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_seconds_remaining`,
          updateWordCorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_correct`,
          updateCurrentRound: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_current_round`,
          updateWordEnd: `/students/${studentId}/student_nonsense_word_fluency/${id}/update_word_end`,
          incrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/increment_word_incorrect`,
          decrementWordIncorrect: `/students/${studentId}/student_nonsense_word_fluency/${id}/decrement_word_incorrect`,
        }}
        sequenceNumber={sequenceNumber}
        type={type + "DEAR"}
        targetGoal={targetGoal}
      />
    );
  }

  if (type === "StudentPhonologicalAwarenessDrill") {
    return (
      <StudentPhonologicalAwarenessDrill
        assessmentId={id}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_reading_challenge/${id}/complete`,
          decrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/decrement_word_incorrect`,
          incrementWordIncorrect: `/students/${studentId}/student_reading_challenge/${id}/increment_word_incorrect`,
          index: `/students/${studentId}/student_reading_challenge/${id}`,
          resetAssessment: `/students/${studentId}/student_reading_challenge/${id}/reset`,
          updateWordCorrect: `/students/${studentId}/student_reading_challenge/${id}/update_word_correct`,
          updateWordEnd: `/students/${studentId}/student_reading_challenge/${id}/update_word_end`,
        }}
        type={paDrillType}
      />
    );
  }

  if (type === "StudentFluencyAssessment") {
    return (
      <StudentFluencyAssessment
        assessmentId={id}
        gradeLevels={gradeLevels}
        open={open}
        paths={{
          completeAssessment: `/students/${studentId}/student_fluency_assessment/${id}/complete_assessment`,
          completeQuestion: `/students/${studentId}/student_fluency_assessment/${id}/complete_question`,
          completeTask: `/students/${studentId}/student_fluency_assessment/${id}/complete_task`,
          index: `/students/${studentId}/student_fluency_assessment/${id}`,
          resetAssessment: `/students/${studentId}/student_fluency_assessment/${id}/reset_assessment`,
          startAssessment: `/students/${studentId}/student_fluency_assessment/${id}/start_assessment`,
          updateGoal: `/students/${studentId}/student_fluency_assessment/${id}/update_goal`,
          updateNextGoal: `/students/${studentId}/student_fluency_assessment/${id}/update_next_goal`,
          updateSecondsRemaining: `/students/${studentId}/student_fluency_assessment/${id}/update_seconds_remaining`,
          updateWord: `/students/${studentId}/student_fluency_assessment/${id}/update_word`,
        }}
      />
    );
  }

  if (type === "StudentHighFrequencyWordsDrill") {
    return <StudentHighFrequencyWordsDrill />;
  }

  return null;
};

export default LessonCardRenderer;
