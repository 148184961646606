import React from "react";
import PropTypes from "prop-types";

export default function Goal({ label, goal, style }) {
  return (
    <div
      className="flex absolute bottom-0 top-0 w-[94px] whitespace-nowrap text-gray-600 justify-end"
      style={style}
    >
      <div className="self-end">
        <span className="font-bold">{label}:</span> {goal}
      </div>
      <div className="absolute bg-gray-600 w-[1px] bottom-0 top-0 right-[-5px]" />
    </div>
  );
}

Goal.propTypes = {
  label: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  style: PropTypes.object,
};
